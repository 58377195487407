<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div>
    <div class="d-flex pa-2">
      <v-col cols="12" sm="3">
        <v-autocomplete
          :items="workers"
          v-model="createdByUuid"
          label="Created by"
          item-text="fullname"
          item-value="uuid"
          :loading="loading"
          outlined
          @change="getHistorylogs"
          clearable
          dense
        ></v-autocomplete>
      </v-col>
      <template v-if="!haveClient">
        <v-col cols="12" sm="3">
          <filter-by-clients @selectC="setPatient($event)" />
        </v-col>
      </template>

      <v-col cols="12" sm="5" class="d-flex">
        <v-row>
          <v-col>
            <ma-date-picker v-model="dateFrom" label="From" past />
          </v-col>
          <v-col><ma-date-picker v-model="dateTo" label="To" past /></v-col>
        </v-row>
      </v-col>
      <template v-if="dateFrom || dateTo">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              small
              color="error"
              icon
              class="my-4"
              @click="cleanFilters"
              ><v-icon>mdi-close</v-icon></v-btn
            >
          </template>
          <span>Clean Filters</span>
        </v-tooltip>
      </template>

      <v-spacer></v-spacer>

      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            class="my-3"
            v-on="on"
            color="primary"
            icon
            @click="getHistorylogs"
            ><v-icon>mdi-reload</v-icon></v-btn
          >
        </template>
        <span>Reload</span>
      </v-tooltip>
    </div>
    {{/* Interval indicator */}}
    <template v-if="dateFrom && dateTo">
      <v-layout class="px-4 pb-2" column>
        <v-layout column class="text-body-1 text--secondary">
          <div>
            From:
            <span class="text-caption">{{ prettyDateShow(query.date1) }}</span>
          </div>
          <div>
            To:
            <span class="text-caption">{{ prettyDateShow(query.date2) }}</span>
          </div>
        </v-layout>
      </v-layout>
    </template>

    <v-data-table
      :loading="loading"
      :options.sync="options"
      :headers="headers"
      :items="historylogs"
      single-expand
      show-expand
      item-key="uuid"
      :expanded.sync="expanded"
      :server-items-length="totallogs"
      :items-per-page="10"
      class="elevation-1"
    >
      <template v-slot:[`item.fullname`]="{ item }">
        {{ item.client ? item.client.fullname : "-" }}
      </template>
      <template v-slot:[`item.polizeStatus`]="{ item }">
        <span
          v-bind:style="{ color: colorShow(item) }"
          class="font-weight-bold cursor-pointer"
          >{{ statusShow(item) }}</span
        >
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-container>
            <div class="text-center mt-2 mb-3"><strong>Before:</strong></div>
            <v-row>
              <template v-if="dataShow(item.before) != '-'">
                <v-col
                  cols="12"
                  sm="3"
                  v-for="(key, i) in dataShow(item.before)"
                  :key="i"
                >
                  <strong>{{ keyShow(key[0]) }}: </strong>
                  <template
                    v-if="key[0] == 'monthlyPay' || key[0] == 'anualIncome'"
                  >
                    <span>{{ valueShow(key) | currency }}</span>
                  </template>
                  <template
                    v-else-if="
                      key[0] == 'address' ||
                      key[0] == 'mailAddress' ||
                      key[0] == 'billingAddress'
                    "
                  >
                    <div v-for="(ad, y) in dataShow(valueShow(key))" :key="y">
                      <span
                        class="
                          text-decoration-underline
                          font-italic font-weight-medium
                        "
                        >{{ keyShow(ad[0]) }}: </span
                      ><span>{{ valueShow(ad) }}</span>
                    </div>
                  </template>
                  <template v-else>
                    <span>{{ valueShow(key) }}</span>
                  </template>
                </v-col>
              </template>
              <template v-else> No data </template>
            </v-row>

            <v-divider class="my-4"></v-divider>
            <div class="text-center mt-2 mb-3"><strong>Changes:</strong></div>
            <v-row>
              <template v-if="dataShow(item.changes) != '-'">
                <v-col
                  cols="12"
                  sm="3"
                  v-for="(key, i) in dataShow(item.changes)"
                  :key="i"
                >
                  <strong>{{ keyShow(key[0]) }}: </strong>
                  <template
                    v-if="key[0] == 'monthlyPay' || key[0] == 'anualIncome'"
                  >
                    <span>{{ valueShow(key) | currency }}</span>
                  </template>
                  <template
                    v-else-if="
                      key[0] == 'address' ||
                      key[0] == 'mailAddress' ||
                      key[0] == 'billingAddress'
                    "
                  >
                    <div v-for="(ad, y) in dataShow(valueShow(key))" :key="y">
                      <span
                        class="
                          text-decoration-underline
                          font-italic font-weight-medium
                        "
                        >{{ keyShow(ad[0]) }}: </span
                      ><span>{{ valueShow(ad) }}</span>
                    </div>
                  </template>
                  <template v-else>
                    <span>{{ valueShow(key) }}</span>
                  </template>
                </v-col>
              </template>
              <template v-else> No data </template>
            </v-row>
            <v-divider class="my-4"></v-divider>
            <div class="text-center mt-2 mb-3"><strong>After:</strong></div>
            <v-row>
              <template v-if="dataShow(item.after) != '-'">
                <v-col
                  cols="12"
                  sm="3"
                  v-for="(key, i) in dataShow(item.after)"
                  :key="i"
                >
                  <strong>{{ keyShow(key[0]) }}: </strong>
                  <template
                    v-if="key[0] == 'monthlyPay' || key[0] == 'anualIncome'"
                  >
                    <span>{{ valueShow(key) | currency }}</span>
                  </template>
                  <template
                    v-else-if="
                      key[0] == 'address' ||
                      key[0] == 'mailAddress' ||
                      key[0] == 'billingAddress'
                    "
                  >
                    <div v-for="(ad, y) in dataShow(valueShow(key))" :key="y">
                      <span
                        class="
                          text-decoration-underline
                          font-italic font-weight-medium
                        "
                        >{{ keyShow(ad[0]) }}: </span
                      ><span>{{ valueShow(ad) }}</span>
                    </div>
                  </template>
                  <template v-else>
                    <span>{{ valueShow(key) }}</span>
                  </template>
                </v-col>
              </template>
              <template v-else> No data </template>
            </v-row>
          </v-container>
        </td></template
      >

      <template v-slot:[`item.createdAt`]="{ item }">
        {{ getDate(item.createdAt) }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <template
          v-if="
            item.name != null &&
            item.lastName != null &&
            item.paymentAmount == null
          "
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" color="primary" @click="convertPatient(item)">
                mdi-account-convert</v-icon
              >
            </template>
            <span>Convert to Patient</span>
          </v-tooltip>
        </template>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import { getAPI } from "@/api/axios-base";
import MaDatePicker from "@/components/MaDatePicker/MaDatePicker.vue";
import { notifyError } from "@/components/Notification";
import { utilMixin } from "@/utils/mixins";
import { mapActions, mapState } from "vuex";
import { AllKeys } from "@/utils/enums/allKeys";
import { PolicyStatus } from "@/utils/enums/policystatus";
import FilterByClients from "@/components/filters/FilterByClients.vue";
import moment from "moment";
export default {
  name: "history-logs",
  mixins: [utilMixin],
  props: {
    clientUuid: {
      type: String,
      default: () => "",
    },
  },
  components: { MaDatePicker, FilterByClients },
  data() {
    return {
      expanded: [],
      historylogs: [],
      totallogs: 0,
      loading: false,
      options: {},
      headers: [
        {
          text: "Full name",
          align: "start",
          sortable: false,
          value: "fullname",
        },

        {
          text: "Message",
          align: "start",
          sortable: false,
          value: "message",
        },
        /*    {
          text: "Status",
          align: "center",
          sortable: false,
          value: "polizeStatus",
        }, */
        {
          text: "Create date",
          sortable: false,
          value: "createdAt",
        },
        {
          text: "Update date",
          sortable: false,
          value: "createdAt",
        },
        { text: "", align: "center", value: "actions", sortable: false },
        { text: "", value: "data-table-expand" },
      ],
      dateFrom: "",
      dateTo: "",
      patientUuid: null,
      createdByUuid: null,
      query: {
        date1: "",
        date2: "",
      },
    };
  },
  watch: {
    options: {
      handler() {
        this.getHistorylogs();
      },
      deep: true,
    },
    dateFrom(val) {
      if (
        val !== null &&
        val !== this.dateTo &&
        this.dateTo !== null &&
        this.dateTo !== ""
      ) {
        this.getHistorylogs();
      }
    },
    dateTo(val) {
      if (
        val !== null &&
        val !== this.dateFrom &&
        this.dateFrom !== null &&
        this.dateFrom !== ""
      ) {
        this.getHistorylogs();
      }
    },
  },
  computed: {
    ...mapState("crmEmployeeModule", ["workers"]),

    ...mapState("crmSettingsModule", [
      "loadingRefer",
      "references",
      "companies",
    ]),
    haveClient() {
      if (
        this.clientUuid != "" &&
        this.clientUuid != null &&
        this.clientUuid != undefined
      ) {
        return true;
      }
      return false;
    },
  },
  methods: {
    ...mapActions("crmEmployeeModule", ["actGetEmployees"]),

    ...mapActions("crmSettingsModule", [
      "actListReferences",
      "actListCompaniesNom",
    ]),
    setPatient(val) {
      this.patientUuid = val;
      this.getHistorylogs();
    },
    getDAte(value) {
      if (value) {
        const y = value.split("-")[0];
        const m = value.split("-")[1];
        const d = value.split("-")[2];
        return `${m}/${d}/${y}`;
      }
      return "-";
    },
    getHistorylogs() {
      const { page, itemsPerPage } = this.options;
      const itmper = itemsPerPage != -1 ? itemsPerPage : 10;
      let body = {
        limit: itmper,
        offset: (page - 1) * itmper,
      };

      if (this.haveClient) {
        body = { ...body, patientUuid: this.clientUuid };
      } else {
        if (this.patientUuid) {
          body = { ...body, patientUuid: this.patientUuid };
        }
      }
      if (this.createdByUuid) {
        body = { ...body, createdByUuid: this.createdByUuid };
      }
      if (this.dateFrom && this.dateTo) {
        const interval = {
          date1: moment(this.dateFrom).startOf("day").toISOString(),
          date2: moment(this.dateTo).endOf("day").toISOString(),
        };
        this.query = interval;
        body = { ...body, interval: interval };
      }
      this.loading = true;
      getAPI
        .post("/patient/filter-logs", body)
        .then((res) => {
          this.loading = false;
          this.totallogs = res.data.count;
          this.historylogs = res.data.result;
        })
        .catch((error) => {
          this.loading = false;
          let mess = error.response.data.message.toString();
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },

    convertPatient(item) {
      const body = {
        name: item.name,
        lastName: item.lastName,
        members: item.members,
        dob: item.dob,
        polizeNumber: item.polizeNumber,
      };

      const st = JSON.stringify(body);
      const codif = btoa(st);
      localStorage.setItem("log-client", codif);
      this.$router.push("/healths/create");
    },

    dataShow(data) {
      if (data == null || data == undefined || data == "") {
        return "-";
      }
      const x = Object.entries(data);

      return x;
    },
    keyShow(value) {
      const x = AllKeys[value];
      if (x == undefined || x == null || x == "") {
        return value;
      }
      return x;
    },
    getCompany(id) {
      if (this.companies.length == 0) {
        return "No Companys";
      }
      if (id == "" || id == null || id == undefined) {
        return "No have company";
      }
      const comp = this.companies.find((c) => c.id == id);
      if (comp) {
        return comp.name;
      }
      return "No have company";
    },
    getSource(id) {
      if (this.references.length == 0) {
        return "No Sources";
      }
      if (id == "" || id == null || id == undefined) {
        return "-";
      }
      const sour = this.references.find((r) => r.id == id);
      if (sour) {
        return sour.name;
      }
      return "-";
    },

    valueShow(data) {
      const key = data[0];
      const value = data[1];
      if (value == null || value == undefined || value == "") {
        return "-";
      }
      switch (key) {
        case "birthDate":
          return this.getDAte(value);
        case "isMale":
          return value ? "Male" : "Female";
        case "isMaried":
          return value ? "Yes" : "No";
        case "isSilver":
          return value ? "Silver" : "Bronze";
        case "createAt":
          return this.getDate(value);
        case "updateAt":
          return this.getDate(value);
        case "effectiveDate":
          return this.getDate(value);
        case "companyId":
          return this.getCompany(value);
        case "howKnow":
          return this.getSource(value);
        case "expDate":
          return this.getDate(value);
        case "polizeStatus":
          const ps = PolicyStatus[value];
          if (ps) {
            return ps;
          }
          return value;
        case "legalStatus":
          if (value == "CITIZEN") {
            return "Citizen";
          }
          if (value == "PERMANENT_RESIDENT") {
            return "Permanent Resident";
          }
          if (value == "TEMPORAL_RESIDENT") {
            return "Temporal Resident";
          }
        case "preferLanguage":
          if (value == "ENGLISH") {
            return "English";
          }
          if (value == "SPANISH") {
            return "Spanish";
          }

        default:
          return value;
      }
    },

    statusShow(item) {
      const est = item.polizeStatus
        ? item.polizeStatus.replaceAll("_", " ")
        : "No Status";
      if (est == null || est == undefined) {
        return "-";
      }

      return est;
    },

    colorShow(item) {
      const status = item.polizeStatus;
      if (status != null && status != undefined) {
        switch (status) {
          case "POLIZE_PENDING":
            return "#1663E0FF";
          case "POLIZE_OK":
            return "#19FF00FF";
          case "PAYMENT_OK":
            return "#00FFB3";
          case "POLIZE_MEMBERS_NO_MATCH":
            return "#FF0033";
          case "PAYMENT_MEMBERS_NO_MATCH":
            return "#FFAA00";

          default:
            return "#000";
        }
      }
      return "#000";
    },
    cleanFilters() {
      this.dateFrom = "";
      this.dateTo = "";
      this.getHistorylogs();
    },
  },
  async mounted() {
    this.loading = true;
    await this.actGetEmployees();

    this.actListReferences();
    this.actListCompaniesNom();

    this.loading = false;
    localStorage.removeItem("log-client");
  },
};
</script>
<style lang=""></style>
