<template>
  <div>
    <v-dialog
      v-model="dialogQ"
      persistent
      :overlay="false"
      max-width="800px"
      transition="dialog-transition"
    >
      <v-card class="quoteCard">
        <v-toolbar class="white--text" color="primary">
          <v-toolbar-title> Quote </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="my-3">
          <v-row no-gutters class="mt-4">
            <v-col cols="12" sm="4">
              <template v-if="!datedisabled">
                <div class="d-flex">
                  <label class="font-weight-medium">Quote Day </label>
                  <v-spacer></v-spacer>
                  <v-btn color="error" dark x-small text @click="cancelEditDate"
                    ><v-icon x-small>mdi-close</v-icon>cancel</v-btn
                  >
                </div>
              </template>
              <template v-else>
                <div class="d-flex">
                  <label class="font-weight-medium">Quote Day </label>
                  <v-spacer></v-spacer>
                  <v-btn color="info" dark x-small text @click="gotoEditDate"
                    ><v-icon x-small>mdi-pencil</v-icon>edit</v-btn
                  >
                </div>

                <v-text-field
                  :disabled="datedisabled"
                  outlined
                  dense
                  v-model="quoteDateshow"
                ></v-text-field>
              </template>
              <ma-date-picker
                v-show="!datedisabled"
                v-model="dateEdit"
                :editable="true"
              />
            </v-col>

            <v-col cols="12" sm="4" class="px-1">
              <label class="font-weight-medium">Company </label>
              <v-select
                :items="companies"
                item-text="name"
                :loading="loading"
                item-value="id"
                v-model="companyId"
                outlined
                dense
              ></v-select>
            </v-col>
            <v-col cols="12" sm="4">
              <label class="font-weight-medium">Members </label>
              <v-text-field outlined dense v-model="members"></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" class="px-1">
              <label class="font-weight-medium">Metal Plan </label>
              <v-select
                :items="metalplan"
                item-text="label"
                item-value="value"
                v-model="policyPlan"
                outlined
                dense
              ></v-select>
            </v-col>
            <v-col cols="12" sm="4">
              <label class="font-weight-medium">Premium </label>
              <v-text-field outlined dense v-model="premium"></v-text-field>
            </v-col>
          </v-row>
          <v-card-actions class="d-flex justify-end">
            <v-btn color="error" :disabled="loading" text @click="cancelQuote(false)"
              >Cancel</v-btn
            >
            <v-btn
              color="info"
              :disabled="loading || !validQuoted"
              :loading="loading"
              text
              @click="confirmQuote"
              >Update</v-btn
            >
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { getAPI } from "@/api/axios-base";
import { notifyError, notifyInfo } from "@/components/Notification";
import cleanObject from "@/utils/cleanObject";
import moment from "moment";
import { mapActions, mapState } from "vuex";
import MaDatePicker from "@/components/MaDatePicker/MaDatePicker.vue";

export default {
  props: {
    dialog: Boolean,
    item: Object,
  },
  components: {
    MaDatePicker,
  },
  data() {
    return {
      dialogQ: false,
      loading: false,
      premium: null,
      policyPlan: null,
      members: null,
      companyId: null,
      datedisabled: true,
      dateEdit: null,
      quotedDate: moment(),
      metalplan: [
        { label: "Bronze", value: "BRONZE" },
        { label: "Silver", value: "SILVER" },
        { label: "Gold", value: "GOLD" },
      ],
    };
  },
  async mounted() {
    this.dialogQ = this.dialog;
    this.loading = true;
    await this.actListCompaniesNom();
    this.dateEdit = moment().format("YYYY-MM-DD");
    this.loading = false;
    this.quotedDate = this.item.quotedDate;
    this.premium = this.item.premium;
    this.members = this.item.members;
    this.policyPlan = this.item.policyPlan;
    this.companyId = this.item.companyId;
  },
  computed: {
    ...mapState("crmSettingsModule", ["companies"]),
    quoteDateshow() {
      return this.quotedDate.format("MM/DD/YYYY");
    },

    validQuoted() {
      /* console.log(this.policyPlan) */
      if (this.policyPlan == null) {
        return false;
      }
      return true;
    },
  },
  methods: {
    ...mapActions("crmSettingsModule", ["actListCompaniesNom"]),
    confirmQuote() {
      let body = {
        members: this.members,
        premium: this.premium,
        quotedDate: this.quotedDate,
        policyPlan: this.policyPlan,
        companyId: this.companyId,
      };
      if (body.members) {
        body.members = Number(body.members);
      }
      if (body.premium) {
        body.premium = Number(body.premium);
      }
      if (this.datedisabled) {
        body.quotedDate = new Date(body.quotedDate).toISOString();
      } else {
        body.quotedDate = moment(this.dateEdit).toISOString();
      }

      const uuid = this.$route.params.uuid;
      body = cleanObject(body);
      body = { ...body };
      this.loading = true;
      getAPI
        .put("/patient/updatePatient/" + uuid, body)
        .then(() => {
          notifyInfo("Quote has been updated");
          this.loading = false;

          this.cancelQuote(true);
        })
        .catch((error) => {
          this.loading = false;
          let mess = error.response.data.message.toString();
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },

    gotoEditDate() {
      this.datedisabled = false;
    },
    cancelEditDate() {
      this.datedisabled = true;
    },
    cancelQuote(value) {
      this.dialogQ = false;
      this.loading = false;
      this.premium = null;
      this.policyPlan = null;
      this.members = null;
      this.companyId = null;
      this.datedisabled = true;
      this.dateEdit = null;
      this.quotedDate = moment();
      if (value) {
        this.$emit("changeQuote", true);
      } else {
        this.$emit("changeQuote", false);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.quoteCard {
  height: 330px !important;
}
</style>
