<template>
  <div>
    <template>
      <v-data-table
        dense
        :headers="headers"
        :items="payments"
        :footer-props="footerProps"
      ></v-data-table
    ></template>
  </div>
</template>
<script>
export default {
  data() {
    return {
      headers: [
        { text: "Monthly Fee", width: 100, value: "monthly", sortable: false },
        { text: "Over Rate", width: 100, value: "over", sortable: false },
        { text: "Bond", width: 100, value: "bond", sortable: false },
        { text: "Amount", width: 100, value: "amount", sortable: false },
        { text: "Range", width: 100, value: "range", sortable: false },
      ],
      payments: [],
      footerProps: {
        "items-per-page-options": [25, 50, 100, 250, 500, 1000],
      },
    };
  },
};
</script>
<style lang=""></style>
