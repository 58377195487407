<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div>
    <v-container>
      <v-card>
        <div class="d-flex">
          <v-switch
            class="mx-2"
            label="Quoted"
            inset
            @change="getStatistics"
            v-model="onlyQuoted"
          ></v-switch>
          <v-spacer></v-spacer>
          <ma-date-picker class="pt-2 pr-1" v-model="dateFrom" label="From" past />
          <ma-date-picker class="pt-2 pr-1" v-model="dateTo" label="To" past />

          <v-btn class="mx-2 mt-3" text small dark color="indigo" @click="clearFilters()">
            <v-icon dark> mdi-broom </v-icon>clear filters
          </v-btn>
          <v-btn
            class="mx-2 mt-3"
            text
            small
            dark
            color="indigo"
            @click="getStatistics()"
          >
            <v-icon dark> mdi-update </v-icon>Update
          </v-btn>
        </div>
        <template v-if="filterDate != 'clear'">
          <v-layout class="pa-4" column>
            <v-layout column class="text-body-1 text--secondary">
              <div>
                From
                <span class="text-caption">{{ prettyDate(query1.date1) }}</span>
              </div>
              <div>
                To
                <span class="text-caption">{{ prettyDate(query1.date2) }}</span>
              </div>
            </v-layout>
          </v-layout>
          <v-divider></v-divider>
        </template>
        <v-data-table
          :headers="headers"
          :loading="loading"
          :items="statistics"
          :footer-props="footerProps"
          class="elevation-1"
        >
          <template v-slot:[`header.totalmembersonrenewal`]="{ header }">
            <template v-if="total && total.totalmembersonrenewal">
              {{ `${header.text}(${total.totalmembersonrenewal})` }}
            </template>
            <template v-else>
              {{ `${header.text}` }}
            </template>
          </template>
          <template v-slot:[`header.totalmemberspaymentok`]="{ header }">
            <template v-if="total && total.totalmemberspaymentok">
              {{ `${header.text}(${total.totalmemberspaymentok})` }}
            </template>
            <template v-else>
              {{ `${header.text}` }}
            </template>
          </template>
          <template v-slot:[`header.totalmemberspending`]="{ header }">
            <template v-if="total && total.totalmemberspending">
              {{ `${header.text}(${total.totalmemberspending})` }}
            </template>
            <template v-else>
              {{ `${header.text}` }}
            </template>
          </template>
        </v-data-table></v-card
      >
    </v-container>
  </div>
</template>
<script>
import { getAPI } from "@/api/axios-base";
import { notifyError } from "@/components/Notification";
import MaDatePicker from "@/components/MaDatePicker/MaDatePicker.vue";
import { mapMutations } from "vuex";
import moment from "moment";
export default {
  components: {
    MaDatePicker,
  },
  data() {
    return {
      dateTo: null,
      dateFrom: null,
      filterDate: "clear",
      headers: [
        {
          text: "Employee",
          align: "start",
          sortable: true,
          value: "fullname",
        },
        {
          text: "Renewal",
          align: "center",
          sortable: true,
          value: "totalmembersonrenewal",
        },
        {
          text: "Payment Ok",
          align: "center",
          sortable: true,
          value: "totalmemberspaymentok",
        },
        {
          text: "Police Pending",
          align: "center",
          sortable: true,
          value: "totalmemberspending",
        },
      ],
      query1: {
        date1: "",
        date2: "",
      },
      quoteDate: {
        date1: "",
        date2: "",
      },
      onlyQuoted: false,
      interval: null,
      total: null,
      statistics: [],
      loading: false,
      footerProps: {
        "items-per-page-options": [25, 50, 100, 250, 500, 1000],
      },
    };
  },
  watch: {
    dateFrom(val) {
      if (val != null && this.dateTo != null && this.dateTo != "") {
        this.filterDate = "custom";
        this.getStatistics();
      } else {
        this.filterDate = "clear";
      }
    },
    dateTo(val) {
      if (val != null && this.dateFrom != null && this.dateFrom != "") {
        this.filterDate = "custom";
        this.getStatistics();
      } else {
        this.filterDate = "clear";
      }
    },
  },
  methods: {
    ...mapMutations(["mutDisplaylogo"]),
    getStatistics() {
      this.loading = true;

      let body = { onlyQuoted: this.onlyQuoted };
      if (this.filterDate == "custom") {
        this.quoteDate = {
          date1: moment(this.dateFrom).startOf("day").toISOString(),
          date2: moment(this.dateTo).endOf("day").toISOString(),
        };
        this.query1 = this.quoteDate;
        body = { ...body, quoteDate: this.quoteDate };
      }

      getAPI
        .post("/reports/new-client-employee-status", body)
        .then((res) => {
          this.loading = false;
          const response = res.data;
          this.statistics = response.filter((r) => r.fullname != "TOTAL");
          this.total = response.find((r) => r.fullname == "TOTAL");
        })
        .catch((error) => {
          let mess = error.response.data.message.toString();
          this.loading = false;

          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
    clearFilters() {
      this.dateFrom = null;
      this.dateTo = null;
      this.filterDate = "clear";
      this.onlyQuoted = false;
      this.getStatistics();
    },
    tryRequest() {
      this.interval = setInterval(this.getStatistics, 30000);
    },

    prettyDate(date) {
      if (date === null || date === "") return "";

      return moment(date).format("dddd D MMMM YYYY, h:mm:ss a");
    },
  },
  mounted() {
    this.mutDisplaylogo(true);
    this.tryRequest();
    this.getStatistics();
  },
  destroyed() {
    this.mutDisplaylogo(false);
    clearInterval(this.interval);
  },
};
</script>
<style lang="scss" scoped>
.logo {
  z-index: 999;
}
</style>
