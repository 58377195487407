<template>
  <div>
    <v-card  :loading="loading" :disabled="loading">
      <v-card-title class="headline primary white--text">
        <v-layout column>
          <div class="text-body-1 font-weight-bold">
            {{ details.name ? details.name : "" }}
            {{ details.lastName ? details.lastName : "" }}
          </div>
          <div class="d-flex flex-row align-center">
            <div class="text-caption font-weight-bold">Phone: {{ details.phone }}</div>
          </div>
        </v-layout>
        <v-spacer></v-spacer>
        <v-divider class="mx-4" vertical />
        <v-tooltip transition="fade-transition" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mr-2"
              :to="'/customers/update/' + $route.params.uuid"
              dark
              icon
              v-on="on"
              v-bind="attrs"
            >
              <v-icon>mdi-account-edit</v-icon>
            </v-btn>
          </template>
          <span>Update customer</span>
        </v-tooltip>
        <v-tooltip transition="fade-transition" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mr-2"
              :loading="loading"
              dark
              icon
              v-on="on"
              v-bind="attrs"
              @click="getCustomer"
            >
              <v-icon>mdi-sync</v-icon>
            </v-btn>
          </template>
          Update Details
        </v-tooltip>
      </v-card-title>
      <v-card-text class="pt-3">
        <v-tabs v-model="tabSelected" grow show-arrows>
          <v-tab
            v-for="(tab, i) in tabs"
            :key="i"
            :disabled="tab.value == 'autos'"
            :href="tab.href"
          >
            <template>
              <v-icon left>{{ tab.icon }}</v-icon>
              {{ tab.title }}
            </template>
          </v-tab>
          {{/* Customer Details */}}
          <v-tab-item value="details" v-if="details != null">
            <v-container>
              <v-row no-gutters>
                <v-col cols="12" sm="4">
                  <div class="text-caption font-weight-blod text--secondary">
                    Full name:
                  </div>
                  <div class="text-body-1">
                    {{ details.name ? details.name : "" }}
                    {{ details.lastName ? details.lastName : "" }}
                  </div>
                </v-col>
                <v-col cols="12" sm="4">
                  <div class="text-caption font-weight-blod text--secondary">DOB:</div>
                  <div class="text-body-1">
                    {{ getDOB }}
                  </div>
                </v-col>
                <v-col cols="12" sm="4">
                  <div class="text-caption font-weight-blod text--secondary">Gender:</div>
                  <div class="text-body-1">
                    {{ details.isMale ? "Male" : "Female" }}
                  </div>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row no-gutters class="mt-2">
                <v-col cols="12" sm="4">
                  <div class="text-caption font-weight-blod text--secondary">Phone:</div>
                  <div class="text-body-1">
                    {{ details.phone ? details.phone : "-" }}
                  </div>
                </v-col>
                <v-col cols="12" sm="4">
                  <div class="text-caption font-weight-blod text--secondary">Email:</div>
                  <div class="text-body-1">
                    {{ details.email ? details.email : "-" }}
                  </div>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <div class="text-center font-weight-blod text-decoration-underline">
                Address
              </div>
              <v-row class="mb-2" no-gutters>
                <v-col cols="12" sm="2">
                  <div class="text-caption font-weight-blod text--secondary">State</div>
                  <template v-if="loadingStates">
                    <div class="text-body-1">...Loading States</div>
                  </template>
                  <template v-else>
                    <div class="text-body-1">
                      {{ getState ? getState.name : "-" }}
                    </div>
                  </template>
                </v-col>
                <v-col cols="12" sm="2">
                  <div class="text-caption font-weight-blod text--secondary">City</div>
                  <template v-if="loadingCities">
                    <div class="text-body-1">...Loading Cities</div>
                  </template>
                  <template v-else>
                    <div class="text-body-1">
                      {{ getCity ? getCity.name : "-" }}
                    </div>
                  </template>
                </v-col>
                <v-col cols="12" sm="5">
                  <div class="text-caption font-weight-blod text--secondary">Address</div>
                  <div class="text-body-1">
                    {{ details.address ? details.address.addressLine1 : "-" }}
                    {{
                      details.address && details.address.addressLine2
                        ? ", " + details.address.addressLine2
                        : ""
                    }}
                  </div>
                </v-col>
                <v-col cols="12" sm="2">
                  <div class="text-caption font-weight-blod text--secondary">ZipCode</div>
                  <div class="text-body-1">
                    {{ details.address ? details.address.zipCode : "-" }}
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>
          {{/* Customer Healths */}}
          <v-tab-item value="healths" v-if="details != null">
            <healths-customer />
          </v-tab-item>
          <v-tab-item value="calls" v-if="details != null">
            <call-view :fromCustomer="true" :phoneCustomer="details.phone" />
          </v-tab-item>
        </v-tabs>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { getAPI } from "@/api/axios-base";

import { notifyError } from "@/components/Notification";
import moment from "moment";
import { mapActions, mapState } from "vuex";
import HealthsCustomer from "./HealthsCustomer.vue";
import CallView from "../CallCenter/CallView.vue";
export default {
  components: { HealthsCustomer, CallView },
  data() {
    return {
      loading: false,
      loadingCities: false,
      details: { name: "as" },
      stateCities: [],
      tabSelected: "details",
      state: 0,
      tabs: [
        { value: "details", title: "Details", icon: "mdi-account", href: "#details" },
        {
          value: "healths",
          title: "Healths",
          icon: "mdi-medical-bag",
          href: "#healths",
        },
        {
          value: "autos",
          title: "Autos",
          icon: "mdi-car",
          href: "#autos",
        },
        {
          value: "calls",
          title: "Calls",
          icon: "mdi-phone",
          href: "#calls",
        },
      ],
      genders: [
        { label: "Female", value: false },
        { label: "Male", value: true },
      ],
    };
  },
  watch: {
    state: function (stateId) {
      if (stateId == undefined || stateId == null) {
        this.stateCities = [];
        this.loadingCities = false;

        return;
      }

      this.loadingCities = true;
      this.actGetCityByState(stateId).then((cities) => {
        this.loadingCities = false;
        this.stateCities = cities;
      });
    },
  },
  computed: {
    ...mapState("crmMedicFormModule", ["states", "loadingStates"]),
    getState() {
      if (this.states.length == 0) {
        return "-";
      } else {
        return this.states.find((s) => s.id == this.state);
      }
    },
    getCity() {
      if (this.details) {
        if (this.stateCities.length == 0) {
          return "-";
        } else {
          return this.stateCities.find((s) => s.id == this.details.address.city);
        }
      } else {
        return "-";
      }
    },
    getDOB() {
      if (this.details) {
        const dob = moment(this.details.birthDate).format("MM/DD/YYYY");
        return dob;
      }
      return "-";
    },
  },
  methods: {
    ...mapActions("crmMedicFormModule", ["actListStates", "actGetCityByState"]),

    getCustomer() {
      const uuid = this.$route.params.uuid;
      this.loading = true;
      getAPI("/customer/" + uuid)
        .then((res) => {
          this.details = res.data;
          this.state = res.data.address ? res.data.address.state : 0;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          let mess = error.response.data.message.toString();
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
    cancel() {
      //
    },
  },
  async mounted() {
    await this.getCustomer();
  },
};
</script>
<style lang=""></style>
