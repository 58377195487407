<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <v-card
    class="  overflow-hidden mx-auto"
    :disabled="loading"
    width="700"
  >
    <v-toolbar class="primary" tag="div" flat dark tile>
      <v-toolbar-title>Companies</v-toolbar-title>
      <v-spacer />
      <v-btn :loading="loading" rounded text @click="listCompanies()">
        <v-icon>mdi-autorenew</v-icon>
        {{ $t("labelUpdate") }}
      </v-btn>
    </v-toolbar>

    <v-toolbar color="transparent" dense flat>
      <v-row>
        <v-col cols="6">
          <btn-create-company expanded @created="listCompanies" />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="'Search ' + name"
            single-line
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
    </v-toolbar>

    <v-data-table
      :search="search"
      :headers="headers"
      :options.sync="options"
      :items="companies"
      :footer-props="footerProps"
      item-key="name"
      class="elevation-1"
      :loading="loading"
    >
      <template v-slot:[`item.color`]="{ item }">
        <div
          v-if="item"
          :style="{ backgroundColor: item.color }"
          class="color"
        ></div>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <template v-if="item.status == 'ACTIVE'">
          <v-icon color="success">mdi-check</v-icon>
        </template>
        <template v-else><v-icon color="error">mdi-close</v-icon> </template>
      </template>
      <template v-slot:[`item.default`]="{ item }">
        <template v-if="item.default">
          <v-icon color="success">mdi-check</v-icon>
        </template>
        <template v-else><v-icon color="error">mdi-close</v-icon> </template>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <template>
          <v-btn color="success" icon @click="goStatesCompany(item)"
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </template>
        <template>
          <v-btn color="primary" @click="toEdit(item)" icon
            ><v-icon>mdi-pencil</v-icon></v-btn
          >
        </template>
        <template v-if="item.status == 'ACTIVE'">
          <v-btn color="error" @click="gotoDelete(item)" icon
            ><v-icon>mdi-delete</v-icon></v-btn
          >
        </template>
        <template v-else>
          <v-btn dark width="120" color="success" icon @click="gotoActive(item)"
            ><v-icon small left>mdi-check</v-icon></v-btn
          >
        </template>

        <!--   <template v-if="!item.default">
              <v-list-item link>
                <v-btn color="success" width="120" @click="setDefault(item)">
                  Set Default
                </v-btn>
              </v-list-item>
            </template> -->
      </template>
    </v-data-table>
    <v-dialog
      v-model="dialogConfirmRemove"
      persistent
      :overlay="false"
      max-width="280"
   
    >
      <v-card>
        <v-card-title class="text-h6 font-weight-regular  ">
          {{ activate ? $t("dialogTitle1") : $t("dialogTitle") }}
        </v-card-title>

        <v-card-text column>
          <div class="text-body-1 text-truncate d-flex mb-1">
            {{ itemTemp ? itemTemp.name : "" }}
          </div>
        </v-card-text>
        <v-divider />

        <v-card-actions class="justify-end">
          <v-btn :disabled="loading" width="100" text rounded @click="cancel()">
            {{ $t("labelCancel") }}
          </v-btn>
          <template v-if="activate">
            <v-btn
              color="success"
              :loading="loading"
              @click="active"
              width="100"
              depressed
              rounded
              dark
            >
              Activate
            </v-btn>
          </template>
          <template v-else>
            <v-btn
              color="error"
              :loading="loading"
              @click="remove"
              width="100"
              depressed
              rounded
              dark
            >
              {{ $t("labelRemove") }}
            </v-btn>
          </template>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      persistent
      v-model="dialogEdit"
     
      max-width="480px"
    >
      <template v-if="itemTemp">
        <v-card>
          <v-card-title>
            <span class="text-h5">Edit Company</span>
          </v-card-title>
          <v-form ref="form" class="pa-4">
            <!--   <v-row class="mx-1">
              <v-switch
                label="Default"
                inset
                v-model="itemTemp.default"
              ></v-switch>
            </v-row> -->
            <v-text-field
              v-model="itemTemp.name"
              placeholder="Name"
              dense
              outlined
            />
            <!--  <v-row>
              <v-col>
                <v-color-picker
                  hide-inputs
                  v-model="itemTemp.color"
                ></v-color-picker>
              </v-col>
            </v-row> -->
          </v-form>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error darken-1" text @click="cancel"> Close </v-btn>
            <v-btn
              color="blue darken-1"
              :loading="loading"
              :disabled="loading"
              text
              @click="update()"
            >
              Update
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </v-card>
</template>

<script>
import Vue from "vue";
import { getAPI } from "@/api/axios-base";

import {
  notifyError,
  notifyInfo,
  notifySuccess,
} from "@/components/Notification";

import BtnCreateCompany from "@/views/Settings/buttons/BtnCreateCompany.vue";

export default Vue.extend({
  components: {
    BtnCreateCompany,
  },
  name: "company-nom",
  data() {
    return {
      search: "",
      options: {},
      totalcompanies: 0,
      name: "",
      menu: false,
      loading: false,
      footerProps: { "items-per-page-options": [15, 50, 100, 500, 1000] },
      companies: [],
      uuid: "",
      dialogConfirmRemove: false,
      dialogEdit: false,
      itemTemp: null,
      activate: false,
      headers: [
        { text: "Name", align: "start", value: "name", sortable: true },
        /*  { text: "Color", value: "color", sortable: false }, */
        { text: "Active", value: "status", sortable: false },
        //  { text: "Default", value: "default", sortable: false },
        { text: "", align: "end", value: "actions", sortable: false },
      ],
    };
  },

  mounted() {
    this.listCompanies();
  },
  methods: {
    goStatesCompany(item) {
      this.$router.push("nomenclators/companies_nom/states/" + item.id);
    },

    gotoDelete(item) {
      this.dialogConfirmRemove = true;
      this.itemTemp = item;
      this.activate = false;
    },
    toEdit(item) {
      this.dialogEdit = true;
      this.itemTemp = item;
    },
    gotoActive(item) {
      this.dialogConfirmRemove = true;
      this.itemTemp = item;
      this.activate = true;
    },
    cancel() {
      this.dialogConfirmRemove = false;
      this.itemTemp = null;
      this.activate = false;
      this.loading = false;
      this.dialogEdit = false;
    },
    remove() {
      this.loading = true;
      getAPI
        .delete("/company-nom/" + this.itemTemp.id)
        .then((res) => {
          this.loading = false;
          notifyInfo(this.$t("notifyRemove"));
          this.cancel();
          this.listCompanies();
        })
        .catch((error) => {
          this.loading = false;
          let mess = error.response.data.message.toString();
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },

    active() {
      this.loading = true;
      getAPI
        .put("/company-nom/update/" + this.itemTemp.id, {
          status: "ACTIVE",
        })
        .then(() => {
          this.loading = false;
          notifyInfo(this.$t("notifyActive"));
          this.cancel();
          this.listCompanies();
        })
        .catch((error) => {
          this.loading = false;
          let mess = error.response.data.message.toString();
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
    listCompanies() {
      this.loading = true;
      /*  let body = {};
      const { page, itemsPerPage } = this.options;
      const itmper = itemsPerPage != -1 ? itemsPerPage : 10;
      body = {
        range: {
          limit: itmper,
          offset: (page - 1) * itmper,
        },
      }; */

      getAPI(`/company-nom/list`)
        .then((res) => {
          this.companies = res.data;

          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          let mess = error.response.data.message.toString();
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
    update() {
      let test = null;
      if (typeof this.itemTemp.color === "string") {
        test = this.itemTemp.color;
      } else {
        test = this.itemTemp.color.hex;
      }
      this.itemTemp.name = this.itemTemp.name;

      this.loading = true;
      getAPI
        .put("/company-nom/update/" + this.itemTemp.id, {
          name: this.itemTemp.name,
          default: this.itemTemp.default,
          color: test,
        })
        .then(() => {
          this.cancel();
          notifySuccess(`The Company has been updated`);
        })
        .catch((error) => {
          this.loading = false;
          this.dialogEdit = false;
          let mess = error.response.data.message.toString();
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },

    setDefault(item) {
      this.loading = true;
      getAPI
        .put("/company-nom/update/" + item.id, {
          default: true,
        })
        .then(() => {
          notifySuccess(`The Company has been set Default`);

          this.loading = false;
          this.listCompanies();
        })
        .catch((error) => {
          this.loading = false;

          let mess = error.response.data.message.toString();
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
  },
});
</script>
<style lang="scss" scoped>
.color {
  margin-left: 7px;
  width: 15px;
  height: 15px;
}
</style>
<i18n>
{
  "en": {
    "dialogTitle": "Remove",
    "dialogTitle1": "Activate",
    "notifyRemove": "Removed successfully",
    "notifyActive": "Activated successfully"
  },
  "es": {
    "dialogTitle": "Eliminar",
    "dialogTitle1": "Activar",
    "notifyRemove": "Eliminado con éxito",
    "notifyActive": "Activado con éxito"
  }
}
</i18n>
