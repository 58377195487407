<template>
  <div>
    <v-card  :loading="loading" :disabled="loading">
      <v-card-title class="headline primary white--text"> Update Customer </v-card-title>
      <v-card-text class="pt-3">
        <v-form ref="customerForm" v-model="formValid">
          <v-row no-gutters>
            <v-col cols="12" sm="6" class="px-1">
              <label class="font-weight-medium" for="name">
                First Name <span class="red--text">*</span></label
              >
              <v-text-field
                v-model="form.name"
                :rules="[rules.required]"
                outlined
                dense
              />
            </v-col>
            <v-col cols="12" sm="6" class="px-1">
              <label class="font-weight-medium" for="lastname">
                Last Name <span class="red--text">*</span></label
              >
              <v-text-field
                v-model="form.lastName"
                :rules="[rules.required]"
                outlined
                dense
              />
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" sm="3" class="px-1">
              <label class="font-weight-medium" for="dob">
                DOB <span class="red--text">*</span></label
              >
              <template v-if="birthDate != ''">
                <DobPicker :dateInitial="birthDate" @dateDOB="form.birthDate = $event" />
              </template>
              <!--  <ma-date-picker
                :rules="[rules.required]"
                v-model="form.birthDate"
                past
                :editable="true"
              /> -->
            </v-col>
            <v-col cols="12" sm="2" class="px-1">
              <label class="font-weight-medium" for="gender"
                >Gender <span class="red--text">*</span>
              </label>
              <v-select
                :items="genders"
                item-text="label"
                item-value="value"
                v-model="form.isMale"
                outlined
                dense
              ></v-select>
            </v-col>
            <v-col cols="12" sm="3" class="px-1">
              <label class="font-weight-medium" for="phone">
                Phone <span class="red--text">*</span></label
              >
              <v-text-field v-model="form.phone" :rules="[rules.phone]" outlined dense />
            </v-col>
            <v-col cols="12" sm="4" class="px-1">
              <label class="font-weight-medium" for="email"> Email </label>
              <v-text-field v-model="form.email" :rules="[rules.email]" outlined dense />
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" sm="4">
              <label class="font-weight-medium" for="address">Address </label>
              <v-text-field
                v-model="form.address.addressLine1"
                prepend-inner-icon="mdi-map-marker"
                dense
                autocomplete="street-address"
                outlined
              />
            </v-col>
            <v-col cols="12" sm="2" class="pl-1">
              <label class="font-weight-medium" for="apto">Apto </label>
              <v-text-field
                v-model="form.address.addressLine2"
                dense
                autocomplete="apto-address"
                outlined
              />
            </v-col>
            <v-col cols="12" sm="2" class="px-1">
              <label class="font-weight-medium" for="state">State </label>
              <v-autocomplete
                v-model="state"
                :items="states"
                item-text="name"
                item-value="id"
                :loading="loadingStates"
                autocomplete="off"
                outlined
                dense
              />
            </v-col>
            <v-col cols="12" sm="2" class="pr-1">
              <label class="font-weight-medium" for="city">City </label>
              <v-autocomplete
                :disabled="stateCities.length == 0"
                v-model.number="form.address.city"
                :items="stateCities"
                item-text="name"
                item-value="id"
                :loading="loadingCities"
                autocomplete="off"
                mandatory
                outlined
                dense
              />
            </v-col>
            <v-col cols="12" sm="2">
              <label class="font-weight-medium" for="zip">Zip </label>
              <v-text-field
                v-model="form.address.zipCode"
                :rules="[rules.zip]"
                autocomplete="postal-code"
                outlined
                dense
              />
            </v-col>
          </v-row>
        </v-form>

        <v-alert type="error" :value="formValid == false">
          {{
            !validDate ? "Invalid Date of Birth" : "All fields with ( * ) are required"
          }}
        </v-alert>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn
          color="error"
          :disabled="loading"
          width="100"
          dark
          depressed
          rounded
          @click="cancel"
        >
          Cancel
        </v-btn>

        <v-btn
          class="ml-3"
          color="primary"
          :disabled="loading || validAddForm == false"
          :loading="loading"
          width="100"
          depressed
          rounded
          @click="update"
        >
          Update
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import { getAPI } from "@/api/axios-base";
import rules from "@/components/account/rules";
import MaDatePicker from "@/components/MaDatePicker/MaDatePicker.vue";
import DobPicker from "@/components/MaDatePicker/DobPicker.vue";
import { notifyError, notifySuccess } from "@/components/Notification";
import cleanObject from "@/utils/cleanObject";
import moment from "moment";
import { mapActions, mapState } from "vuex";
export default {
  components: { DobPicker },
  data() {
    return {
      loading: false,
      formValid: false,
      loadingCities: false,
      stateCities: [],
      state: 0,
      uuid: null,
      birthDate: "",
      form: {
        name: null,
        lastName: null,
        birthDate: null,
        email: null,
        phone: null,
        isMale: true,
        address: {
          addressLine1: null,
          addressLine2: null,
          city: null,
          state: null,
          zipCode: null,
        },
      },
      rules: {
        required: rules.required,
        email: rules.emailnull,
        zip: rules.isZipCode,
        phone: rules.isPhone,
        phone1: rules.isPhone1,
      },
      genders: [
        { label: "Female", value: false },
        { label: "Male", value: true },
      ],
    };
  },
  computed: {
    ...mapState("crmMedicFormModule", ["states", "loadingStates"]),
    validAddForm() {
      return this.validDate && this.formValid;
    },
    validDate() {
      if (this.form.birthDate == "" || this.form.birthDate == null) {
        return false;
      }
      const d = moment(this.form.birthDate);
      const now = moment();
      if (d.isBefore(now)) {
        return true;
      }
      return false;
    },
    noDataAddress() {
      const add = this.form.address;
      if (
        add.addressLine1 == null &&
        add.addressLine2 == null &&
        add.state == null &&
        add.city == null &&
        add.zipCode == null
      ) {
        return true;
      }
      return false;
    },
  },
  watch: {
    state: function (stateId) {
      if (stateId == undefined || stateId == null) {
        this.stateCities = [];
        this.loadingCities = false;

        return;
      }

      this.loadingCities = true;
      this.form.address.state = stateId;
      this.actGetCityByState(stateId).then((cities) => {
        this.loadingCities = false;
        this.stateCities = cities;
      });
    },
  },
  methods: {
    ...mapActions("crmMedicFormModule", ["actListStates", "actGetCityByState"]),

    update() {
      let body = Object.assign({}, this.form);
      body.phone = "+1" + body.phone;
      body.birthDate = moment(body.birthDate).toISOString();
      if (this.noDataAddress) {
        delete body.address;
      } else {
        if (body.address.state) {
          body.address.state = body.address.state.toString();
        }
        if (body.address.city) {
          body.address.city = body.address.city.toString();
        }
      }
      body = cleanObject(body);
      this.loading = true;
      getAPI
        .post("/customer/update/" + this.uuid, body)
        .then((res) => {
          this.loading = false;
          notifySuccess("Customer has been updated");
          this.cancel();
        })
        .catch((error) => {
          this.loading = false;
          let mess = error.response.data.message.toString();
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
    cancel() {
      this.$router.push(`/customers/details/${this.uuid}`);
    },

    getCustomer() {
      this.loading = true;
      getAPI("/customer/" + this.uuid)
        .then((res) => {
          const customer = res.data;
          this.initForm(customer);

          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          let mess = error.response.data.message.toString();
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },

    async initForm(value) {
      this.form.name = value.name;
      this.form.lastName = value.lastName;
      this.form.birthDate = value.birthDate;
      this.birthDate = value.birthDate;
      this.form.email = value.email;
      this.form.phone = value.phone ? value.phone.replace("+1", "") : null;
      this.form.isMale = value.isMale;
      this.form.address.addressLine1 = value.address ? value.address.addressLine1 : null;
      this.form.address.addressLine2 = value.address ? value.address.addressLine2 : null;
      this.form.address.zipCode = value.address ? value.address.zipCode : null;
      await this.actListStates();
      this.state = value.address ? Number(value.address.state) : null;
      this.form.address.city = value.address ? Number(value.address.city) : null;
      /*  address: {
          addressLine1: value.address ? value.address.addressLine1 : null,
          addressLine2: value.address ? value.address.addressLine2 : null,
          state: value.address ? Number(value.address.state) : null,
          city: value.address ? Number(value.address.city) : null,
          zipCode: value.address ? value.address.zipCode : null,
        }, */
    },
  },
  async mounted() {
    this.uuid = this.$route.params.uuid;
    await this.getCustomer();
  },
};
</script>
<style lang=""></style>
