import { render, staticRenderFns } from "./FormUpdate.vue?vue&type=template&id=3bc73004&scoped=true&"
import script from "./FormUpdate.vue?vue&type=script&lang=js&"
export * from "./FormUpdate.vue?vue&type=script&lang=js&"
import style0 from "./FormUpdate.vue?vue&type=style&index=0&id=3bc73004&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3bc73004",
  null
  
)

/* custom blocks */
import block0 from "./FormUpdate.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSlideXReverseTransition } from 'vuetify/lib/components/transitions';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VAlert,VAutocomplete,VBtn,VCard,VCardActions,VCardText,VCardTitle,VChip,VCol,VDialog,VDivider,VForm,VIcon,VRadio,VRadioGroup,VRow,VSelect,VSlideXReverseTransition,VTextField,VTextarea,VTooltip})
