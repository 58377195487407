<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div class="d-flex flex-column mt-4 mx-2">
    <v-form ref="form" id="create-payment" name="create-payment">
      <template>
        <div class="d-flex fill-width">
          <v-text-field
            type="number"
            prefix="$"
            label="Amount"
            v-model="body.amount"
            outlined
            dense
          />

          <v-select
            outlined
            class="mx-1"
            dense
            item-text="label"
            item-value="value"
            :items="typesShow"
            v-model="type"
            label="Type"
          ></v-select>
          <v-select
            outlined
            class="mr-1"
            dense
            :items="yearShow"
            v-model="body.year"
            label="Year"
          ></v-select>
          <v-select
            outlined
            item-text="name"
            item-value="value"
            clearable
            dense
            :items="monthShow"
            v-model="body.month"
            label="Month"
          >
            <template v-slot:selection="{ item, index }">
              <template v-if="index === 0">
                <span>{{ item.name }}</span>
              </template>
              <span v-if="index === 1" class="grey--text text-caption mx-1">
                (+{{ body.month.length - 1 }} others)
              </span>
            </template>
          </v-select>

          <!--    <v-btn color="error" width="120px" depressed rounded @click="reset()">
            Cancel
          </v-btn> -->

          <v-btn
            color="success"
            :loading="loading"
            class="mx-2"
            rounded
            :disabled="loading || !validPay || existPay"
            depressed
            @click="addPayment()"
          >
            <v-icon>mdi-currency-usd</v-icon> {{ "Pay" }}
          </v-btn>
        </div>

        <!--  <v-textarea
          :rules="[rules.required]"
          outlined
          label="Details"
          :disabled="creating"
          v-model.trim="body.details"
        ></v-textarea> -->
      </template>
    </v-form>

    <template v-if="existPay">
      <v-alert outlined dense class="mr-4" type="error" :value="true">
        {{ "There can only be one payment per date" }}
      </v-alert>
    </template>
  </div>
</template>

<script>
/* eslint-disable @typescript-eslint/no-explicit-any */
import PatientCard from "@/views/Patients/components/PatientCard.vue";
import { loadStripeTerminal } from "@stripe/terminal-js";

import Vue from "vue";
import rules from "@/components/account/rules";
import {
  notificationError,
  notifyError,
  notifySuccess,
  notifyInfo,
} from "@/components/Notification";
import { getAPI } from "@/api/axios-base";
import { mapGetters, mapMutations, mapState } from "vuex";
import moment from "moment";
import months from "@/utils/enums/months";
export default Vue.extend({
  props: {
    payments: Array,
    companyState: Object,
  },
  components: { PatientCard },
  name: "make-payment",

  data() {
    return {
      loading: false,
      rules,
      btnText: "Submit",
      confirmate: false,
      creating: false,
      discount: 0,
      type: "COMISION",
      body: {
        amount: null,
        type: "COMISION",
        month: null,
        year: moment().format("YYYY"),
      },
    };
  },
  watch: {
    type: {
      handler(val) {
        this.body.type = val;
        if (val == "COMISION") {
          this.body.amount = this.priceState;
        } else {
          this.body.amount = null;
        }
      },
    },
  },

  computed: {
    ...mapState("crmMedicFormModule", ["patientDetail"]),
    monthShow() {
      return months;
    },

    priceState() {
      if (this.companyState == null || this.companyState == undefined) {
        return 0;
      }
      return this.companyState.companyStatePrices[0].price;
    },
    existPay() {
      if (this.payments.length == 0) {
        return false;
      }
      const pay = this.payments.find(
        (p) => p.month == this.body.month && p.year == this.body.year
      );
      return pay ? true : false;
    },
    validPay() {
      if (this.body.month != null && this.body.year != null && this.body.amount != null) {
        return true;
      }
      return false;
    },

    yearShow() {
      const actualyear = moment();
      let years = [actualyear.add(-1, "month").format("YYYY")];
      for (let index = 0; index < 2; index++) {
        const yar = moment();
        const element = yar.add(index, "year");
        years.push(element.format("YYYY"));
      }
      return years;
    },
    cardShow() {
      const cardN = this.patientDetail.cardNumber;
      return `****-****-****-${cardN}`;
    },

    typesShow() {
      return [
        { value: "COMISION", label: "Comision" },
        { value: "OVERIDE", label: "Overide" },
        { value: "BONUS", label: "Bonus" },
      ];
    },

    /*  expShow() {
      const exp = this.patientDetail.cardExpireDate;
      const year = exp.split("-")[0].substr(2, 2);
      const month = exp.split("-")[1];
      return `${month}/${year}`;
    }, */
  },

  methods: {
    addPayment() {
      const body = { ...this.body, patientUuid: this.patientDetail.uuid };

      body.year = Number(body.year);
      body.amount = Number(body.amount);

      this.loading = true;
      getAPI
        .post("/patient/createPayment", body)
        .then((res) => {
          this.loading = false;
          notifyInfo("Payment Confirmed");
          this.$emit("payment");
          this.$emit("confirmed");
          this.reset();
        })
        .catch((err) => {
          this.loading = false;
          let mess = err.response?.data?.message.toString();

          notifyError(err.response.data, `An error occurred: ${mess}`);
        });

      /*  if (this.confirmate) {
        if (body.type == "Stripe") {
          if (this.epaycard && body.type == "Stripe") {
            body.type = "UsaEpay";
          }
          getAPI
            .post("/payment/confirm-payment", {
              paymentUuid: this.confirmPay.payment.uuid,
              cardId: body.cardId,
            })
            .then(() => {
              this.loading = false;
              notifyInfo("Payment Confirmed");
              this.$emit("payment");
              this.$emit("confirmed");
              this.reset();
            })
            .catch((error) => {
              this.loading = false;

              if (error.response?.status == 400) {
                notifyError(
                  error.response.data.message || error.response.data.details
                );
              } else {
                notificationError();
              }
            });
        }
      } */

      /******** */
    },
    reset() {
      this.type = "COMISION";
      this.body = {
        month: null,
        year: moment().format("YYYY"),
      };
    },
  },
  mounted() {
    this.body.year = moment().format("YYYY");
    if (this.companyState) {
      this.body.amount = this.companyState.companyStatePrices[0].price;
      this.body.type = "COMISION";
    }
  },
});
</script>
<style lang="scss" scoped>
.cardshow {
  font-size: 24px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
}
.otherdates {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
}
.bg-custom {
  margin-top: 2rem;
  font: bold 100% sans-serif;
  letter-spacing: 0.5em;
  text-align: center;
  text-transform: uppercase;
  background: #26a69a;
  border-radius: 0.25em;
  color: #fff;
  margin: 0 0 1em;
  padding: 0.5em 0;
}
</style>
