<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div>
    <template>
      <v-stepper v-model="fase" vertical>
        <v-stepper-step :complete="fase > 1" step="1">
          Upload File
          <small>Files format: xls, csv , xlsx</small>
        </v-stepper-step>

        <v-stepper-content step="1">
          <v-file-input
            v-model="file"
            accept=".xls,.csv,.xlsx"
            show-size
            truncate-length="15"
            label="Select File"
          ></v-file-input>
          <v-btn
            color="primary"
            :loading="loading"
            :disabled="loading || file == null"
            @click="firstStep"
          >
            Continue
          </v-btn>
        </v-stepper-content>

        <v-stepper-step :complete="fase > 2" step="2">
          Data to match
          <small>Select files to match</small>
        </v-stepper-step>

        <v-stepper-content step="2">
          <template v-if="headersData.length != 0">
            <v-row class="pa-1">
              <v-col cols="12" sm="6">
                <v-select
                  :items="headersData"
                  v-model="headers.polizeNumber"
                  label="Policy Number *"
                  outlined
                  clearable
                  dense
                ></v-select>
                <v-select
                  :items="headersData"
                  v-model="headers.name"
                  label="Client name "
                  outlined
                  clearable
                  dense
                ></v-select>
                <v-select
                  :items="headersData"
                  v-model="headers.lastName"
                  label="Client Last Name "
                  outlined
                  clearable
                  dense
                ></v-select>
                <v-select
                  :items="headersData"
                  v-model="headers.dob"
                  label="DOB "
                  outlined
                  clearable
                  dense
                ></v-select>

                <v-select
                  :items="companies"
                  v-model="headers.companyId"
                  label="Company Id *"
                  :loading="loadingRefer"
                  item-text="name"
                  item-value="id"
                  outlined
                  clearable
                  dense
                ></v-select>
              </v-col>
              <template v-if="firstData != null">
                <v-col class="d-flex flex-column" cols="12" sm="6">
                  <v-text-field
                    label="Policy Number "
                    disabled
                    outlined
                    dense
                    :value="getData(headers.polizeNumber)"
                  ></v-text-field>
                  <v-text-field
                    label="Client Name"
                    disabled
                    outlined
                    dense
                    :value="getData(headers.name)"
                  ></v-text-field>
                  <v-text-field
                    label="Client Last Name"
                    disabled
                    outlined
                    dense
                    :value="getData(headers.lastName)"
                  ></v-text-field>
                  <v-text-field
                    label="DOB"
                    disabled
                    :class="
                      getDataDob(headers.dob) == 'Invalid date'
                        ? 'errordate'
                        : ''
                    "
                    outlined
                    dense
                    :value="getDataDob(headers.dob)"
                  ></v-text-field>

                  <v-text-field
                    label="Company Id"
                    disabled
                    outlined
                    dense
                    :value="getCompanySelect(headers.companyId)"
                  ></v-text-field>
                </v-col>
              </template>
            </v-row>
            <v-alert outlined type="error" :value="!validMatch || !validDate">
              {{ !validDate ? "Invalid DOB" : "Fields with (*) are required" }}
            </v-alert>
          </template>
          <v-btn
            color="primary"
            :loading="loading"
            :disabled="loading || !validMatch"
            @click="secondStep"
          >
            Confirm
          </v-btn>
          <v-btn
            color="error"
            class="ml-1"
            :disabled="loading"
            @click="resetAll"
          >
            Cancel
          </v-btn>
        </v-stepper-content>

        <v-stepper-step step="3" :complete="fase == 3">
          Import successful
        </v-stepper-step>
        <v-stepper-content step="3">
          <v-btn color="primary" @click="resetAll"
            ><v-icon class="mr-1">mdi-cloud-upload</v-icon> import another
          </v-btn>
          <v-btn class="mx-2" color="warning" @click="backclients">
            <v-icon>mdi-exit-to-app</v-icon> Exit
          </v-btn>
        </v-stepper-content>
      </v-stepper>
    </template>
  </div>
</template>
<script>
import { getAPI } from "@/api/axios-base";
import cleanObject from "@/utils/cleanObject";
import { notifyError } from "@/components/Notification";
import { mapActions, mapState } from "vuex";
import moment from "moment";
export default {
  name: "import-delincuents",
  data() {
    return {
      loading: false,
      headersData: [],

      firstData: null,
      lastResult: null,
      configResult: null,
      file: null,
      fase: 1,
      headers: {
        polizeNumber: null,
        name: null,
        lastName: null,
        dob: null,
        paymentAmount: null,

        companyId: null,
      },
    };
  },
  computed: {
    ...mapState("crmSettingsModule", ["loadingRefer", "companies"]),

    validDate() {
      let temp = "";
      if (this.headers.dob == null) {
        return true;
      }
      if (
        this.headers.dob != null &&
        this.firstData[this.headers.dob] != undefined
      ) {
        temp = this.firstData[this.headers.dob].toString();
      }
      if (
        this.headers.dob != null &&
        temp.includes("T") &&
        temp.includes(":") &&
        temp.includes("-") &&
        temp.includes("Z")
      ) {
        return true;
      }
      return false;
    },
    validMatch() {
      if (
        this.headers.polizeNumber != null &&
        this.headers.companyId != null &&
        this.validDate
      ) {
        return true;
      }
      return false;
    },
  },
  methods: {
    ...mapActions("crmSettingsModule", ["actListCompaniesNom"]),
    getData(value) {
      return this.firstData[value];
    },
    getDataDob(value) {
      let temp = this.firstData[value];
      if (value == null || temp == undefined) {
        return "Invalid date";
      }
      temp = temp.toString();
      if (
        temp.includes("T") &&
        temp.includes(":") &&
        temp.includes("-") &&
        temp.includes("Z")
      ) {
        const dat = temp.split("T")[0];
        const d = dat.split("-")[2];
        const m = dat.split("-")[1];
        const y = dat.split("-")[0];
        return `${m}/${d}/${y}`;
      }

      return "Invalid date";
    },

    getCompanySelect(value) {
      return this.companies.find((c) => c.id == value)?.name;
    },
    firstStep() {
      this.loading = true;
      const formDatafile = new FormData();

      formDatafile.append("file", this.file);

      getAPI
        .post("/social/decodeFilePreview", formDatafile)
        .then((res) => {
          this.loading = false;
          this.headersData = res.data.headers;
          this.firstData = res.data.first;
          this.fase = 2;
        })
        .catch((error) => {
          this.loading = false;
          let mess = error.response.data.message.toString();

          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
    secondStep() {
      this.loading = true;
      let body = Object.assign({}, this.headers);

      body = cleanObject(body);
      if (body.companyId) {
        body.companyId = Number(body.companyId);
      }

      const formDatafile = new FormData();

      formDatafile.append("file", this.file);

      Object.entries(body).forEach(([key, value]) => {
        formDatafile.append(key, value);
      });

      getAPI
        .post("/task-import/updateDelincuents", formDatafile)
        .then((res) => {
          this.loading = false;
          this.firstResult = res.data.first;
          this.lastResult = res.data.last;
          this.configResult = res.data.config;
          this.fase = 3;
        })
        .catch((error) => {
          this.loading = false;
          let mess = error.response.data.message.toString();
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },

    backclients() {
      this.$router.push("/healths");
    },

    resetAll() {
      this.file = null;
      this.loading = false;
      this.fase = 1;
      this.headersData = [];
      this.firstData = null;
      this.lastResult = null;
      this.configResult = null;

      this.headers = {
        polizeNumber: null,
        name: null,
        lastName: null,
        dob: null,
        paymentAmount: null,

        companyId: 0,
      };
    },
  },
  mounted() {
    this.actListCompaniesNom();
  },
};
</script>
<style lang="scss" scoped>
.errordate {
  color: red !important;
}
</style>
