var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex align-center pa-3"},[_vm._v(" "+_vm._s(/* Filter */)+" "),_c('div',{staticClass:"col-sm-3"},[_c('v-text-field',{attrs:{"label":'Search',"hide-details":"","prepend-inner-icon":"mdi-magnify","clearable":"","outlined":"","dense":""},model:{value:(_vm.query),callback:function ($$v) {_vm.query=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"query"}})],1),_c('div',{staticClass:"col-sm-3"},[_c('v-text-field',{attrs:{"dense":"","outlined":"","hide-details":"","label":"by Policy Number","prepend-inner-icon":"mdi-magnify","clearable":""},model:{value:(_vm.polizeNumber),callback:function ($$v) {_vm.polizeNumber=$$v},expression:"polizeNumber"}})],1)]),_c('v-data-table',{attrs:{"headers":_vm.headers,"options":_vm.options,"server-items-length":_vm.totalhealths,"items":_vm.healths,"loading":_vm.loading,"footer-props":_vm.footerProps,"item-key":"uuid"},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.showDetails},scopedSlots:_vm._u([{key:"item.fullname",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"namepatient"},[_vm._v(_vm._s(item.fullname)+" ")])]}},{key:"item.relations",fn:function(ref){
var item = ref.item;
return [(item.relations && item.relations.length != 0)?[_c('div',{staticClass:"d-flex flex-column"},_vm._l((item.relations),function(r,i){return _c('div',{key:r.id,staticClass:"mr-1"},[_vm._v(" "+_vm._s(r.employee ? r.employee.fullname : "-")+_vm._s(i == item.relations.length - 1 ? "" : ", ")+" ")])}),0)]:[_vm._v(" - ")]]}},{key:"item.patientTagsNom",fn:function(ref){
var item = ref.item;
return [(item.patientTagsNom && item.patientTagsNom.length != 0)?[_c('div',{staticClass:"chipstag"},_vm._l((item.patientTagsNom),function(itm){return _c('v-chip',{key:itm.id,staticClass:"mr-1",attrs:{"color":_vm.colorShowT(itm),"dark":""}},[_vm._v(" "+_vm._s(itm.tagNom ? itm.tagNom.name : "-")+" "),(itm.asAdmin)?[_c('v-icon',{staticClass:"ml-1",attrs:{"color":"white","small":""}},[_vm._v("mdi-star")])]:_vm._e()],2)}),1)]:[_vm._v("-")]]}},{key:"item.company",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.company ? item.company.name : "-")+" ")]}},{key:"item.quotedDate",fn:function(ref){
var item = ref.item;
return [(item.quotedDate)?[_vm._v(" "+_vm._s(_vm.getDate(item.quotedDate))+" ")]:[_c('v-icon',{attrs:{"color":"error","dense":""}},[_vm._v("mdi-close")])]]}},{key:"item.companyState",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.companyState ? item.companyState.name : "-")+" ")]}},{key:"item.call",fn:function(ref){
var item = ref.item;
return [(item.calls.length == 0)?[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-close")])]:[_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-check")])]]}},{key:"item.totalMembers",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.totalMembers ? item.totalMembers : 0)+" ")])]}},{key:"item.effectiveDate",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.formatDateE(item.effectiveDate))+" ")])]}},{key:"item.isMale",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item + ";asd")+" ")])]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.email + "asda")+" ")])]}},{key:"item.homePhone",fn:function(ref){
var item = ref.item;
return [(item.homePhone)?[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.makeCallV(item.homePhone)}}},[_vm._v(" "+_vm._s(item.homePhone)+" ")]),_c('div',{staticClass:"mx-1"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","color":"info"},on:{"click":function($event){return _vm.copyPhone(item.homePhone)}}},on),[_vm._v("mdi-content-copy")])]}}],null,true)},[_c('span',[_vm._v("Copy Phone")])])],1)])]:[_vm._v("-")]]}},{key:"item.polizeNumber",fn:function(ref){
var item = ref.item;
return [(item.polizeNumber)?[_c('div',{staticClass:"d-flex"},[_c('div',[_vm._v(" "+_vm._s(item.polizeNumber)+" ")]),_c('div',{staticClass:"mx-1"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","color":"info"},on:{"click":function($event){return _vm.copyPolizeNumber(item.polizeNumber)}}},on),[_vm._v("mdi-content-copy")])]}}],null,true)},[_c('span',[_vm._v("Copy Policy Number")])])],1)])]:[_vm._v("-")]]}},{key:"item.policyPlan",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"font-weight-bold",style:({ color: _vm.colorPolicy(item.policyPlan) })},[_vm._v(" "+_vm._s(item.policyPlan)+" ")])]}},{key:"item.agent",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getAgent(item))+" ")]}},{key:"item.sourceNom",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold",style:({ color: _vm.colorShowS(item) })},[_vm._v(_vm._s(item.sourceNom ? item.sourceNom.name : "-"))])]}},{key:"item.npnNom",fn:function(ref){
var item = ref.item;
return [(item.npnNom)?[_c('span',{staticClass:"font-weight-bold",style:({ color: item.npnNom.color })},[_vm._v(_vm._s(item.npnNom ? item.npnNom.name : "-"))])]:_vm._e()]}},{key:"item.payToNom",fn:function(ref){
var item = ref.item;
return [(item.payToNom)?[_c('span',{staticClass:"font-weight-bold",style:({ color: item.payToNom.color })},[_vm._v(_vm._s(item.payToNom ? item.payToNom.name : "-"))])]:_vm._e()]}},{key:"item.renewalStatusNom",fn:function(ref){
var item = ref.item;
return [(item.renewalStatusNom)?[_c('span',{staticClass:"font-weight-bold",style:({ color: item.renewalStatusNom.color })},[_vm._v(_vm._s(item.renewalStatusNom ? item.renewalStatusNom.name : "-"))])]:_vm._e()]}},{key:"item.paymentStatus",fn:function(ref){
var item = ref.item;
return [(item.paymentStatus)?[_c('span',{staticClass:"font-weight-bold",style:({ color: _vm.colorShowPaymentStatus(item.paymentStatus) })},[_vm._v(_vm._s(_vm.ToshowPaymentStatus(item.paymentStatus)))])]:_vm._e()]}},{key:"item.polizeStatus",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","origin":"center center","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"text-body-1 cursor-pointer",style:({ color: _vm.colorShow(item) })},on),[_vm._v(" "+_vm._s(_vm.statusShow(item))+" ")])]}}],null,true)},[_c('v-list',_vm._l((_vm.policyStates),function(stat,index){return _c('v-list-item',{key:index,staticClass:"cursor-pointer",attrs:{"disabled":item.polizeStatus == stat.value},on:{"click":function($event){return _vm.gotosetStatus(item, stat)}}},[_c('v-list-item-title',{class:item.polizeStatus == stat.value ? 'status-active' : ''},[_vm._v(_vm._s(stat.label))])],1)}),1)],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }