<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div>
    <div class="d-flex pa-2">
      <div class="col-sm-8">
        <v-row no-gutters>
          <v-col>
            <ma-date-picker
              :datepickerProps="{
                'no-title': true,
              }"
              v-model="dateFrom"
              label="From"
              past
            />
          </v-col>
          <v-col class="ml-2">
            <ma-date-picker
              :datepickerProps="{
                'no-title': true,
              }"
              v-model="dateTo"
              label="To"
              past
            />
          </v-col>
        </v-row>
      </div>
      <template v-if="dateFrom != null || dateTo != null">
        <div class="col-sm-3">
          <v-btn class="my-2" icon small dark color="red" @click="refresh()">
            <v-icon dark> mdi-cancel </v-icon>
          </v-btn>
        </div>
      </template>
      <v-spacer></v-spacer>

      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            class="my-2"
            v-on="on"
            color="success"
            icon
            :loading="donwloading"
            :disabled="donwloading"
            @click="exportLogs"
            ><v-icon>mdi-file-excel</v-icon></v-btn
          >
        </template>
        <span>Export</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            class="my-2"
            v-on="on"
            color="primary"
            icon
            @click="getClientsLogs"
            ><v-icon>mdi-reload</v-icon></v-btn
          >
        </template>
        <span>Reload</span>
      </v-tooltip>
    </div>
    <v-data-table
      :loading="loading"
      :options.sync="options"
      :headers="headers"
      :items="clientslogs"
      :server-items-length="totallogs"
      :items-per-page="10"
      class="elevation-1"
    >
      <template v-slot:[`item.dob`]="{ item }">
        {{ getDAte(item.dob) }}
      </template>
      <template v-slot:[`item.createdAt`]="{ item }">
        {{ getDate(item.createdAt) }}
      </template>
      <template v-slot:[`item.task`]="{ item }">
        {{ getFile(item.task) }}
      </template>
      <template v-slot:[`item.taskType`]="{ item }">
        {{ item.task ? getType(item.task.taskType) : "" }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <template
          v-if="
            item.name != null &&
            item.lastName != null &&
            item.paymentAmount == null
          "
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" color="primary" @click="convertPatient(item)">
                mdi-account-convert</v-icon
              >
            </template>
            <span>Convert to Patient</span>
          </v-tooltip>
        </template>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import { getAPI } from "@/api/axios-base";
import { notifyError } from "@/components/Notification";
import MaDatePicker from "@/components/MaDatePicker/MaDatePicker.vue";
import { utilMixin } from "@/utils/mixins";
import { apiBaseUrl } from "@/enviorment";
import moment from "moment";
import axios from "axios";
import { upperCase } from "lodash";
export default {
  name: "no-clients-logs",
  components: { MaDatePicker },
  mixins: [utilMixin],
  data() {
    return {
      clientslogs: [],
      totallogs: 0,
      toExport: null,
      donwloading: false,
      loading: false,
      options: {},
      headers: [
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "name",
        },
        {
          text: "Last Name",
          align: "start",
          sortable: false,
          value: "lastName",
        },
        {
          text: "DOB",

          sortable: false,
          value: "dob",
        },
        {
          text: "Policy Number",
          align: "start",
          sortable: false,
          value: "polizeNumber",
        },
        {
          text: "Task Type",
          align: "start",
          sortable: false,
          value: "taskType",
        },
        {
          text: "File Task",
          align: "start",
          sortable: false,
          value: "task",
        },
        {
          text: "Members",
          align: "center",
          sortable: false,
          value: "members",
        },
        {
          text: "Create date",
          sortable: false,
          value: "createdAt",
        },
        { text: "", align: "center", value: "actions", sortable: false },
      ],

      dateTo: null,
      dateFrom: null,
    };
  },
  watch: {
    options: {
      handler() {
        this.getClientsLogs();
      },
      deep: true,
    },

    dateFrom(val) {
      if (val !== null && this.dateTo !== null && this.dateTo !== "") {
        this.getClientsLogs();
      }
    },
    dateTo(val) {
      if (val !== null && this.dateFrom !== null && this.dateFrom !== "") {
        this.getClientsLogs();
      }
    },
  },
  methods: {
    getDAte(value) {
      if (value) {
        const y = value.split("-")[0];
        const m = value.split("-")[1];
        const d = value.split("-")[2];
        return `${m}/${d}/${y}`;
      }
      return "-";
    },

    getType(type) {
      let t = type;
      if (t == "UPDATE_POLIZES") {
        t = "UPDATE_POLICIES";
      }

      if (t == "UPDATE_DELINCUENTS") {
        t = "UPDATE_DELINQUENTS";
      }

      t = t.replaceAll("_", " ");

      return upperCase(t);
    },
    getFile(file) {
      if (file) {
        const temp = file && file.minioFile ? file.minioFile : "";
        const filetemp = temp.split("/");
        return filetemp.length > 2 ? filetemp[2] : "";
      }
      return "";
    },

    refresh() {
      this.dateFrom = null;
      this.dateTo = null;
      this.getClientsLogs();
    },
    getClientsLogs() {
      const { page, itemsPerPage } = this.options;
      const itmper = itemsPerPage != -1 ? itemsPerPage : 10;
      const body = {
        limit: itmper,
        offset: (page - 1) * itmper,
        taskType: ["INSERT_PAYMENTS", "UPDATE_POLIZES", "REMOVE_PAYMENTS"],
        createdInterval: {
          date1: moment(this.dateFrom).startOf("day").utc().toISOString(),
          date2: moment(this.dateTo).endOf("day").utc().toISOString(),
        },
      };
      if (
        this.dateFrom == null ||
        this.dateTo == null ||
        this.dateFrom == "" ||
        this.dateTo == ""
      ) {
        delete body.createdInterval;
      }

      this.toExport = body;
      this.loading = true;
      getAPI
        .post("/task-import/list-tasks-logs", body)
        .then((res) => {
          this.loading = false;
          this.totallogs = res.data.count;
          this.clientslogs = res.data.result;
        })
        .catch((error) => {
          this.loading = false;
          let mess = error.response.data.message.toString();
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },

    convertPatient(item) {
      const body = {
        name: item.name,
        lastName: item.lastName,
        members: item.members,
        dob: item.dob,
        polizeNumber: item.polizeNumber,
      };

      const st = JSON.stringify(body);
      const codif = btoa(st);
      localStorage.setItem("log-client", codif);
      this.$router.push("/healths/create");
    },

    exportLogs() {
      const token = this.$store.state.accessToken;
      let auth =
        token != undefined && token != null && token != ""
          ? {
              Accept: "*/*",
              Authorization: `Bearer ${token}`,
            }
          : {
              Accept: "*/*",
            };
      const date = moment().format("MM/DD/YYYY HH:mm");

      const name = "Task-Logs-" + date + ".xlsx";
      this.donwloading = true;
      const url = apiBaseUrl + "/task-import/list-tasks-logs?export=true";

      axios({
        url: url,
        method: "POST",
        responseType: "blob",
        headers: auth,
        data: this.toExport,
      })
        .then((res) => {
          this.donwloading = false;
          const file_path = URL.createObjectURL(res.data);
          const a = document.createElement("A");
          a.href = file_path;
          a.setAttribute("download", name);
          a.setAttribute("target", "_blank");
          a.click();
        })
        .catch((err) => {
          this.donwloading = false;
        });
    },
  },
  mounted() {
    localStorage.removeItem("log-client");
  },
};
</script>
<style lang=""></style>
