<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div>
    <!--  <div class="d-flex pa-2">
      <v-col cols="12" sm="3">
        <v-autocomplete
          :items="workers"
          v-model="createdByUuid"
          label="Created by"
          item-text="fullname"
          item-value="uuid"
          :loading="loading"
          outlined
          @change="getHistorylogs"
          clearable
          dense
        ></v-autocomplete>
      </v-col>
      <template v-if="!haveClient">
        <v-col cols="12" sm="3">
          <v-autocomplete
            :items="patients"
            v-model="patientUuid"
            label="Filter by Client"
            item-text="fullname"
            item-value="uuid"
            :loading="loading"
            outlined
            @change="getHistorylogs"
            clearable
            dense
          ></v-autocomplete>
        </v-col>
      </template>

      <v-col cols="12" sm="5" class="d-flex">
        <v-row>
          <v-col>
            <ma-date-picker v-model="dateFrom" label="From" past />
          </v-col>
          <v-col><ma-date-picker v-model="dateTo" label="To" past /></v-col>
        </v-row>
      </v-col>
      <template v-if="dateFrom || dateTo">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              small
              color="error"
              icon
              class="my-4"
              @click="cleanFilters"
              ><v-icon>mdi-close</v-icon></v-btn
            >
          </template>
          <span>Clean Filters</span>
        </v-tooltip>
      </template>

      <v-spacer></v-spacer>

      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            class="my-3"
            v-on="on"
            color="primary"
            icon
            @click="getHistorylogs"
            ><v-icon>mdi-reload</v-icon></v-btn
          >
        </template>
        <span>Reload</span>
      </v-tooltip>
    </div> -->
    {{/* Interval indicator */}}
    <!--  <template v-if="dateFrom && dateTo">
      <v-layout class="px-4 pb-2" column>
        <v-layout column class="text-body-1 text--secondary">
          <div>
            From:
            <span class="text-caption">{{ prettyDateShow(query.date1) }}</span>
          </div>
          <div>
            To:
            <span class="text-caption">{{ prettyDateShow(query.date2) }}</span>
          </div>
        </v-layout>
      </v-layout>
    </template> -->
    <div class="d-flex justify-end my-3">
      <v-btn color="primary" text @click="getLogs"
        ><v-icon>mdi-refresh</v-icon>Reload</v-btn
      >
    </div>
    <v-data-table
      :loading="loading"
      :options.sync="options"
      :headers="headers"
      :items="statusLogs"
      single-expand
      show-expand
      item-key="uuid"
      :expanded.sync="expanded"
      :server-items-length="totallogs"
      :items-per-page="10"
      class="elevation-1"
    >
      <template v-slot:[`item.fullname`]="{ item }">
        {{ item.before ? item.before.fullname : "-" }}
      </template>
      <template v-slot:[`item.before`]="{ item }">
        <span
          v-bind:style="{ color: colorShowS(item.beforePolizeStatus) }"
          class="font-weight-bold cursor-pointer"
          >{{ statusShowS(item.beforePolizeStatus) }}</span
        >
      </template>
      <template v-slot:[`item.after`]="{ item }">
        <span
          v-bind:style="{ color: colorShowS(item.polizeStatus) }"
          class="font-weight-bold cursor-pointer"
          >{{ statusShowS(item.polizeStatus) }}</span
        >
      </template>
      <template v-slot:[`item.polizeStatus`]="{ item }">
        <span
          v-bind:style="{ color: colorShowS(item) }"
          class="font-weight-bold cursor-pointer"
          >{{ statusShowS(item) }}</span
        >
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-container>
            <v-row>
              <v-col cols="12" sm="6">
                <div class="mt-2 mb-3">
                  <strong>Note:</strong>
                </div>
                {{ item.changes ? item.changes.noteForStatusChange : "-" }}
              </v-col>
              <v-col cols="12" sm="6">
                <div class="mt-2 mb-3">
                  <strong>Created by:</strong>
                </div>
                {{ item.createdBy ? item.createdBy.fullname : "-" }}
              </v-col>
            </v-row>
          </v-container>
        </td></template
      >

      <template v-slot:[`item.createdAt`]="{ item }">
        {{ getDate(item.createdAt) }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <template
          v-if="
            item.name != null &&
            item.lastName != null &&
            item.paymentAmount == null
          "
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" color="primary" @click="convertPatient(item)">
                mdi-account-convert</v-icon
              >
            </template>
            <span>Convert to Patient</span>
          </v-tooltip>
        </template>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import { getAPI } from "@/api/axios-base";
import MaDatePicker from "@/components/MaDatePicker/MaDatePicker.vue";
import { notifyError } from "@/components/Notification";
import { utilMixin } from "@/utils/mixins";
import { mapActions, mapState } from "vuex";
import { AllKeys } from "@/utils/enums/allKeys";
import { PolicyStatus } from "@/utils/enums/policystatus";
import moment from "moment";
export default {
  name: "status-logs",
  mixins: [utilMixin],

  components: { MaDatePicker },
  data() {
    return {
      expanded: [],
      statusLogs: [],
      totallogs: 0,
      loading: false,
      options: {},
      headers: [
        {
          text: "Before",
          align: "start",
          sortable: false,
          value: "before",
        },
        {
          text: "After",
          align: "center",
          sortable: false,
          value: "after",
        },
        {
          text: "Create date",
          sortable: false,
          value: "createdAt",
        },
        /* {
          text: "Update date",
          sortable: false,
          value: "createdAt",
        }, */
        /* { text: "", align: "center", value: "actions", sortable: false }, */
        { text: "", value: "data-table-expand" },
      ],
      dateFrom: "",
      dateTo: "",
      patientUuid: null,
      createdByUuid: null,
      query: {
        date1: "",
        date2: "",
      },
    };
  },
  watch: {
    /*  dateFrom(val) {
      if (
        val !== null &&
        val !== this.dateTo &&
        this.dateTo !== null &&
        this.dateTo !== ""
      ) {
        this.getHistorylogs();
      }
    },
    dateTo(val) {
      if (
        val !== null &&
        val !== this.dateFrom &&
        this.dateFrom !== null &&
        this.dateFrom !== ""
      ) {
        this.getHistorylogs();
      }
    }, */
  },
  computed: {
    ...mapState("crmEmployeeModule", ["workers"]),
    ...mapState("crmMedicFormModule", ["patients"]),
    ...mapState("crmSettingsModule", [
      "loadingRefer",
      "references",
      "companies",
    ]),
  },
  methods: {
    ...mapActions("crmEmployeeModule", ["actGetEmployees"]),
    ...mapActions("crmMedicFormModule", ["actListPatientsFilter"]),
    ...mapActions("crmSettingsModule", [
      "actListReferences",
      "actListCompaniesNom",
    ]),

    getLogs() {
      this.loading = true;
      getAPI(
        "/client-logs/getStatusChangesLogsByClient/" + this.$route.params.uuid
      ).then((res) => {
        this.statusLogs = res.data;
        this.totallogs = this.statusLogs.length;
        this.loading = false;
      });
    },
    getDAte(value) {
      if (value) {
        const y = value.split("-")[0];
        const m = value.split("-")[1];
        const d = value.split("-")[2];
        return `${m}/${d}/${y}`;
      }
      return "-";
    },

    convertPatient(item) {
      const body = {
        name: item.name,
        lastName: item.lastName,
        members: item.members,
        dob: item.dob,
        polizeNumber: item.polizeNumber,
      };

      const st = JSON.stringify(body);
      const codif = btoa(st);
      localStorage.setItem("log-client", codif);
      this.$router.push("/healths/create");
    },

    dataShow(data) {
      if (data == null || data == undefined || data == "") {
        return "-";
      }
      const x = Object.entries(data);

      return x;
    },
    keyShow(value) {
      const x = AllKeys[value];
      if (x == undefined || x == null || x == "") {
        return value;
      }
      return x;
    },
    getCompany(id) {
      if (this.companies.length == 0) {
        return "No Companys";
      }
      if (id == "" || id == null || id == undefined) {
        return "No have company";
      }
      const comp = this.companies.find((c) => c.id == id);
      if (comp) {
        return comp.name;
      }
      return "No have company";
    },
    getSource(id) {
      if (this.references.length == 0) {
        return "No Sources";
      }
      if (id == "" || id == null || id == undefined) {
        return "-";
      }
      const sour = this.references.find((r) => r.id == id);
      if (sour) {
        return sour.name;
      }
      return "-";
    },

    valueShow(data) {
      const key = data[0];
      const value = data[1];
      if (value == null || value == undefined || value == "") {
        return "-";
      }
      switch (key) {
        case "birthDate":
          return this.getDAte(value);
        case "isMale":
          return value ? "Male" : "Female";
        case "isMaried":
          return value ? "Yes" : "No";
        case "isSilver":
          return value ? "Silver" : "Bronze";
        case "createAt":
          return this.getDate(value);
        case "updateAt":
          return this.getDate(value);
        case "effectiveDate":
          return this.getDate(value);
        case "companyId":
          return this.getCompany(value);
        case "howKnow":
          return this.getSource(value);
        case "expDate":
          return this.getDate(value);
        case "polizeStatus":
          const ps = PolicyStatus[value];
          if (ps) {
            return ps;
          }
          return value;
        case "legalStatus":
          if (value == "CITIZEN") {
            return "Citizen";
          }
          if (value == "PERMANENT_RESIDENT") {
            return "Permanent Resident";
          }
          if (value == "TEMPORAL_RESIDENT") {
            return "Temporal Resident";
          }
        case "preferLanguage":
          if (value == "ENGLISH") {
            return "English";
          }
          if (value == "SPANISH") {
            return "Spanish";
          }

        default:
          return value;
      }
    },

    statusShow(item) {
      const est = item.polizeStatus
        ? item.polizeStatus.replaceAll("_", " ")
        : "No Status";
      if (est == null || est == undefined) {
        return "-";
      }

      return est;
    },
    statusShowS(status) {
      const est = status.replaceAll("_", " ");

      if (est == null || est == undefined) {
        return "-";
      }

      return est;
    },

    colorShow(item) {
      const status = item.polizeStatus;
      if (status != null && status != undefined) {
        switch (status) {
          case "POLIZE_PENDING":
            return "#1663E0FF";
          case "PAYMENT_PENDING":
            return "#4B7ECE";
          case "POLIZE_OK":
            return "#19FF00FF";
          case "PAYMENT_OK":
            return "#00FFB3";
          case "POLIZE_MEMBERS_NO_MATCH":
            return "#FF0033";
          case "PAYMENT_MEMBERS_NO_MATCH":
            return "#FFAA00";
          case "POLICY_CANCELLATION":
            return "#FF4400";
          case "DELINCUENT":
            return "#DE7954";
          case "POLICY_RECONCILIATION":
            return "#B7FF00";
          case "POLICY_RENEWAL":
            return "#EEC167";
          case "OTHER_PARTY":
            return "#9C27B0";
          case "NO_AOR":
            return "#625F62";
          case "NO_AOR_ACTIVE":
            return "#8e3bed";
          case "POLICY_BLACKLIST":
            return "#270E41";

          default:
            return "#000";
        }
      }
      return "#000";
    },
    colorShowS(status) {
      if (status != null && status != undefined) {
        switch (status) {
          case "POLIZE_PENDING":
            return "#1663E0FF";
          case "PAYMENT_PENDING":
            return "#4B7ECE";
          case "POLIZE_OK":
            return "#19FF00FF";
          case "PAYMENT_OK":
            return "#00FFB3";
          case "POLIZE_MEMBERS_NO_MATCH":
            return "#FF0033";
          case "PAYMENT_MEMBERS_NO_MATCH":
            return "#FFAA00";
          case "POLICY_CANCELLATION":
            return "#FF4400";
          case "DELINCUENT":
            return "#DE7954";
          case "POLICY_RECONCILIATION":
            return "#B7FF00";
          case "POLICY_RENEWAL":
            return "#EEC167";
          case "OTHER_PARTY":
            return "#9C27B0";
          case "NO_AOR":
            return "#625F62";
          case "NO_AOR_ACTIVE":
            return "#8e3bed";
          case "POLICY_BLACKLIST":
            return "#270E41";

          default:
            return "#000";
        }
      }
      return "#000";
    },
    cleanFilters() {
      this.dateFrom = "";
      this.dateTo = "";
      this.getHistorylogs();
    },
  },
  async mounted() {
    this.getLogs();
    localStorage.removeItem("log-client");
  },
};
</script>
<style lang=""></style>
