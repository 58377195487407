/* eslint-disable */
import { axiosBase, getAPI } from "@/api/axios-base";
import {
  notificationError,
  notifyError,
  notifyInfo,
  notifySuccess,
} from "@/components/Notification";
import { isDevelop } from "@/enviorment";
import { ResponseStatus } from "@/models/ResponseStatusEnum";
import { Settings } from "@/models/Settings";
import router from "@/router";
import {
  MUT_USEDOCUSIGN,
  MUT_CONFIG,
  MUT_CONFIG_ID,
  MUT_DATA_COLORS,
  MUT_DATA_MENU,
  MUT_UPDATE_LOCAL_STORAGE,
  MUT_UPDATE_PROFILE,
  MUT_UPDATE_SIDEBAR_SELECTED,
} from "../mutations/types";
import store from "@/store";
import { Link } from "@/models/Link";
import i18n from "@/i18n";
import { Themes } from "@/models/Colors";
// USER ACTIONS
// run the below action to get a new access token on expiration
interface SHOWHIDE {
  item: Link;
  value: boolean;
}

export async function refreshToken({ state, commit }: any) {
  const refreshUrl = "/users/refresh";
  try {
    await getAPI
      .post(refreshUrl, { refresh_token: state.refreshToken })
      .then((res) => {
        if (res.status === 201) {
          commit("setAccessToken", res.data.access_token);
        }
      });
  } catch (error) {
    //
  }
}

const addsettingUrl = "/setting/create";
const updatesettingUrl = "/setting/update/";
const deletesettingUrl = "/setting/delete/";
const settingUrl = "/setting/list";
const settingPublic = "/setting/listPublic";
export async function actAddSettings(
  { commit, dispatch }: any,
  value: Settings
) {
  try {
    await getAPI.post(addsettingUrl, value);
    dispatch("actSettings");
  } catch (error) {
    notificationError();
  }
}

export async function actDeleteSettings(
  { commit, dispatch }: any,
  uuid: string
) {
  try {
    await getAPI.delete(deletesettingUrl + uuid);
    dispatch("actSettings");
    dispatch("actGeneralConfig");
  } catch (error) {
    notificationError();
  }
}
export async function actUpdateSettings(
  { commit, dispatch }: any,
  setting: Settings
) {
  try {
    await getAPI.put(updatesettingUrl + setting.uuid, {
      name: setting.name,
      value: setting.value,
      description: setting.description,
      active: setting.active,
      isPublic: setting.isPublic,
    });
    notifyInfo("Settings has been update");
    dispatch("actSettings");
    dispatch("actGeneralConfig");
  } catch (error: any) {
    let mess = error.response.data.message.toString();

    notifyError(error.response.data, `An error occurred: ${mess}`);
  }
}
export async function actSettings({ commit, dispatch }: any) {
  const ResponseData = (await getAPI(settingUrl)).data;
  const ResponseOutConfig = ResponseData.filter(
    (res: Settings) => res.name != "general_config" && res.name != "form_config"
  );
  commit("mutSettings", ResponseOutConfig);
  dispatch("actSettingsOrganice", ResponseData);
}

export function actSettingsOrganice({ commit }: any, settings: Settings[]) {
  settings.forEach((sts) => {
    switch (sts.name) {
      case "title":
        break;
      case "bgcolor":
        commit("mutDataCRMBGColor", sts.value);
        break;
      case "VUE_APP_BASE_URL":
        if (sts.active) {
          commit("mutBaseURL", sts.value);
        }
        break;
      default:
        break;
    }
  });
}

export function actSaveConfig() {
  // console.log("asd");
}

// export function registerUser(_context: any, data: any): Promise<any> {
//   return new Promise((resolve, reject) => {
//     axiosBase
//       .post("/register", {
//         name: data.name,
//         email: data.email,
//         username: data.username,
//         password: data.password,
//         confirm: data.confirm,
//       })
//       .then((response) => {
//         resolve(response);
//       })
//       .catch((error) => {
//         reject(error);
//       });
//   });
// }

export function logoutUser(context: any): void {
  if (context.getters.loggedIn) {
    getAPI("/users/logout")
      .then(() => {
        context.commit("updateactivesms", false);
        context.commit("updateactivenotify", false);
        context.commit("destroyToken");
        clearInterval((window as any).sheduleMessageRequest);
        router.push({ name: "auth" });
      })
      .catch(() => {
        //
      });
  }
}

export function forceLogout({ commit }: any) {
  commit("updateactivesms", false);
  commit("updateactivenotify", false);
  commit("destroyToken");
  router.push({ name: "auth" });
}
export function forcedestroy({ commit }: any) {
  commit("updateactivesms", false);
  commit("updateactivenotify", false);
  commit("destroyToken");
}

export async function loginUser(
  { commit, dispatch }: any,
  credentials: { email: string; password: string }
): Promise<any> {
  const response = await axiosBase.post("/users/employee/login", {
    email: credentials.email,
    password: credentials.password,
  });
  const responseData = response.data;

  if (response.status === ResponseStatus.OK) {
    if (responseData.needTwoFAuth) {
      commit("mutTokenTwoFA", responseData.access_token);
      commit("mutNeedTwoFA", responseData.needTwoFAuth);
    } else {
      dispatch("continueLogin", responseData);
    }
  } else {
    // UNEXPECTED ERROR
  }
}
export function continueLogin({ commit }: any, data: any) {
  commit(MUT_UPDATE_LOCAL_STORAGE, {
    access: data.access_token,
    refresh: data.refresh_token,
  });

  commit(MUT_UPDATE_PROFILE, data.user);
}

export async function getProfileData({ commit }: any) {
  const response = await getAPI("/users/getProfile");
  commit(MUT_UPDATE_PROFILE, response.data);
}

export async function impersonateUser(
  { commit }: any,
  data: any
): Promise<any> {
  commit("updateactivenotify", false);
  const response = await getAPI.post("/users/employee/impersonate", data);
  const responseData = response.data;
  if (response.status === ResponseStatus.OK) {
    localStorage.setItem("impersonate", "yes");

    commit(MUT_UPDATE_LOCAL_STORAGE, {
      access: responseData.access_token,
      refresh: responseData.access_token,
    });
    commit(MUT_UPDATE_PROFILE, responseData.user);
    commit("updateactivenotify", true);
  } else {
    // UNEXPECTED ERROR
    //
  }
}
// END USER ACTIONS

// SIDEBAR SELECT MENU
export function setSidebarSelected(context: any, item: string): void {
  context.commit(MUT_UPDATE_SIDEBAR_SELECTED, item);
}
// END SIDEBAR SELECT MENU

export async function actGeneralConfig({ commit }: any) {
  const ColorsDefault: Themes = {
    light: {
      primary: "#1B74D3",
      hprimary: "#3B475F",
      secondary: "#607D8B",
      accent: "#8C239E",
      error: "#C02929",
      info: "#0288D1",
      success: "#689F38",
      warning: "#FBC02D",
    },
  };

  const SideBar: Link[] = [
    /*  {
      hide: false,
      title: "Dashboard",
      icon: "mdi-view-dashboard",
      href: "/dashboard",
      showIf:
        store.getters.isAdmin ||
        store.getters.isSupervisor ||
        store.getters.isSuper ||
        store.getters.isWorker||
        store.getters.isAgent,
      showFor: "ADMINS, WORKERS,AGENT",
    }, */
    /*     {
      hide: false,
      title: i18n.t("appointments"),
      icon: "mdi-calendar-account",
      href: "/appointments/",
      showIf:
        store.getters.isDoctor ||
        store.getters.isAdmin ||
        store.getters.isSupervisor ||
        store.getters.isSuper ||
        store.getters.isWorker||
        store.getters.isAgent,
      showFor: "ADMINS, WORKERS, DOCTORS, AGENT",
    }, */
    {
      hide: false,
      title: i18n.t("reminder"),
      icon: "mdi-calendar",
      href: "/reminder/",
      showIf:
        store.getters.isDoctor ||
        store.getters.isAdmin ||
        store.getters.isSupervisor ||
        store.getters.isSuper ||
        store.getters.isWorker ||
        store.getters.isAgent,
      showFor: "ADMINS, WORKERS, DOCTORS,AGENT",
    },
    {
      hide: false,
      title: i18n.t("labelEmployees"),
      icon: "mdi-account-multiple",
      href: "/employees",
      showIf: store.getters.isAdmin || store.getters.isSuper,
      showFor: "ADMINS",
    },
    {
      hide: false,
      title: i18n.t("companies"),
      icon: "mdi-briefcase-variant-outline",
      href: "/companies",
      showIf: store.getters.isSuper,
      showFor: "ONLY SUPER",
    },
    {
      hide: false,
      title: i18n.t("labelCustomers"),
      icon: "mdi-account-multiple-outline",
      href: "/customers",
      showIf:
        store.getters.isSuper ||
        store.getters.isAdmin ||
        store.getters.isSupervisor ||
        store.getters.isWorker ||
        store.getters.isAgent,
      showFor: "ADMINS, WORKERS,AGENT",
    },
    {
      hide: false,
      title: store.getters.getpatientN,
      icon: "mdi-medical-bag",
      href: "/healths",
      showIf:
        store.getters.isSuper ||
        store.getters.isAdmin ||
        store.getters.isSupervisor ||
        store.getters.isWorker ||
        store.getters.isAgent,
      showFor: "ADMINS, WORKERS,AGENT",
    },
    {
      hide: false,
      title: i18n.t("labelSMS"),
      icon: "mdi-chat-processing",
      href: "/chat",
      showIf: true,
      showFor: "ALL",
      method: "twilio",
    },
    {
      hide: false,
      title: "Admin SMS",
      icon: "mdi-chat-alert",
      href: "/admin-sms",
      showIf:
        store.getters.isAdmin ||
        store.getters.isSuper ||
        store.getters.isSupervisor,
      showFor: "ADMINS",
      method: "twilio",
    },
    {
      hide: false,
      title: i18n.t("labelCallCenter"),
      icon: "mdi-phone",
      href: "/call-center",
      showIf:
        store.getters.isAdmin ||
        store.getters.isSupervisor ||
        store.getters.isSuper ||
        store.getters.isWorker ||
        store.getters.isAgent,
      showFor: "ADMINS, WORKERS,AGENT",
      method: "twilio",
    },
    /*  {
      hide: true,
      title: i18n.t("labelSMS"),
      icon: "mdi-chat-processing",
      href: "/sms",
      showIf: true,
      showFor: "ALL",
      method: "3cx",
    }, */
    /*  {
      hide: true,
      title: i18n.t("labelCallCenter"),
      icon: "mdi-phone",
      href: "/calls",
      showIf:
        store.getters.isAdmin ||
        store.getters.isSupervisor ||
        store.getters.isSuper ||
        store.getters.isWorker,
      showFor: "ADMINS, WORKERS",
      method: "3cx",
    }, */
    {
      hide: false,
      title: i18n.t("leadscenter"),
      icon: "mdi-headset",
      href: "/leads_center",
      showIf:
        store.getters.isSuper ||
        store.getters.isAdmin ||
        store.getters.isSupervisor ||
        store.getters.isWorker ||
        store.getters.isAgent,
      showFor: "ADMINS, WORKERS,AGENT",
    },
    {
      hide: false,
      title: i18n.t("payments"),
      icon: "mdi-cash-usd",
      href: "/payments",
      showIf:
        store.getters.isAdmin ||
        store.getters.isSuper ||
        store.getters.isSupervisor,
      showFor: "ADMINS",
    }, 
    {
      hide: false,
      title: i18n.t("Statistics"),
      icon: "mdi-chart-line",
      href: "/statistics",
      showIf:
        store.getters.isSuper ||
        store.getters.isAdmin ||
        store.getters.isSupervisor,

      showFor: "ADMINS",
    },
    {
      hide: false,
      title: i18n.t("Display"),
      icon: "mdi-monitor",
      href: "/display",
      showIf:
        store.getters.isAdmin ||
        store.getters.isSupervisor ||
        store.getters.isSuper ||
        store.getters.isWorker ||
        store.getters.isAgent,
      showFor: "ADMINS, WORKERS , AGENT",
    },
 
    /*    {
      hide: false,
      title: i18n.t("quotes"),
      icon: "mdi-clipboard-text",
      href: "/quotes-general",
      showIf: store.getters.isAdmin || store.getters.isSuper,
      showFor: "ADMINS",
    },
    {
      hide: false,
      title: i18n.t("sellaccessory"),
      icon: "mdi-cash-usd",
      href: "/sell-accessories",
      showIf:
        store.getters.isSuper ||
        store.getters.isAdmin ||
        store.getters.isSupervisor ||
        store.getters.isWorker,
      showFor: "ADMINS, WORKERS",
    }, */

    /*  {
      hide: false,
      title: i18n.t("clockincheck"),
      icon: "mdi-clock",
      href: "/clock-in-check",
      showIf: store.getters.isAdmin || store.getters.isSuper,
      showFor: "ADMINS",
    }, */
    {
      hide: false,
      title: i18n.t("nomenclators"),
      icon: "mdi-cogs",
      href: "/nomenclators",
      showIf: store.getters.isAdmin || store.getters.isSuper,
      showFor: "ADMINS",
    },
    {
      hide: false,
      title: i18n.t("config"),
      icon: "mdi-wrench",
      href: "/config",
      showIf: store.getters.isAdmin || store.getters.isSuper,
      showFor: "ADMINS",
    },
    /* {
      hide: false,
      title: i18n.t("Reports"),
      icon: "mdi-finance",
      href: "/reports",
      showIf: store.getters.isAdmin || store.getters.isSuper,
      showFor: "ADMINS",
    }, */
    {
      hide: false,
      title: "Logs",
      icon: "mdi-clipboard-alert",
      href: "/logs",
      showIf: store.getters.isSuper,
      showFor: "ADMINS",
    },
  ];

  const thisTabs = [
    /*  {
      title: "Check list",
      icon: "mdi-check-all",
      href: "#checklist",
      enabled:true,
    }, */

    {
      title: "Details",
      icon: "mdi-card-account-details",
      href: "#details",
      enabled: true,
    },
    {
      title: "Policies",
      icon: "mdi-account-multiple",
      href: "#policies",
      enabled: true,
    },
    {
      title: "Documents",
      icon: "mdi-clipboard-text",
      href: "#documents",
      enabled: true,
    },

    /*   {
      title: "Medical History",
      icon: "mdi-clipboard-text",
      href: "#mhistory",
      enabled:true,
    },
    {
      title: "Labs",
      icon: "mdi-biohazard",
      href: "#labs",
      enabled:true,
    },
    {
      title: "Quotes",
      icon: "mdi-clipboard-text",
      href: "#quotes",
      enabled:true,
    },
    {
      title: "Accessories",
      icon: "mdi-cash-usd",
      href: "#accessories",
      enabled:true,
    }, */
    {
      title: "Payments",
      icon: "mdi-cash-usd",
      href: "#payments",
      enabled: true,
    },
    {
      title: "Notes",
      icon: "mdi-note-text",
      href: "#notes",
      enabled: true,
    },
    {
      title: "Relationship",
      icon: "mdi-account-network",
      href: "#relations",
      enabled: true,
    },
    {
      title: "Record",
      icon: "mdi-voicemail",
      href: "#recorded",
      enabled: true,
    },
    {
      title: "Logs",
      icon: "mdi-clipboard-alert",
      href: "#logs",
      enabled: true,
    },
    {
      title: "Status Changes",
      icon: "mdi-alert",
      href: "#statuschange",
      enabled: true,
    },
    /*  {
      title: "Document Signing",
      icon: "mdi-draw",
      href: "#documents",
      enabled:true,
    }, */
  ];

  const formValues = {
    dob: {
      name: "dob",
      label: "Date of Birth",
      active: true,
    },
    weight: {
      name: "weight",
      label: "Weight (lbs)",
      active: true,
    },
    height: {
      name: "height",
      label: "Height",
      label1: "Feet",
      label2: "Inch",
      active: true,
    },
    doctorChoise: {
      name: "doctorChoise",
      label: "Doctor of Choice",
      active: true,
    },
    procedureInterest: {
      name: "procedureInterest",
      label: "Procedure of Interest",
      active: true,
    },
    medicalCondition: {
      name: "medicalCondition",
      label: "Do you have any medical conditions?",
      active: true,
    },
    medications: {
      name: "medications",
      label: "Do you take any medications?",
      active: true,
    },
    alergies: {
      name: "alergies",
      label: "Alergies",
      active: true,
    },
    bloodClots: {
      name: "bloodClots",
      label: "Have you ever had any blood clots/ blood clotting?",
      label2: "If yes, please describe the situation and when it happened",
      active: true,
    },
    previousSurgery: {
      name: "previousSurgery",
      label: "Any procedures or surgeries in the last 3 months?",
      label2: "Please specify.",
      active: true,
    },

    buttshots: {
      name: "buttshots",
      label: "Do you have butt shots/ injections?",
      active: true,
    },
    children: {
      name: "children",
      label: "Do you have Children?",
      active: true,
    },
    smoke: {
      name: "smoke",
      label: "Do you smoke or use any nicotine products?",
      active: true,
    },
    alcohol: {
      name: "alcohol",
      label: "Do you drink alcohol?",
      active: true,
    },
    other: {
      name: "other",
      label: "Other Substances",
      label2: "Substances",
      active: true,
    },
    desireSurgeryDate: {
      name: "desireSurgeryDate",
      label: "Desired Surgery Date",
      active: true,
    },
    source: {
      name: "source",
      label: "Please tell us how you heard about us",
      active: true,
    },
    details: {
      name: "details",
      label: "Notes",
      active: true,
    },
    photo: {
      name: "photo",
      label: "Photos",
      label2: "Attach files",
      active: true,
    },
    besttime: {
      name: "besttime",
      label: "Best time to call",
      label2: "(office hours 9am- 6pm, M-F)",
      active: true,
    },
    emailme: {
      name: "emailme",
      label: "Email me a copy of my responses.",
      label2:
        "We’ll only use your email address to send you your responses. Once you submit your responses, you’ll be sent to our sign-up page.",
      active: true,
    },
  };

  const thisColumnsLeads=[
    {
      text: "Creation Date",
      align: "start",
      sortable: false,
      value: "createAt",
      show: true,
    },
    {
      text: "Name",
      align: "start",
      width: 200,
      sortable: false,
      value: "name",
      show: true,
    },
    // { text: "Email", value: "email", sortable: false, show: true },
    { text: "Phone", value: "phone", sortable: false, show: true },
    { text: "Source",width: 200, value: "sourceNom", sortable: false, show: true },
    {
      text: "Language",
      value: "preferLanguage",
      sortable: false,
      show: true,
    },
 
    { text: "Agent",width: 200, value: "coordinator", sortable: false, show: true },
    {
      text: "R-Date",
      align: "start",
      width: 110,
      value: "rd",
      sortable: false,
      show: true,
    },
    {
      text: "Members",
      value: "members",
      align: "center",
      sortable: false,
      show: false,
    },
    {
      text: "Status",
      value: "status",
      width: 200,
      align: "center",
      sortable: false,
      show: true,
    },
    {
      text: "Quote Date",
      value: "quotedDate",
      align: "center",
      sortable: false,
      show: false,
    },
    {
      text: "Sms",
      value: "smsCount",
      sortable: false,
      align: "center",
      show: true,
    },
    {
      text: "Calls",
      value: "callCount",
      sortable: false,
      align: "center",
      show: true,
    },
    {
      text: "Options",
      value: "actions",
      align: "center",
      sortable: false,
      show: true,
    },
    { text: "Tags", value: "leadTagsNom",  sortable: false, show: false },

  ];
  const thisColumnsClients=[
    { text: "Fullname", show: true, width: 300, value: "fullname", sortable: true },
        {
          text: "Policy Number",
          width: 200,
          show: false,
          value: "polizeNumber",
          sortable: true,
        },
        { text: "Phone",
          width: 150, show: true, value: "homePhone", sortable: true },
        {
          text: "Agent",
          show: true,
          value: "relations",
          width: 200,
          sortable: false,
        },
        { text: "Company", show: true, width: 200, value: "company", sortable: false },
        {
          text: "Company State",
          show: true,
          width: 200,
          value: "companyState",
          sortable: false,
        },
        { text: "Source", show: true,width: 200, value: "sourceNom", sortable: false },
       
        {
          text: "Quote Date",
          show: false,
          value: "quotedDate",
          sortable: false,
          align: "center",
        },
        {
          text: "Members",
          show: true,
          value: "totalMembers",
          align: "center",
          sortable: false,
        },
        {
          text: "Npn",
          show: false,
          width: 200,
          value: "npnNom",
          align: "center",
          sortable: false,
        },
        { text: "Status", show: true,width: 200, value: "polizeStatus", sortable: false },
        
        { text: "Quote", show: false, value: "actions", sortable: false },
        { text: "OP Status", show: false, value: "renewalStatusNom",  width: 200, sortable: false },
        {
          text: "Payment Status",
          show: false,
          value: "paymentStatus",
          width: 200,
          sortable: false,
        },
        { text: "Pay To", show: false, value: "payToNom",  width: 200, sortable: false },
        { text: "Effective Date", show: false, value: "effectiveDate",  sortable: false },
        { text: "With Check", show: false, value: "call", sortable: false },
        { text: "Tags", show: true, value: "patientTagsNom", sortable: false },
       
  ];



  try {
    commit("loadingConfig", true);
    const resData = (await getAPI(settingPublic)).data;

    const docunSign = resData.filter(
      (data: Settings) => data.name == "UseDocusign"
    )[0];
    const countForms = resData.filter(
      (data: Settings) => data.name == "form_config-count"
    )[0];
    const defaultForm = resData.filter(
      (data: Settings) => data.name == "form_config-default"
    )[0];
    const responseData = resData.filter(
      (data: Settings) => data.name == "general_config"
    )[0];
    let responseDataForm = resData.filter(
      (data: Settings) =>
        data.name.includes("form_config-") &&
        data.name != "form_config-default" &&
        data.name != "form_config-count"
    );

    const com = resData.filter(
      (data: Settings) => data.name == "COMPANY_NAME"
    )[0];
    if (com != undefined && com != null) {
      commit("mutDataCompany", com.value);
    }

    if (countForms != undefined && countForms != null) {
      commit("mutcountForm", countForms);
    }
    if (defaultForm != undefined && defaultForm != null) {
      commit("mutdefaulFormID", defaultForm);
    }

    if (docunSign != undefined && docunSign != null) {
      commit(MUT_USEDOCUSIGN, docunSign.active);
    } else {
      commit(MUT_USEDOCUSIGN, false);
    }

    commit(MUT_CONFIG_ID, responseData.uuid);
    if (responseDataForm != undefined) {
      responseDataForm = responseDataForm.map((rdf: any) => {
        const name = rdf.name.split("-");
        let indexConfig = 0;
        if (name.length > 1) {
          indexConfig = name[1];
        } else {
          indexConfig = 1;
        }
        const desc = rdf.description.split(":");
        let url = "none";
        if (desc.length > 1) {
          url = desc[1];
        } else {
          url = "none";
        }

        return { ...rdf, indexConfig: Number(indexConfig), url };
      });
      commit("mutconfigForm", responseDataForm);
    } else {
      commit("mutconfigForm", []);
    }
    if (responseData != undefined && responseData != null) {
      let dataCRM = JSON.parse(responseData.value);

      if (dataCRM.columnsClients==undefined||dataCRM.columnsClients.length==0) {
        
        dataCRM = { ...dataCRM, columnsClients: thisColumnsClients };
      }
      if (dataCRM.columnsLeads==undefined||dataCRM.columnsLeads.length==0) {
        dataCRM = { ...dataCRM, columnsLeads: thisColumnsLeads };
      }


      if (
        dataCRM.tabPatientDetails == undefined ||
        dataCRM.tabPatientDetails.length == 0
      ) {
        dataCRM = { ...dataCRM, tabPatientDetails: thisTabs };
      }
      if (dataCRM.methodCall == undefined) {
        dataCRM = { ...dataCRM, methodCall: "twilio" };
      }
      if (dataCRM.requireNotes == undefined) {
        dataCRM = { ...dataCRM, requireNotes: false };
      }
      if (dataCRM.useMerchant == undefined) {
        dataCRM = { ...dataCRM, useMerchant: false };
      }
      if (dataCRM.sellerN == undefined) {
        dataCRM = { ...dataCRM, sellerN: "Seller" };
      }
      if (dataCRM.patientN == undefined) {
        dataCRM = { ...dataCRM, patientN: "Patient" };
      }
      if (dataCRM.viewPayments == undefined) {
        dataCRM = { ...dataCRM, viewPayments: false };
      }
      if (dataCRM.viewPPS == undefined) {
        dataCRM = { ...dataCRM, viewPPS: false };
      }
      if (dataCRM.viewLabs == undefined) {
        dataCRM = { ...dataCRM, viewLabs: false };
      }
      if (dataCRM.viewMHistory == undefined) {
        dataCRM = { ...dataCRM, viewMHistory: false };
      }
      if (dataCRM.approveQ == undefined) {
        dataCRM = { ...dataCRM, approveQ: false };
      }
      if (dataCRM.sendQ == undefined) {
        dataCRM = { ...dataCRM, sendQ: false };
      }
      if (dataCRM.viewpatientStatus == undefined) {
        dataCRM = { ...dataCRM, viewpatientStatus: false };
      }
      if (dataCRM.leadHeaders == undefined) {
        dataCRM = { ...dataCRM, leadHeaders: "both" };
      }
      if (dataCRM.selecDocuments == undefined) {
        dataCRM = { ...dataCRM, selecDocuments: false };
      }
      if (dataCRM.coordinatorN == undefined) {
        dataCRM = { ...dataCRM, coordinatorN: "Surgical coordinator" };
      }
      if (dataCRM.soundNotification == undefined) {
        dataCRM = { ...dataCRM, soundNotification: false };
      }
      if (dataCRM.appointmetsTypes == undefined) {
        const apty = [
          {
            label: "Treatment",
            value: "TREATMENT",
          },
          {
            label: "Surgery",
            value: "SURGERY",
          },
          {
            label: "Evaluation",
            value: "EVALUATION",
          },
          {
            label: "Follow Up",
            value: "FOLLOWUP",
          },
        ];
        dataCRM = { ...dataCRM, appointmetsTypes: apty };
      }

      dataCRM.menu.map((m: Link) => {
        if (m.hide == undefined) {
          m.hide = false;
        }
      });

      delete dataCRM.notTwilio;

      dataCRM.menu.forEach((element: Link) => {
        SideBar.forEach((el: Link) => {
          if (element.href == el.href) {
            el.hide = element.hide;
            if (el.method != undefined) {
              if (el.method == dataCRM.methodCall) {
                el.hide = false;
              } else {
                el.hide = true;
              }
            }
          }
          if (el.href == "/healths") {
            el.title = dataCRM.patientN;
          }
        });
      });

      commit(MUT_CONFIG, dataCRM);
      commit(MUT_DATA_MENU, SideBar);
    } else {
      commit(MUT_DATA_MENU, SideBar);
      commit(MUT_DATA_COLORS, ColorsDefault);
    }
    commit("loadingConfig", false);
  } catch (error) {
    commit("loadingConfig", false);
    commit(MUT_DATA_MENU, SideBar);
    commit(MUT_DATA_COLORS, ColorsDefault);
  }
}

//REFRESH COLORS
export function actRefreshColors({ commit }: any) {
  const ColorsDefault: Themes = {
    light: {
      primary: "#00bcd5",
      hprimary: "#141414",
      secondary: "#424242",
      accent: "#82B1FF",
      error: "#FF5252",
      info: "#2196F3",
      success: "#4CAF50",
      warning: "#ffc107",
    },
  };

  commit(MUT_DATA_COLORS, ColorsDefault);
}

//Config Hide SIDEMENU
//otro
export async function changeStatus(context: any, status: string): Promise<any> {
  try {
    await getAPI("/users/changeMyStatus/" + status);
  } catch (error) {
    notificationError();
  }
}

///Clock in and out
export async function actClockin({ commit }: any, req: any) {
  try {
    const responseData = (await getAPI.put("/clocking/changeStatus", req)).data;
    notifySuccess(`Status was changed`);
    return responseData;
  } catch (error) {
    notificationError();
  }
}

//User Contacts
//List
export async function actGetContacts({ commit }: any) {
  try {
    commit("updateloadingContact", true);
    const response = (await getAPI("/contacts")).data;
    commit("updateUserContacts", response);
    commit("updateloadingContact", false);
  } catch (error) {
    // notificationError();
  }
}
export async function actGetNotifications({ commit, dispatch }: any, req: any) {
  try {
    let body = req ? req : { limit: 200, offset: 0 };
    let response = (await getAPI.post("/notify/filterList", body)).data;

    commit("mutNotifications", response.result);
    commit("mutTotalNotify", response.count);
  } catch (error) {
    // notificationError();
  }
}
export async function actGetAllNotifications(
  { commit, dispatch }: any,
  req: any
) {
  try {
    let body = req ? req : { limit: 200, offset: 0 };
    let response = (await getAPI.post("/notify/filterList", body)).data;

    commit("mutAllNotifications", response.result);
    commit("mutTotalAllNotify", response.count);
  } catch (error) {
    // notificationError();
  }
}
//Create
export async function actCreateContacts({ commit }: any, req: any) {
  try {
    commit("updateloadingContact", true);
    await getAPI.post("/contacts", req);
    notifySuccess("Contact has been added");
    commit("updateloadingContact", false);
  } catch (error: any) {
    commit("updateloadingContact", false);
    let mess = error.response.data.message;

    if (mess.includes("[")) {
      mess = mess.replace("[", "");
      mess = mess.replace("]", "");
    }
    notifyError(error.response.data, `An error occurred: ${mess}`);
  }
}
//Update
export async function actUpdateContacts({ commit }: any, req: any) {
  try {
    const uuid = req.uuid;
    const body = req;

    delete body.uuid;
    commit("updateloadingContact", true);
    await getAPI.put("/contacts/" + uuid, body);
    notifyInfo("Contact has been updated");
    commit("updateloadingContact", false);
  } catch (error: any) {
    commit("updateloadingContact", false);
    let mess = error.response.data.message;

    if (mess.includes("[")) {
      mess = mess.replace("[", "");
      mess = mess.replace("]", "");
    }
    notifyError(error.response.data, `An error occurred: ${mess}`);
  }
}
export async function actDeleteContacts({ commit }: any, req: any) {
  try {
    commit("updateloadingContact", true);
    await getAPI.delete("/contacts/" + req);
    notifyInfo("Contact has been deleted");
    commit("updateloadingContact", false);
  } catch (error: any) {
    commit("updateloadingContact", false);
    let mess = error.response.data.message;

    if (mess.includes("[")) {
      mess = mess.replace("[", "");
      mess = mess.replace("]", "");
    }
    notifyError(error.response.data, `An error occurred: ${mess}`);
  }
}
