export enum   PolicyStatus {
  POLIZE_PENDING = "Policy Pending",
  POLIZE_OK = "Policy Ok",
  POLIZE_MEMBERS_NO_MATCH = "Policy Member does not match",
  PAYMENT_MEMBERS_NO_MATCH = "Member payment does not match",
  PAYMENT_OK = "Payment Ok",
  PAYMENT_PENDING = "Payment Pending",
  POLICY_CANCELLATION = "Cancelled",
  POLICY_RENEWAL = "Renewal",
  POLICY_RECONCILIATION = "Reconciliation",
  NO_AOR = "No Aor",
  NO_AOR_ACTIVE = "No Aor Active",
  OTHER_PARTY = "Other Party",
  DELINCUENT = "Delinquent",
  POLICY_BLACKLIST = "Blacklist",
  PENDING_RENEWAL = "Pending Renewal",
  NO_RENEWAL = "No Renewal",
  PROSPECT_RENEWAL = "Prospect Renewal",
  POLICY_NEW = "Policy New",
  ACTIVE= "Active",
  ACTIVE_PENDING= "Active Pending",
  CANCELLED= "Cancelled",
  PAY_DUE= "Pay Due",
  REVIEW= "Review",
  BLACKLIST = "Blacklist",
  
}

/* Policy not registered
Policy Payment Not Registered
Cancellation
Renewal
Reconciliation */
