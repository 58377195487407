import { RouteConfig } from "vue-router";
import { Permission } from "@/utils/permissions";

const CustomerRoutes: Array<RouteConfig> = [
 
  {
    path: "/healths/policy_create/:uuid",
    name: "Members Create",
    component: () => import("@/views/Patients/components/CreateMembers.vue"),
    meta: {
      requiresLogged: true,
    },
  },
  {
    path: "/healths/policy_edit/:uuid",
    name: "Members Edit",
    component: () => import("@/views/Patients/components/EditMember.vue"),
    meta: {
      requiresLogged: true,
    },
  },
  {
    path: "/healths/import",
    name: "Import Client",
    component: () => import("@/views/Patients/components/ImportClients.vue"),
    meta: {
      requiresLogged: true,
    },
  },
  {
    path: "create",
    name: "Create customer",
    component: () => import("../CreateCustomer.vue"),
    meta: {
      requiresLogged: true,
    },
  },
  {
    path: "update/:uuid",
    name: "Update customer",
    component: () => import("../UpdateCustomer.vue"),
    meta: {
      requiresLogged: true,
    },
  },
  {
    path: "details/:uuid",
    name: "Customer details",
    component: () => import("../DetailsCustomer .vue"),
    meta: {
      requiresLogged: true,
    },
  },
  {
    path: "",
    name: "Customers list",
    component: () => import("../Customers.vue"),
    meta: {
      requiresLogged: true,
    },
  },
];

export default CustomerRoutes;
