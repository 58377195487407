<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div>
    <div class="d-flex flex-row align-center justify-space-between mb-4">
      <div class="top-header mb-0 text-uppercase">
        <v-icon class="cursor--pointer" @click="backleads">mdi-reply</v-icon>

        {{ "Leads" }}
      </div>
    </div>
    <v-card color="white" elevation="1">
      <template v-if="lead">
        <v-card-title class="primary white--text" primary-title>
          Lead Details
          <v-spacer></v-spacer>

          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" small icon color="white" @click="goEdit()"
                ><v-icon>mdi-pencil</v-icon></v-btn
              >
            </template>

            <span>Edit Lead</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" small icon color="white" @click="gotoQuote()"
                ><v-icon>mdi-currency-usd</v-icon></v-btn
              >
            </template>

            <span>Quote</span>
          </v-tooltip>
          <template v-if="lead.phone">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn color="white" v-on="on" icon @click="makeCallV(lead.phone)">
                  <v-icon>mdi-phone-forward</v-icon>
                </v-btn>
              </template>
              <span>Make Call</span>
            </v-tooltip>
          </template>
          <create-reminder :typeReminder="'lead'" :external="true" :uuid="uuidLead" />

          <template v-if="isAdmin || isSuper">
            <asign-to :color="'white'" @assigned="getLeadData" :leadUuid="lead.uuid" />
          </template>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                depressed
                v-on="on"
                icon
                class="mx-1"
                color="white"
                @click="convertToPatient()"
                dark
                ><v-icon>mdi-account-convert</v-icon></v-btn
              >
            </template>
            <span>Convert to {{ getpatientN }}</span>
          </v-tooltip>

          <send-email :color="'white'" :user="lead" />
          <template v-if="lead != null && lead != undefined">
            <!-- <drawer-sms
              :leadUuid="lead.uuid"
              :color="'error'"
              :details="lead"
              :origin="'lead'"
              @updateFiles="updateFiles"
            /> -->
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  color="white"
                  :disabled="showSmsGlobal"
                  icon
                  @click="openSMS"
                  ><v-icon>mdi-android-messages</v-icon></v-btn
                >
              </template>
              <span>Send Message</span>
            </v-tooltip>
          </template>
          <NotificationsLC :data="arraynotify" @updateNotify="getLeadData" />

          <div>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn color="white" v-on="on" icon @click="getLeadData"
                  ><v-icon>mdi-reload</v-icon></v-btn
                >
              </template>
              <span>Details Reload</span>
            </v-tooltip>
          </div>
        </v-card-title>
        <v-tabs v-model="tab" centered icons-and-text grow show-arrows>
          <v-tabs-slider></v-tabs-slider>

          <v-tab href="#details">
            Details
            <v-icon>mdi-clipboard-account</v-icon>
          </v-tab>

          <v-tab href="#relations">
            Relationship
            <v-icon>mdi-account-network</v-icon>
          </v-tab>

          <v-tab href="#notes">
            Notes
            <v-icon>mdi-note-text </v-icon>
          </v-tab>
          <v-tab href="#recorded">
            Recorded Calls
            <v-icon>mdi-voicemail </v-icon>
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-container>
            <v-tab-item value="details">
              <v-card flat>
                <v-card-text>
                  <v-container>
                    <v-row no-gutters>
                      <v-col cols="12" sm="3">
                        <div class="text-caption font-weight-blod text--secondary">
                          ID
                        </div>
                        <div class="text-body-1">
                          {{ lead.id ? lead.id : "-" }}
                        </div>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <div class="text-caption font-weight-blod text--secondary">
                          Status
                        </div>
                        <div class="text-body-1">
                          <v-menu offset-x>
                            <template v-slot:activator="{ on }">
                              <span
                                v-on="on"
                                class="font-weight-bold cursor-pointer"
                                v-bind:style="{ color: colorShow() }"
                                >{{ statusShow() }}</span
                              >
                            </template>
                            <v-list>
                              <v-list-item
                                class="cursor-pointer"
                                v-for="(st, index) in status"
                                :key="index"
                                link
                                :disabled="statusShowReal() == st.name"
                                @click="changeStatus(st)"
                              >
                                <v-list-item-title
                                  :class="statusShowReal() == st.name ? 'red--text' : ''"
                                  >{{ st.name }}</v-list-item-title
                                >
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </div>
                      </v-col>

                      <v-col cols="12" sm="3">
                        <div class="text-caption font-weight-blod text--secondary">
                          Gender
                        </div>
                        <div class="text-body-1">
                          {{ lead.isMale ? "Male" : "Female" }}
                        </div>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <div class="text-caption font-weight-blod text--secondary">
                          Date of Birth
                        </div>
                        <div class="text-body-1">
                          {{ getDateD(lead.birthDate) }}
                        </div>
                      </v-col>
                    </v-row>
                    <v-divider class="my-2"></v-divider>
                    <v-row no-gutters>
                      <v-col cols="12" sm="3">
                        <div class="text-caption font-weight-blod text--secondary">
                          Full name
                        </div>
                        <div class="text-body-1">
                          {{ lead.name ? lead.name : "-" }}
                          {{ lead.last_name ? lead.last_name : "" }}
                        </div>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <div class="text-caption font-weight-blod text--secondary">
                          Email
                        </div>
                        <div class="text-body-1">
                          {{ lead.email ? lead.email : "-" }}
                        </div>
                      </v-col>
                      <v-col cols="6" sm="3">
                        <div class="text-caption font-weight-blod text--secondary">
                          Phone
                        </div>
                        <div class="text-body-1">
                          <template v-if="lead.phone">
                            <div class="d-flex">
                              <div class="cursor-pointer" @click="makeCallV(lead.phone)">
                                {{ lead.phone }}
                              </div>
                              <div class="mx-1">
                                <v-tooltip top>
                                  <template v-slot:activator="{ on }">
                                    <v-icon
                                      @click="copyPhone(lead.phone)"
                                      v-on="on"
                                      small
                                      color="info"
                                      >mdi-content-copy</v-icon
                                    >
                                  </template>
                                  <span>Copy Phone</span>
                                </v-tooltip>
                              </div>
                            </div>
                          </template>
                          <template v-else> - </template>
                        </div>
                      </v-col>
                      <v-col cols="6" sm="3">
                        <div class="text-caption font-weight-blod text--secondary">
                          Preferred Language
                        </div>
                        <div class="text-body-1">
                          {{ lead.preferLanguage ? lead.preferLanguage : "-" }}
                        </div>
                      </v-col>
                    </v-row>
                    <v-divider class="my-2"></v-divider>
                    <v-row no-gutters>
                      <v-col cols="12" sm="3">
                        <div class="text-caption font-weight-blod text--secondary">
                          Source
                        </div>
                        <div class="text-body-1">
                          <span
                            v-bind:style="{ color: colorShowS() }"
                            class="font-weight-bold"
                            >{{ lead.sourceNom ? lead.sourceNom.name : "-" }}</span
                          >
                        </div>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <div class="text-caption font-weight-blod text--secondary">
                          Tag
                        </div>
                        <div class="text-body-1">
                          <template v-if="lead">
                            <template
                              v-if="lead.leadTagsNom && lead.leadTagsNom.length != 0"
                            >
                              <div v-for="itm in lead.leadTagsNom" :key="itm.id">
                                <v-tooltip top :color="colorShowTag(itm)">
                                  <template v-slot:activator="{ on }">
                                    <v-chip
                                      class="ma-1 chipindetails"
                                      v-on="on"
                                      :color="colorShowTag(itm)"
                                      dark
                                    >
                                      <template v-if="itm.asAdmin"
                                        ><v-icon class="mr-1 my-1" color="white" small
                                          >mdi-star</v-icon
                                        ></template
                                      >
                                      <div class="mt-1">
                                        {{ itm.tagNom ? itm.tagNom.name : "-" }}
                                      </div>
                                    </v-chip>
                                  </template>
                                  <span>{{ itm.tagNom ? itm.tagNom.name : "-" }}</span>
                                </v-tooltip>
                              </div>
                            </template>
                            <template v-else>-</template>
                          </template>
                          <template v-else> - </template>

                          <!--   <span v-bind:style="{ color:  }" class="font-weight-bold"></span> -->
                        </div>
                      </v-col>

                      <v-col cols="12" sm="3">
                        <div class="text-caption font-weight-blod text--secondary">
                          Create at
                        </div>
                        <div class="text-body-1">
                          {{ getPrettyDate(lead.createAt) }}
                        </div>
                      </v-col>
                    </v-row>
                  </v-container>

                  <v-divider class="mx-2 my-1"></v-divider>
                  <template v-if="lead.quotedDate">
                    <v-row no-gutters class="px-3">
                      <v-col cols="12" sm="3">
                        <div class="text-caption font-weight-blod text--secondary">
                          Quote Date
                        </div>
                        <div class="text-body-1">
                          {{ lead.quotedDate ? getDate(lead.quotedDate) : "-" }}
                        </div>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <div class="text-caption font-weight-blod text--secondary">
                          Company
                        </div>
                        <div class="text-body-1">
                          {{ lead.company ? lead.company.name : "-" }}
                        </div>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <div class="text-caption font-weight-blod text--secondary">
                          State
                        </div>
                        <div class="text-body-1">
                          {{ lead.state ? lead.state : "-" }}
                        </div>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <div class="text-caption font-weight-blod text--secondary">
                          Members
                        </div>
                        <div class="text-body-1">
                          {{ lead.members ? lead.members : "0" }}
                        </div>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <div class="text-caption font-weight-blod text--secondary mt-3">
                          Metal Plan
                        </div>
                        <div
                          class="text-body-1 font-weight-bold"
                          v-bind:style="{ color: colorPolicy() }"
                        >
                          {{ lead.policyPlan ? lead.policyPlan : "-" }}
                        </div>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <div class="text-caption font-weight-blod text--secondary mt-3">
                          Premium
                        </div>
                        <div class="text-body-1">
                          {{ !lead.premium ? 0 : lead.premium | currency }}
                        </div>
                      </v-col>
                    </v-row>
                    <v-divider class="mx-2 my-1"></v-divider>
                  </template>

                  <v-row>
                    <v-col>
                      <all-images
                        ref="images"
                        @uploaded="getLeadData"
                        :isConsent="false"
                        :imagesfrom="'lead'"
                        :uuid="lead.uuid"
                        :imagesProps="lead.pictures"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item value="relations"
              ><lead-relation :lead="lead" @getData="getLeadData" />
            </v-tab-item>

            <v-tab-item value="notes">
              <v-card flat>
                <v-card-text>
                  <v-card>
                    <v-toolbar dark color="secondary">
                      <v-toolbar-title> Notes </v-toolbar-title>

                      <v-btn
                        depressed
                        class="ml-auto black--text"
                        color="white"
                        dark
                        @click="OpenDialog('note')"
                        >Add Note</v-btn
                      >

                      <v-btn color="white" class="mx-1" text @click="getNotes()">
                        <v-icon small>mdi-reload</v-icon> Reload
                      </v-btn>
                    </v-toolbar>
                    <v-card-title primary-title>
                      <div class="row">
                        <v-dialog v-model="dialogNote" max-width="600">
                          <v-card>
                            <v-card-title primary-title>
                              <h4>Add Note</h4>
                              <v-spacer></v-spacer>
                              <v-switch
                                inset
                                label="Evaluation"
                                v-model="evaluation"
                              ></v-switch>
                            </v-card-title>
                            <v-card-text>
                              <v-textarea
                                outlined
                                name="input-7-4"
                                label="DETAILS"
                                v-model="detailsnote"
                              ></v-textarea
                            ></v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="green darken-1" text @click="cancelDialog">
                                Cancel
                              </v-btn>
                              <v-btn color="green darken-1" text @click="addNote">
                                Add
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </div>
                    </v-card-title>
                    <v-card-text>
                      <table-leads :data="notes" :headers="'notes'"></table-leads>
                    </v-card-text>
                  </v-card>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item value="recorded">
              <RecordedCallsCL
                :from="'Lead'"
                :uuid="lead.uuid"
                :phone="lead.phone"
                :calls="lead.calls"
              />
            </v-tab-item>
          </v-container>
        </v-tabs-items>
      </template>
      <template v-else-if="lead == null && loading">
        <v-card-text class="d-flex flex-column align-center">
          <strong>Loading...</strong>
          <v-progress-circular
            :size="70"
            :width="7"
            color="primary"
            indeterminate
          ></v-progress-circular
        ></v-card-text>
      </template>
      <v-dialog
        v-model="quote.dialog"
        persistent
        :overlay="false"
        max-width="800px"
        transition="dialog-transition"
      >
        <v-card>
          <v-toolbar class="white--text" color="primary">
            <v-toolbar-title> Quote </v-toolbar-title>
          </v-toolbar>
          <v-card-text class="my-3">
            <v-row no-gutters class="mt-4">
              <v-col cols="12" sm="4">
                <label class="font-weight-medium">Quote Day1 </label>
                <v-text-field
                  disabled
                  outlined
                  dense
                  v-model="quoteDateshow"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="4" class="px-1">
                <label class="font-weight-medium" for="state">State </label>
                <v-autocomplete
                  :items="states"
                  v-model="quote.item.state"
                  item-text="name"
                  item-value="name"
                  :loading="loadingStates"
                  autocomplete="off"
                  outlined
                  dense
                />
              </v-col>
              <v-col cols="12" sm="4">
                <label class="font-weight-medium">Company </label>
                <v-select
                  :items="companies"
                  item-text="name"
                  :loading="quote.loading"
                  item-value="id"
                  v-model="quote.item.companyId"
                  outlined
                  dense
                ></v-select>
              </v-col>
              <v-col cols="12" sm="4">
                <label class="font-weight-medium">Members </label>
                <v-text-field outlined dense v-model="quote.item.members"></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" class="px-1">
                <label class="font-weight-medium">Metal Plan </label>
                <v-select
                  :items="metalplan"
                  item-text="label"
                  item-value="value"
                  v-model="quote.item.policyPlan"
                  outlined
                  dense
                ></v-select>
              </v-col>
              <v-col cols="12" sm="4">
                <label class="font-weight-medium">Premium </label>
                <v-text-field outlined dense v-model="quote.item.premium"></v-text-field>
              </v-col>
            </v-row>
            <v-card-actions class="d-flex justify-end">
              <v-btn color="error" :disabled="quote.loanding" text @click="cancelQuote"
                >Cancel</v-btn
              >
              <v-btn
                color="info"
                :disabled="quote.loanding"
                :loading="quote.loanding"
                text
                @click="confirmQuote"
                >Update</v-btn
              >
            </v-card-actions>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-card>
  </div>
</template>
<script>
import { desireSurgery } from "@/utils/consts";
import { mapActions, mapGetters, mapState, mapMutations } from "vuex";
import { getAPI } from "@/api/axios-base";
import TableLeads from "@/views/Configurations/components/TableLeads.vue";

import moment from "moment";
import SendEmail from "@/components/emails/SendEmail.vue";
import AllImages from "@/components/Images/AllImages.vue";

import AsignTo from "@/views/Configurations/components/AsignTo.vue";
import DrawerSms from "@/views/SMS/components/DrawerSms.vue";
import { LeadStatus } from "@/models/Lead";
import LeadRelation from "@/views/Configurations/components/LeadRelation.vue";

import { notifyError, notifyInfo } from "@/components/Notification";
import CreateReminder from "@/views/Reminder/Calendar/CreateReminder.vue";
import cleanObject from "@/utils/cleanObject";
import { funtionsPhone, utilMixin } from "@/utils/mixins";
const NotificationsLC = () => import("@/components/NotificationsLC.vue");
const RecordedCallsCL = () => import("@/views/CallCenter/components/RecordedCallsCL.vue");
export default {
  name: "lead-details",
  components: {
    TableLeads,

    AllImages,

    LeadRelation,
    AsignTo,
    DrawerSms,
    SendEmail,
    CreateReminder,
    NotificationsLC,
    RecordedCallsCL,
  },
  mixins: [utilMixin, funtionsPhone],
  data() {
    return {
      tab: null,
      lead: null,
      uuidLead: null,
      desireSurgery,
      loading: false,
      dialogNote: false,
      evaluation: false,
      detailsnote: "",
      status: [],
      notes: [],
      arraynotify: [],
      metalplan: [
        { label: "Bronze", value: "BRONZE" },
        { label: "Silver", value: "SILVER" },
        { label: "Gold", value: "GOLD" },
      ],
      quote: {
        dialog: false,
        loanding: false,
        item: {
          members: 0,
          policyPlan: "",
          companyId: 0,
          quotedDate: moment(),
          state: "",
          premium: 0,
        },
      },
    };
  },
  watch: {
    lead(val) {
      if (val != null) {
        this.expandedLead();
      }
    },
  },
  computed: {
    ...mapGetters(["getCoordinatorN", "getpatientN", "isAdmin", "isSuper", "getsendQ"]),
    ...mapState("crmSettingsModule", ["companies"]),
    ...mapState("crmMedicFormModule", ["states", "loadingStates"]),
    ...mapState("crmCallCenterModule", ["dialogmakeCall", "callsLead"]),
    ...mapState(["showSmsGlobal"]),

    quoteDateshow() {
      return this.quote.item.quotedDate.format("MM/DD/YYYY");
    },

    desireSurgerySelect() {
      if (this.lead == null || this.lead.medicalHistory == null) {
        return null;
      }
      if (this.lead.medicalHistory.desiredSurgeryDate) {
        const t = this.lead.medicalHistory.desiredSurgeryDate;
        const desire = this.desireSurgery.filter((d) => d.value == t);
        return desire[0];
      }
      return null;
    },

    bmi() {
      let inches = 0;
      let feets = 0;
      if (this.lead == null) {
        return 0;
      }
      if (this.lead.weight == 0 && this.lead.height == 0) return 0;
      const he = this.lead.height.toString();
      if (he.includes(".")) {
        inches = Number(he.split(".")[1]);
        feets = he.split(".")[0] ? Number(he.split(".")[0]) : 0;
      } else if (he.includes(",")) {
        inches = Number(he.split(",")[1]);
        feets = he.split(".")[0] ? Number(he.split(",")[0]) : 0;
      } else {
        feets = he;
      }

      const inchtometer = inches * 0.0254;
      const feettometer = feets * 0.3048;
      const bmi =
        (Number(this.lead.weight) * 0.453592) / Math.pow(feettometer + inchtometer, 2);

      return Number(bmi).toFixed(2);
    },

    typebmi() {
      const bmitemp = Number(this.bmi);

      if (bmitemp == 0) {
        return "No BMI";
      }
      if (bmitemp <= 18.5) {
        return "Underweight";
      }
      if (bmitemp > 18.5 && bmitemp <= 24.9) {
        return "Normal weight";
      }
      if (bmitemp >= 25 && bmitemp <= 29.9) {
        return "Overweight";
      }

      return "Obesity";
    },
    colorWeight() {
      if (this.typebmi == "No BMI") {
        return "grey";
      }
      if (this.typebmi == "Underweight") {
        return "amber";
      }
      if (this.typebmi == "Normal weight") {
        return "green";
      }
      if (this.typebmi == "Overweight") {
        return "orange";
      }
      if (this.typebmi == "Obesity") {
        return "red";
      }
    },
  },
  methods: {
    ...mapMutations("crmCallCenterModule", ["mutDialogmakeCall"]),
    ...mapActions("crmConfigurationsModule", [
      "actGetLeads",
      "actConverToPatient",
      "actAddNote",
      "actAddLead",
    ]),

    ...mapActions("crmSettingsModule", ["actListCompaniesNom"]),
    ...mapActions("crmMedicFormModule", ["actListStates"]),
    ...mapMutations("crmConfigurationsModule", ["mutleadSelect"]),
    ...mapMutations(["mutShowSms"]),
    getDate(value) {
      if (value) {
        const val = value.split("T")[0];
        const y = val.split("-")[0];
        const m = val.split("-")[1];
        const d = val.split("-")[2];

        return `${m}/${d}/${y}`;
      }
      return "-";
    },
    getDateD(value) {
      if (value) {
        const val = value.split("T")[0];
        const y = val.split("-")[0];
        const m = val.split("-")[1];
        const d = val.split("-")[2];
        const tem = `${m}/${d}/${y}`;
        return moment(tem).format("MMMM DD, YYYY");
      }
      return "-";
    },
    getPrettyDate(value) {
      if (value) {
        const x = moment(value).format("MM/DD/yyyy");
        return x;
      }
      return "-";
    },
    colorPolicy() {
      const status = this.lead.policyPlan;
      if (status != null && status != undefined) {
        switch (status) {
          case "GOLD":
            return "#EDC43D";
          case "SILVER":
            return "#C0C0C0";
          case "BRONZE":
            return "#CD7F32";

          default:
            return "#000";
        }
      }
      return "#000";
    },
    openCall() {
      localStorage.setItem("number", this.lead.phone);
      this.mutDialogmakeCall(true);
    },
    openSMS() {
      localStorage.setItem("numberSMS", this.lead.phone);
      localStorage.setItem("nameSMS", this.lead.name + " " + this.lead.last_name);
      this.mutShowSms(true);
    },

    getLeadData() {
      this.loading = true;
      this.uuidLead = this.$route.params.uuid;
      getAPI("/social/getLead/" + this.uuidLead)
        .then((res) => {
          this.lead = res.data;
          this.arraynotify = this.lead.remindersAbout ? this.lead.remindersAbout : [];
          this.loading = false;
        })
        .catch((error) => {
          let mess = error.response.data.message.toString();
          this.loading = false;

          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },

    async getNotes() {
      this.loading = true;
      const uuidLead = this.$route.params.uuid;
      getAPI("social/getNotesByLead/" + uuidLead)
        .then((res) => {
          this.notes = res.data;

          this.loading = false;
        })
        .catch((error) => {
          let mess = error.response.data.message.toString();
          this.loading = false;

          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },

    async gotoQuote() {
      this.quote.dialog = true;
      this.actListStates();
      this.quote.loanding = true;
      await this.actListCompaniesNom();
      this.quote.loanding = false;

      if (this.lead.quotedDate) {
        this.quote.item = {
          companyId: this.lead.company ? this.lead.company.id : 0,
          members: this.lead.members ? this.lead.members : 0,
          policyPlan: this.lead.policyPlan ? this.lead.policyPlan : "",
          quotedDate: this.lead.quotedDate ? moment(this.lead.quotedDate) : moment(),
          state: this.lead.state ? this.lead.state : "",
          premium: this.lead.premium ? this.lead.premium : 0,
        };
      }
    },
    cancelQuote() {
      this.quote = {
        dialog: false,
        loanding: false,
        item: {
          members: 0,
          policyPlan: "",
          companyId: 0,
          quotedDate: moment(),
          state: "",
          premium: 0,
        },
      };
    },
    confirmQuote() {
      let body = Object.assign({}, this.quote.item);
      if (body.members) {
        body.members = Number(body.members);
      }
      if (body.premium) {
        body.premium = Number(body.premium);
      }
      if (body.quotedDate) {
        body.quotedDate = new Date(body.quotedDate).toISOString();
      }
      body = cleanObject(body);
      body = { ...body, leadUuid: this.lead.uuid };
      this.quote.loanding = true;
      getAPI
        .put("/social/updateCounts", body)
        .then(() => {
          notifyInfo("Quote has been updated");
          this.quote.loanding = false;
          this.getLeadData();
          this.cancelQuote();
        })
        .catch((error) => {
          this.quote.loanding = false;
          let mess = error.response.data.message.toString();
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },

    changeStatus(status) {
      this.loading = true;
      let body = {
        leadUuid: this.lead.uuid,
        statusNom: status.id,
      };
      getAPI
        .put("/social/updateCounts", body)
        .then(() => {
          notifyInfo("Lead Status has been changed");
          this.loading = false;
          this.getLeadData();
        })
        .catch((error) => {
          this.loading = false;
          let mess = error.response.data.message.toString();
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },

    statusShow() {
      const est = this.lead.statusNom ? this.lead.statusNom.name : "No Status";
      if (est == null || est == undefined) {
        return "-";
      }
      switch (est) {
        case LeadStatus.NotInterested:
          return "Not Interested";
        case LeadStatus.WrongNumber:
          return "Wrong Number";
        default:
          return est;
      }
    },
    statusShowReal() {
      const est = this.lead.statusNom ? this.lead.statusNom.name : "No Status";
      if (est == null || est == undefined) {
        return "-";
      }

      return est;
    },
    colorShowTag(itm) {
      return itm.tagNom ? itm.tagNom.color : "#000";
    },
    colorShow() {
      return this.lead.statusNom ? this.lead.statusNom.color : "#000";
    },
    colorShowS() {
      return this.lead.sourceNom ? this.lead.sourceNom.color : "#000";
    },
    colorShowT() {
      return this.lead.tagNom ? this.lead.tagNom.color : "#000";
    },

    loadLeadStatus() {
      getAPI(`/lead-status-nom/list`)
        .then((res) => {
          this.status = res.data.filter((f) => f.status != "NO_ACTIVE");
        })
        .catch((error) => {
          let mess = error.response.data.message.toString();
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
    convertToPatient() {
      this.mutleadSelect(this.lead);
      this.$router.push("/healths/create");
    },
    formatDate() {
      const d = moment(this.lead.birthDate).format("MM/DD/YYYY");
      return d;
    },
    getHeight(height) {
      if (height == undefined || height == null || height == "") {
        return 0;
      }
      const ht = height.toString();
      if (ht.includes(".")) {
        return ht.split(".")[0] + " ft, " + ht.split(".")[1] + " in";
      } else if (ht.includes(",")) {
        return ht.split(",")[0] + " ft, " + ht.split(",")[1] + " in";
      } else {
        return ht + " ft";
      }
    },

    getDoctorName() {
      if (this.lead.medicalHistory == null || this.lead.medicalHistory == undefined) {
        return "None";
      }
      if (this.lead.medicalHistory.doctorChoice != null) {
        return this.lead.medicalHistory.doctorChoice.fullname;
      }
      return "None";
    },

    cancelDialog() {
      this.dialogNote = false;

      this.evaluation = false;

      this.detailsnote = "";
    },

    async addNote() {
      await this.actAddNote({
        details: this.detailsnote,
        leadUuid: this.lead.uuid,
        type: this.evaluation ? "EVALUATION" : "ANY",
      });
      this.cancelDialog();
      this.getLeadData();
      this.getNotes();
    },

    OpenDialog(type) {
      switch (type) {
        case "note":
          this.dialogNote = true;
          break;
        default:
          this.dialogLead = true;
          break;
      }
    },
    backleads() {
      this.$router.push("/leads_center");
    },
    goEdit() {
      localStorage.setItem("from", "lead");
      this.$router.push("/leads_center/edit/" + this.lead.uuid);
    },

    updateFiles() {
      setTimeout(() => {
        this.$refs.images.getImages();
      }, 500);
    },

    expandedLead() {
      const phone = this.lead.phone;
    },
  },
  async mounted() {
    await this.loadLeadStatus();
    await this.getLeadData();
    await this.getNotes();
    localStorage.setItem("leadcenter", true);
  },
};
</script>
<style lang="scss">
.texts {
  margin-top: -26px !important;
  margin-bottom: 10px !important;
}

.texts1 {
  margin-top: -40px !important;
  margin-bottom: 10px !important;
}

.texts2 {
  margin-top: -40px !important;
  margin-bottom: 8px !important;
}

.actions {
  margin-top: -50px !important;
}

.texts3 {
  margin-top: -40px !important;
  margin-bottom: 5px !important;
}

.borderdivider {
  border: 1px solid;
}

.test {
  border: 1px solid red;
}
</style>
