var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"overflow-hidden mx-auto",attrs:{"width":"1000"}},[_c('v-toolbar',{staticClass:"primary",attrs:{"tag":"div","flat":"","dark":"","tile":""}},[_c('v-toolbar-title',[_vm._v(" Configurations ")]),_c('v-spacer')],1),_c('v-tabs',{attrs:{"grow":"","show-arrows":""},model:{value:(_vm.tabSelect),callback:function ($$v) {_vm.tabSelect=$$v},expression:"tabSelect"}},[_c('v-tab',{attrs:{"href":"#tab-2"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-tablet-ipad")]),_vm._v(" Devices ")],1),(_vm.isSuper)?_c('v-tab',{attrs:{"href":"#tab-4"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-code-braces")]),_vm._v(" ENVIROMENTS ")],1):_vm._e(),(_vm.isSuper)?_c('v-tab',{attrs:{"href":"#tab-5"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-cogs")]),_vm._v(" GENERAL ")],1):_vm._e(),(_vm.isSuper)?_c('v-tab',{attrs:{"href":"#tab-7"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-wrench")]),_vm._v(" FORM ")],1):_vm._e()],1),_c('v-tabs-items',{model:{value:(_vm.tabSelect),callback:function ($$v) {_vm.tabSelect=$$v},expression:"tabSelect"}},[_c('v-tab-item',{attrs:{"value":"tab-2"}},[[_c('div',{staticClass:"d-flex align-end"},[_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2 my-1",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Add+ ")])]}}]),model:{value:(_vm.CreateDialog),callback:function ($$v) {_vm.CreateDialog=$$v},expression:"CreateDialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("Create Device")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"Device Name","required":""},model:{value:(_vm.deviceName),callback:function ($$v) {_vm.deviceName=$$v},expression:"deviceName"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"items":['PROCEDURE', 'DOOR'],"label":"Device Type","required":""},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.cancel}},[_vm._v(" Close ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.createDevice}},[_vm._v(" Create ")])],1)],1)],1),_c('v-spacer'),_c('div',{staticClass:"col-sm-4"},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"loading":_vm.loadingDevice,"dense":"","search":_vm.search,"items":_vm.devices,"headers":_vm.headers,"item-key":"uuid"},scopedSlots:_vm._u([{key:"item.deviceName",fn:function(props){return [_vm._v(" "+_vm._s(props.item.deviceName)+" ")]}},{key:"item.link",fn:function(props){return [_c('v-badge',{attrs:{"color":props.item.link == null ? 'green' : 'red',"dot":""}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","origin":"center center","rounded":"lg","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"link":""}},[_c('v-dialog',{attrs:{"max-width":"280","content-class":"rounded-xl"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"red"}},'v-btn',attrs,false),Object.assign({}, dialog)),[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-delete")]),_vm._v("Remove ")],1)]}}],null,true),model:{value:(_vm.dialogConfirmRemove),callback:function ($$v) {_vm.dialogConfirmRemove=$$v},expression:"dialogConfirmRemove"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h6 font-weight-regular rounded-xl"},[_vm._v(" "+_vm._s(_vm.$t("dialogTitle"))+" ")]),_c('v-card-text',{attrs:{"column":""}},[_c('div',{staticClass:"text-body-1 text-truncate d-flex mb-1"},[_vm._v(" "+_vm._s(item.deviceName)+" ")])]),_c('v-divider'),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"color":"success","disabled":_vm.loading,"width":"100","text":"","rounded":""},on:{"click":function($event){return _vm.cancel()}}},[_vm._v(" "+_vm._s(_vm.$t("labelCancel"))+" ")]),_c('v-btn',{attrs:{"color":"red","loading":_vm.loading,"width":"100","depressed":"","rounded":"","dark":""},on:{"click":function($event){return _vm.remove(item.uuid)}}},[_vm._v(" "+_vm._s(_vm.$t("labelRemove"))+" ")])],1)],1)],1)],1),(item.link != null)?_c('v-list-item',{attrs:{"link":""}},[_c('v-dialog',{attrs:{"max-width":"280","content-class":"rounded-xl"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"warning"}},'v-btn',attrs,false),Object.assign({}, dialog)),[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-lock-open")]),_vm._v("unlock ")],1)]}}],null,true),model:{value:(_vm.dialogConfirmUnlock),callback:function ($$v) {_vm.dialogConfirmUnlock=$$v},expression:"dialogConfirmUnlock"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h6 font-weight-regular rounded-xl"},[_vm._v(" Unlock ")]),_c('v-card-text',{attrs:{"column":""}},[_c('div',{staticClass:"text-body-1 text-truncate d-flex mb-1"},[_vm._v(" "+_vm._s(item.deviceName)+" ")])]),_c('v-divider'),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"color":"success","disabled":_vm.loading,"width":"100","text":"","rounded":""},on:{"click":function($event){return _vm.cancel()}}},[_vm._v(" "+_vm._s(_vm.$t("labelCancel"))+" ")]),_c('v-btn',{attrs:{"color":"warning","loading":_vm.loading,"width":"100","depressed":"","rounded":"","dark":""},on:{"click":function($event){return _vm.unlock(item.uuid)}}},[_vm._v(" "+_vm._s(_vm.$t("labelUnlock"))+" ")])],1)],1)],1)],1):_vm._e(),_c('v-list-item',{attrs:{"link":""}},[_c('v-dialog',{attrs:{"temporary":"","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"success","width":"120"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-qrcode")]),_vm._v(" Qr ")],1)]}}],null,true),model:{value:(_vm.DialogEdit),callback:function ($$v) {_vm.DialogEdit=$$v},expression:"DialogEdit"}},[(item.type == 'DOOR')?[_c('qr-code',{attrs:{"size":400,"error-level":"L","text":JSON.stringify({
                            apiBaseUrl: _vm.apiurl,
                            access_token: item.access_token,
                          })}})]:[_c('qr-code',{attrs:{"size":400,"error-level":"L","text":JSON.stringify({
                            uuid: item.uuid,
                            name: item.deviceName,
                          })}})]],2)],1)],1)],1)]}}],null,true)})]],2),(_vm.isSuper)?_c('v-tab-item',{attrs:{"value":"tab-4"}},[_c('env-settings')],1):_vm._e(),(_vm.isSuper)?_c('v-tab-item',{attrs:{"value":"tab-5"}},[_c('crm-config')],1):_vm._e(),(_vm.isSuper)?_c('v-tab-item',{attrs:{"value":"tab-7"}},[_c('config-form-view')],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }