<template>
  <div>
    <div class="d-flex pt-4">
      <div class="col-sm-3">
        <MonthPicker ref="mtp" :inFilter="true" @monthPick="dateFrom = $event" />
      </div>
      <div class="col-sm-3">
        <MonthPicker ref="mtp1" :inFilter="true" @monthPick="dateTo = $event" />
      </div>
      <div class="col-sm-1">
        <template v-if="dateFrom || dateTo">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn text v-on="on" color="error" @click="clearFilters">
                <v-icon>mdi-filter-remove</v-icon></v-btn
              >
            </template>
            <span>Clear Filters</span>
          </v-tooltip>
        </template>
      </div>
      <v-spacer></v-spacer>
      <div class="col d-flex justify-end">
        <v-btn text small :disabled="loading" color="primary" @click="getImportLogs">
          <v-icon>mdi-refresh</v-icon> Update</v-btn
        >
      </div>
    </div>
    <v-data-table
      dense
      :loading="loading"
      :headers="headers"
      :items="logs"
      :options.sync="options"
      :server-items-length="total"
      :footer-props="footerProps"
      @click:row="showDetails"
    >
      <template v-slot:[`item.createdAt`]="{ item }">
        <div
          class="cursor-pointer"
          :class="item.uuid == uuidLastimport ? 'green--text font-weight-bold' : ''"
        >
          {{ formatDate(item.createdAt) }}
        </div>
      </template>
      <template v-slot:[`item.selectedDate`]="{ item }">
        <div
          class="cursor-pointer"
          :class="item.uuid == uuidLastimport ? 'green--text font-weight-bold' : ''"
        >
          {{ item.monthByAgent + ", " + item.yearByAgent }}
          <template v-if="item.uuid == uuidLastimport">
            <span class="red--text">{{ "( Last import)" }}</span>
          </template>
        </div>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import { getAPI } from "@/api/axios-base";

import moment from "moment";
import MonthPicker from "@/components/MaDatePicker/MonthPicker.vue";
export default {
  components: { MonthPicker },
  data() {
    return {
      dateFrom: null,
      dateTo: null,
      loading: false,
      uuidLastimport: null,
      currentLog: null,
      dialogDetails: false,
      headers: [
        { text: "Created At", width: 100, value: "createdAt", sortable: false },
        {
          text: "Selected Import Date",
          width: 100,
          value: "selectedDate",
          sortable: false,
        },
        {
          text: "Payments Found",
          align: "end",
          width: 100,
          value: "totalFound",
          sortable: false,
        },
        {
          text: "Payments No Found",
          align: "end",
          width: 100,
          value: "totalNoFound",
          sortable: false,
        },
      ],
      logs: [],
      options: {},
      total: 0,
      footerProps: {
        "items-per-page-options": [25, 50, 100, 250, 500, 1000],
      },
    };
  },
  watch: {
    options(val) {
      if (val != {}) {
        this.getImportLogs();
      }
    },
    dateFrom(val) {
      if (val != null && this.dateTo != null && this.dateTo != "") {
        this.getImportLogs();
      }
    },
    dateTo(val) {
      if (val != null && this.dateFrom != null && this.dateFrom != "") {
        this.getImportLogs();
      }
    },
  },
  methods: {
    getImportLogs() {
      this.loading = true;

      const { page, itemsPerPage } = this.options;
      const itmper = itemsPerPage != -1 ? itemsPerPage : 50;
      let body = {
        limit: itmper,
        offset: (page - 1) * itmper,
      };
      if (this.dateFrom && this.dateTo) {
        body = {
          ...body,
          dateInterval: {
            date1: moment(this.dateFrom).toISOString(),
            date2: moment(this.dateTo).toISOString(),
          },
        };
      }
      getAPI
        .post("payment-tasks/filterList", body)
        .then((res) => {
          this.total = res.data.count;
          this.logs = res.data.result;
          this.loading = false;
          if (localStorage.getItem("logpayment")) {
            this.uuidLastimport = localStorage.getItem("logpayment");
          }
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    showDetails(details) {
      this.$emit("details", { show: true, details });
    },
    closeDetails() {
      this.currentLog = null;
      this.dialogDetails = false;
    },

    clearFilters() {
      this.dateFrom = null;
      this.dateTo = null;
      this.$refs.mtp.cancelMenu();
      this.$refs.mtp1.cancelMenu();
      this.getImportLogs();
    },

    formatDate(date) {
      const d = moment(date).format("MM/DD/YYYY");
      return d;
    },
  },
  destroyed() {
    localStorage.removeItem("logpayment");
  },
};
</script>
<style lang=""></style>
