<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div>
    <div class="d-flex justify-end pa-2">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" color="primary" icon @click="getClientsLogs"
            ><v-icon>mdi-reload</v-icon></v-btn
          >
        </template>
        <span>Reload</span>
      </v-tooltip>
    </div>
    <v-data-table
      :loading="loading"
      :options.sync="options"
      :headers="headers"
      :items="clientslogs"
      :server-items-length="totallogs"
      :items-per-page="10"
      class="elevation-1"
    >
      <template v-slot:[`item.dob`]="{ item }">
        {{ getDAte(item.dob) }}
      </template>
      <template v-slot:[`item.createdAt`]="{ item }">
        {{ getDate(item.createdAt) }}
      </template>
      <template v-slot:[`item.task`]="{ item }">
        {{ getFile(item.task) }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <template
          v-if="
            item.name != null &&
            item.lastName != null &&
            item.paymentAmount == null
          "
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" color="primary" @click="convertPatient(item)">
                mdi-account-convert</v-icon
              >
            </template>
            <span>Convert to Patient</span>
          </v-tooltip>
        </template>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import { getAPI } from "@/api/axios-base";
import { notifyError } from "@/components/Notification";
import { utilMixin } from "@/utils/mixins";
export default {
  name: "delincuents-logs",
  mixins: [utilMixin],
  data() {
    return {
      clientslogs: [],
      totallogs: 0,
      loading: false,
      options: {},
      headers: [
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "name",
        },
        {
          text: "Last Name",
          align: "start",
          sortable: false,
          value: "lastName",
        },
        {
          text: "DOB",

          sortable: false,
          value: "dob",
        },
        {
          text: "Policy Number",
          align: "start",
          sortable: false,
          value: "polizeNumber",
        },
        {
          text: "File Task",
          align: "start",
          sortable: false,
          value: "task",
        },
        {
          text: "Create date",
          sortable: false,
          value: "createdAt",
        },
        /*   { text: "", align: "center", value: "actions", sortable: false }, */
      ],
    };
  },
  watch: {
    options: {
      handler() {
        this.getClientsLogs();
      },
      deep: true,
    },
  },
  methods: {
    getDAte(value) {
      if (value) {
        const y = value.split("-")[0];
        const m = value.split("-")[1];
        const d = value.split("-")[2];
        return `${m}/${d}/${y}`;
      }
      return "-";
    },
    getFile(file) {
      if (file) {
        const temp = file && file.minioFile ? file.minioFile : "";
        const filetemp = temp.split("/");
        return filetemp.length > 2 ? filetemp[2] : "";
      }
      return;
      if (file == null || file == undefined) {
        return "";
      } else {
        return file;
      }
    },
    getClientsLogs() {
      const { page, itemsPerPage } = this.options;
      const itmper = itemsPerPage != -1 ? itemsPerPage : 10;
      const body = {
        limit: itmper,
        offset: (page - 1) * itmper,
        taskType: ["UPDATE_DELINCUENTS"],
      };
      this.loading = true;
      getAPI
        .post("/task-import/list-tasks-logs", body)
        .then((res) => {
          this.loading = false;
          this.totallogs = res.data.count;
          this.clientslogs = res.data.result;
        })
        .catch((error) => {
          this.loading = false;
          let mess = error.response.data.message.toString();
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },

    convertPatient(item) {
      const body = {
        name: item.name,
        lastName: item.lastName,
        members: item.members,
        dob: item.dob,
        polizeNumber: item.polizeNumber,
      };

      const st = JSON.stringify(body);
      const codif = btoa(st);
      localStorage.setItem("log-client", codif);
      this.$router.push("/healths/create");
    },
  },
  mounted() {
    localStorage.removeItem("log-client");
  },
};
</script>
<style lang=""></style>
