<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div>
    <v-card
      class=" fill-height overflow-hidden"
      :loading="loading"
      :disabled="loading"
    >
      <v-expand-transition>
        <div v-if="details" class="d-flex flex-column">
          <v-card-title class="headline primary white--text d-flex align-center py-2">
            <v-layout column>
              <div class="text-body-1 font-weight-bold">
                {{ details.fullname }}
              </div>
              <div class="d-flex flex-row align-center">
                <div class="text-caption font-weight-bold">ID: {{ details.id }}</div>
                <div class="text-caption font-weight-bold mx-3">
                  Phone: {{ details.homePhone }}
                </div>
                <div class="text-caption font-weight-bold">
                  Email: {{ details.email }}
                </div>
              </div>
            </v-layout>

            <v-spacer />
            <template v-if="details && details.customerUuid">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" color="white" text @click="gotoCustomer"
                    ><v-icon>mdi-account</v-icon></v-btn
                  >
                </template>
                <span>Go To Customer</span>
              </v-tooltip>
            </template>

            <v-divider class="mr -4" vertical />

            <!--  <drawer-sms :details="details" :origin="'patient'" /> -->

            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" small icon color="white" @click="gotoQuote()"
                  ><v-icon>mdi-currency-usd</v-icon></v-btn
                >
              </template>

              <span>Quote</span>
            </v-tooltip>
            <template v-if="details.homePhone">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    color="white"
                    v-on="on"
                    icon
                    @click="makeCallV(details.homePhone)"
                  >
                    <v-icon>mdi-phone-forward</v-icon>
                  </v-btn>
                </template>
                <span>Make Call</span>
              </v-tooltip>
            </template>
            <create-reminder
              :external="true"
              :typeReminder="'patient'"
              :uuid="uuidPatient"
            />
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  color="white"
                  :disabled="showSmsGlobal"
                  icon
                  @click="openSMS"
                  ><v-icon>mdi-android-messages</v-icon></v-btn
                >
              </template>
              <span>Send Message</span>
            </v-tooltip>

            <send-email :user="details" />
            <NotificationsLC :data="arraynotify" @updateNotify="getPatientData" />
            <v-divider class="mx-4" vertical />
            {{/* Update patient */}}
            <v-tooltip transition="fade-transition" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="mr-2"
                  :to="'/healths/update/' + $route.params.uuid"
                  dark
                  icon
                  v-on="on"
                  v-bind="attrs"
                >
                  <v-icon>mdi-account-edit</v-icon>
                </v-btn>
              </template>
              <span>Update {{ getpatientN }}</span>
            </v-tooltip>
            {{/* Update patient */}}
            <v-tooltip transition="fade-transition" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="mr-2"
                  :loading="loading"
                  dark
                  icon
                  v-on="on"
                  v-bind="attrs"
                  @click="getPatientDataForce"
                >
                  <v-icon>mdi-sync</v-icon>
                </v-btn>
              </template>
              Update state of {{ getpatientN }}
            </v-tooltip>
            <template v-if="isAdmin || isSuper">
              <btn-remove-patient :patient="details" />
            </template>
          </v-card-title>

          <!--  <v-toolbar dense height="80">
            <btn-add-appointment
              :patient="details"
              :btnProps="{ color: 'primary' }"
            />

             <btn-create-procedure
              :internal="true"
              :patientUuid="details.uuid"
              @created="getPatientData"
            /> 
           <btn-create-quote @created="getPatientData" /> 
              <v-divider class="mx-1" vertical /> 

            <div
              v-if="procedureData"
              class="text-body-1 ml-2 my-4 d-flex flex-column"
            >
              <div>{{ procedureData.name }}</div>
              <div class="text-caption d-flex">
                ID: <b class="mx-1">{{ procedureData.id }}</b>
                Created at:
                <b class="mx-2">{{ procedureData.date }}</b>
                <div
                  v-if="procedureData.treatmentType != null"
                  class="text-caption mx-2"
                >
                  Total: <b>{{ procedureData.totalTreatment | currency }}</b>
                </div>
                <div v-else class="text-caption mx-2">
                  Total: <b>{{ procedureData.total | currency }}</b>
                </div>
                <div class="text-caption">
                  Amount due: <b>{{ procedureData.deb | currency }}</b>
                </div>
              </div>
            </div>

            <v-spacer />
          </v-toolbar> -->
          <v-divider />
        </div>
      </v-expand-transition>

      <v-tabs v-model="tabSelected" grow show-arrows>
        <v-tab v-for="(tab, i) in tabs" :key="i" :href="tab.href">
          <template>
            <v-icon left>{{ tab.icon }}</v-icon>
            {{ tab.title }}
          </template>
        </v-tab>

        {{/* Check list */}}

        {{/* Patient Details */}}
        <v-tab-item value="details" v-if="details != null">
          <patient-personal-details :patient="details" @parent="getMemberData" />
        </v-tab-item>
        <v-tab-item value="relations" v-if="details != null">
          <relations />
        </v-tab-item>
        {{/* Patient Documents */}}
        <v-tab-item value="documents" v-if="details != null">
          <documents @documentChek="getPatientData" />
        </v-tab-item>
        {{/* Patient Policies */}}

        <v-tab-item value="policies" v-if="details != null">
          <patient-members @detailsmemeber="getMemberData" />
        </v-tab-item>
        {{/* Patient Medical History */}}

        <!--  Recorded -->
        <v-tab-item value="recorded">
          <template v-if="details">
            <RecordedCallsCL
              :from="'Client'"
              :pictures="details.consentDocs"
              :uuid="details.uuid"
              :phone="details.homePhone"
              :calls="details.calls"
            />
          </template>
        </v-tab-item>
        <!--  Logs -->
        <v-tab-item value="logs">
          <history-logs :clientUuid="$route.params.uuid" />
        </v-tab-item>
        <!--  Logs Status -->
        <v-tab-item value="statuschange">
          <StatusLogs :client="details" />
        </v-tab-item>
        <!--  Accessories -->

        {{/* Paymets */}}
        <v-tab-item value="payments">
          <payments @update-patient="getPatientData" />
        </v-tab-item>
        {{/* Notes */}}
        <v-tab-item value="notes" v-if="details != null">
          <notes :patient="details" @update-patient="getPatientData" />
        </v-tab-item>
        {{/* Document Signing */}}
      </v-tabs>
      <v-dialog
        v-model="dialogConfirmWhy"
        max-width="400"
        persistent     
      >
        <v-card>
          <v-card-title class="text-h6 white--text green lighten-2">
            {{ $t("dialogTitle") }}
          </v-card-title>
          <v-card-text>
            <v-layout class="pa-1" column>
              <v-textarea
                outlined
                name="input-7-4"
                label="Reasons"
                v-model="reasonswhy"
              ></v-textarea>
            </v-layout>
          </v-card-text>

          <v-divider />
          <v-card-actions class="justify-end">
            <v-btn color="error" :disabled="loading" @click="cancelEntry" text>
              {{ $t("labelCancel") }}
            </v-btn>
            <v-btn
              color="success"
              :loading="loadingwhy"
              :disabled="
                reasonswhy == '' ||
                reasonswhy == null ||
                reasonswhy == undefined ||
                loadingwhy
              "
              @click="_goToPatient"
              text
            >
              {{ $t("labelAccept") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <template v-if="quote.dialog">
        <QuoteClient
          :dialog="quote.dialog"
          :item="quote.item"
          @changeQuote="cancelQuote($event)"
        />
      </template>
    </v-card>
  </div>
</template>

<script>
/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from "vue";
import { getAPI } from "@/api/axios-base";
import { isDevelop } from "@/enviorment";

import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import moment from "moment";
import DrawerSms from "@/views/SMS/components/DrawerSms.vue";
import { notifyError, notifyInfo } from "@/components/Notification";
import AccessoriesSales from "@/views/Patients/AccessoriesSales.vue";
import SendEmail from "@/components/emails/SendEmail.vue";
import Documents from "@/views/Patients/Documents.vue";
import HistoryLogs from "@/views/Patients/components/HistoryLogs.vue";
import CreateReminder from "@/views/Reminder/Calendar/CreateReminder.vue";
import cleanObject from "@/utils/cleanObject";

const StatusLogs = () => import("@/views/Patients/components/StatusLogs.vue");
const NotificationsLC = () => import("@/components/NotificationsLC.vue");
import PatientMembers from "@/views/Patients/components/PatientMembers.vue";
const BtnRemovePatient = () =>
  import(/* webpackChunkName: "remove-patient" */ "./buttons/BtnRemovePatient.vue");
const Notes = () => import(/* webpackChunkName: "notes" */ "./Notes/Notes.vue");
const Relations = () =>
  import(/* webpackChunkName: "relations" */ "@/views/Patients/Relations.vue");

const Payments = () => import(/* webpackChunkName: "payments" */ "./Payments.vue");

const PatientPersonalDetails = () =>
  import(
    /* webpackChunkName: "patient-personal-details" */ "./PatientPersonalDetails.vue"
  );
const QuoteClient = () =>
  import(
    /* webpackChunkName: "patient-personal-details" */ "./components/QuoteClient.vue"
  );
const RecordedCallsCL = () => import("@/views/CallCenter/components/RecordedCallsCL.vue");

export default Vue.extend({
  name: "patient-details",
  components: {
    Notes,
    Documents,
    DrawerSms,
    HistoryLogs,
    Payments,
    PatientPersonalDetails,
    BtnRemovePatient,
    PatientMembers,
    Relations,
    QuoteClient,
    AccessoriesSales,
    SendEmail,

    CreateReminder,
    NotificationsLC,
    StatusLogs,
    RecordedCallsCL,
  },
  data() {
    return {
      details: null,
      loadingEditPro: false,
      dialogEditProcedure: false,
      req: {
        surgicalDate: "",
        preopDate: "",
      },

      // The procedure selected
      metalplan: [
        { label: "Bronze", value: "BRONZE" },
        { label: "Silver", value: "SILVER" },
        { label: "Gold", value: "GOLD" },
      ],

      quote: {
        dialog: false,
        item: {
          members: null,
          policyPlan: null,
          companyId: null,
          quotedDate: moment(),

          premium: null,
        },
      },
      dialogConfirmWhy: false,
      reasonswhy: "",
      loadingwhy: false,
      uuidPatient: null,
      loading: false,
      tabSelected: "details",
      dialogSignStep1: false,
      dialogDocument: false,
      isDevelop: isDevelop,
      procedureName: "",
      procedureType: "",
      procedureCreatedAt: "",
      procedureData: null,
      sms: {
        number: "",
        message: "",
        // replyTo: "+17869461441",
      },
      arraynotify: [],
      arreminders: [],
      dialogImgShow: false,
      image: "",
      width: 800,
    };
  },
  watch: {
    tabSelected: {
      handler() {
        localStorage.setItem("tab-patient", this.tabSelected);
      },
    },
  },
  computed: {
    ...mapGetters([
      "isSuper",
      "isAdmin",
      "getRequireNote",
      "getpatientN",
      "getviewLabs",
      "getviewMHistory",
      "gettabPatientDetails",
    ]),
    ...mapState("crmDashboardModule", ["procedureSelectid"]),
    ...mapState("crmMedicFormModule", ["patientDetail", "unreadDoc", "countUnread"]),

    ...mapState(["showSmsGlobal"]),
    ...mapState("crmSMSModule", ["messagesPatient"]),
    ...mapState("crmCallCenterModule", ["dialogmakeCall"]),

    showPreopDate() {
      if (this.procedureData == null) {
        return "";
      }
      if (this.procedureData.preopDate == null) {
        return "";
      }
      return moment(this.procedureData.preopDate).format("MM/DD/YYYY");
    },
    showSurgicalDate() {
      if (this.procedureData == null) {
        return "";
      }
      if (this.procedureData.surgicalDate == null) {
        return "";
      }
      return moment(this.procedureData.surgicalDate).format("MM/DD/YYYY");
    },

    tabs() {
      let tabs = this.gettabPatientDetails.filter((tpd) => tpd.enabled);
      /* if (this.patientDetail && this.patientDetail.memberOf) {
        tabs = tabs.filter((t) => t.title != "Members");
      } */
      if (this.isSuper) {
        return tabs;
      }

      tabs = tabs.filter((t) => t.title != "Logs");

      return tabs;
    },
  },
  async mounted() {
    this.uuidPatient = this.$route.params.uuid;
    if (localStorage.getItem("tab-patient")) {
      this.tabSelected = localStorage.getItem("tab-patient");
    }

    localStorage.setItem("filterfrompatients", true);
    if (this.isSuper || this.isAdmin || !this.getRequireNote) {
      await this.getPatientData();
    } else {
      this.dialogConfirmWhy = true;
    }
  },
  destroyed() {
    this.mutprocedureSelectid(0);
    this.mutProcedure(null);
  },
  methods: {
    ...mapMutations("crmDashboardModule", ["mutprocedureSelectid"]),
    ...mapMutations("crmMedicFormModule", [
      "mutPatientDetails",
      "mutProcedure",
      "mutPatientQuotes",
      "mutunreadDoc",
      "mutcountUnred",
    ]),
    ...mapMutations("crmSMSModule", ["mutSetContact"]),
    ...mapActions("crmSMSModule", ["actMessagesPerNumber", "actSendMessage"]),
    ...mapMutations("crmCallCenterModule", ["mutDialogmakeCall"]),
    ...mapMutations(["mutShowSms"]),

    openCall() {
      localStorage.setItem("number", this.patientDetail.homePhone);
      this.mutDialogmakeCall(true);
    },
    openSMS() {
      localStorage.setItem("numberSMS", this.patientDetail.homePhone);
      localStorage.setItem("nameSMS", this.patientDetail.fullname);
      this.mutShowSms(true);
    },

    toedit() {
      this.dialogEditProcedure = true;
    },
    prettyDate(date) {
      return moment(date).format("MM/DD/YY");
    },

    async _goToPatient() {
      try {
        this.loadingwhy = true;
        const patient = (
          await getAPI.post("/patient/getPatientWithNotes", {
            patientUuid: this.$route.params.uuid,
            note: this.reasonswhy,
          })
        ).data;
        this.loadingwhy = false;
        this.reasonswhy = "";
        this.dialogConfirmWhy = false;
        this.mutPatientDetails(patient);
        this.mutPatientQuotes(patient);
        this.loading = false;
        this.details = patient;
        // Si ya había un procedimiento seleccionado se actualiza con éste
      } catch (error) {
        this.loadingwhy = false;
        let mess = error.response.data.message;

        if (mess.includes("[")) {
          mess = mess.replace("[", "");
          mess = mess.replace("]", "");
        }
        notifyError(error.response.data, `An error occurred: ${mess}`);
      }
    },
    cancelEntry() {
      this.dialogConfirmWhy = false;
      this.reasonswhy = "";
      this.$router.push("/healths");
    },
    getPatientDataForce() {
      this.getPatientData();
    },

    async getPatientData() {
      this.loading = true;

      const patient = (await getAPI(`/patient/getPatient/${this.$route.params.uuid}`))
        .data;
      this.arreminders = patient.remindersAbout ? patient.remindersAbout : [];
      this.arraynotify = patient.remindersAbout ? patient.remindersAbout : [];

      this.mutPatientDetails(patient);

      this.loading = false;
      this.details = patient;
      // Si ya había un procedimiento seleccionado se actualiza con éste
    },
    async getMemberData() {
      this.tabSelected = "details";

      this.loading = false;
      this.details = this.patientDetail;
      // Si ya había un procedimiento seleccionado se actualiza con éste
    },
    makeCallV(item) {
      getAPI("/zoom/zoomPhonecall", {
        params: {
          phone: item,
        },
      }).then((res) => {
        window.open(res.data, "_blank", "noreferrer");
      });
    },

    async gotoQuote() {
      this.quote.dialog = true;
      if (this.details.quotedDate) {
        this.quote.item = {
          companyId: this.details.company ? this.details.company.id : null,
          members: this.details.members ? this.details.members : null,
          policyPlan: this.details.policyPlan ? this.details.policyPlan : null,
          quotedDate: this.details.quotedDate
            ? moment(this.details.quotedDate)
            : moment(),
          state: this.details.state ? this.details.address.state : null,
          premium: this.details.premium ? this.details.premium : null,
        };
      }
    },
    cancelQuote(value) {
      this.quote = {
        dialog: false,
        item: {
          members: null,
          policyPlan: null,
          companyId: null,
          quotedDate: moment(),
          premium: null,
        },
      };
      if (value == true) {
        this.getPatientData();
      }
    },

    gotoCustomer() {
      this.$router.push(`/customers/details/${this.details.customerUuid}`);
    },
  },
});
</script>
<style lang="scss" scoped>
.surgi {
  position: relative;
}
.editsurgi {
  position: absolute;
  right: -30px;
  top: -5px;
}
section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  background-color: rgb(40, 37, 53);

  main {
    padding: 10px;
    height: 75vh;
    margin: 10vh 0 10vh;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;

    &::-webkit-scrollbar {
      width: 0.25rem;
    }

    &::-webkit-scrollbar-track {
      background: #1e1e24;
    }

    &::-webkit-scrollbar-thumb {
      background: #6649b8;
    }
  }

  form {
    height: 5vh;
    position: fixed;
    bottom: 0;
    background-color: rgb(24, 23, 23);
    width: 100%;
    max-width: 728px;
    display: flex;
    font-size: 1.5rem;

    button {
      width: 20%;
      background-color: rgb(56, 56, 143);
    }

    input {
      line-height: 1.5;
      width: 100%;
      font-size: 1.5rem;
      background: rgb(58, 58, 58);
      color: white;
      outline: none;
      border: none;
      padding: 0 10px;
    }
  }

  .message {
    display: flex;
    align-items: center;

    &.received {
      p {
        background: #e5e5ea;
        color: #000;
      }
    }

    &.sent {
      flex-direction: row-reverse;

      p {
        color: #fff;
        background: #0b93f6;
        align-self: flex-end;
      }
    }

    .img {
      width: 100px;
      height: 80px;
      max-height: 80px;
      max-width: 100px;
      margin: 2px 5px;
    }

    p {
      max-width: 500px;
      margin-bottom: 12px;
      line-height: 24px;
      padding: 10px 20px;
      border-radius: 25px;
      position: relative;
      color: #fff;
      text-align: justify;
    }
  }

  button {
    background-color: #282c34; /* Green */
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    font-size: 1.25rem;
    border-radius: 5%;
    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  button,
  input {
    color: #fff;
    border: none;
  }

  p {
    max-width: 500px;
    margin-bottom: 12px;
    line-height: 24px;
    padding: 10px 20px;
    border-radius: 25px;
    position: relative;
    color: white;
    text-align: center;
  }
}
</style>
<i18n>
{
  "en": {   
    "dialogTitle": "Reason why you open details",
    "notification": "Successfully removed" 
  },
  "es": {    
    "dialogTitle": "Razon por la que abre los detalles ",
    "notification": "Eliminado con éxito"
}}
</i18n>
