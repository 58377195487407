<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div>
    <v-card class="overflow-hidden" :disabled="disableWhileLoading">
      <v-toolbar class="primary" tag="div" flat dark tile>
        <div class="text-h6">{{ "Customers" }}</div>
        <v-spacer></v-spacer>

        <v-btn :loading="loading" text @click="getCustomers">
          <v-icon>mdi-autorenew</v-icon>
          {{ $t("labelUpdate") }}
        </v-btn>
      </v-toolbar>
      <div class="d-flex align-center">
        {{/* Filter */ }}
        <div class="col-sm-4">
          <v-text-field
            v-model.trim="query"
            :label="'Search'"
            hide-details
            prepend-inner-icon="mdi-magnify"
            clearable
            outlined
            dense
          />
        </div>

        <!-- <div class="col-sm-6 d-flex">
          <template v-if="filterDate == 'custom'">
            <ma-date-picker class="pt-3 mt-3" v-model="dateFrom" label="From" past />
            <ma-date-picker class="pt-3 mt-3 mx-1" v-model="dateTo" label="To" past />
          </template>
          <template v-if="dateFrom || dateTo">
            <div class="mt-3 pt-3">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    fab
                    small
                    dark
                    x-small
                    v-on="on"
                    class="mr-1"
                    color="error"
                    @click="clearDates()"
                  >
                    <v-icon dark> mdi-refresh </v-icon>
                  </v-btn>
                </template>
                <span>Clear Dates</span>
              </v-tooltip>
            </div>
          </template>
        </div> -->
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="openFilters"
          ><v-icon>mdi-filter-variant</v-icon> Filters</v-btn
        >
      </div>

      <v-divider></v-divider>
      <template v-if="!noDatesFT">
        <v-layout class="pa-4" column>
          <div class="mr-2 text-capitalize">
            {{ filterDate.replace("-", " ") }}
          </div>
          <v-layout column class="text-body-1 text--secondary">
            <div>
              From
              <span class="text-caption">{{ prettyDate(query1.date1) }}</span>
            </div>
            <div>
              To
              <span class="text-caption">{{ prettyDate(query1.date2) }}</span>
            </div>
          </v-layout>
        </v-layout>
        <v-divider></v-divider>
      </template>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :options.sync="options"
        :server-items-length="totalcustomers"
        :items="customers"
        :show-select="isAdmins"
        :loading="disableWhileLoading"
        :footer-props="footerProps"
        item-key="uuid"
        @click:row="showDetails"
      >
        <template v-slot:[`item.fullname`]="{ item }">
          <div>
            {{ item.name ? item.name : "" }} {{ item.lastName ? item.lastName : "" }}
          </div>
        </template>
        <template v-slot:[`item.phone`]="{ item }">
          <div class="cursor-pointer">
            {{ item.phone ? "..... " + item.phone.slice(item.phone.length - 4) : "-" }}
          </div>
        </template>
      </v-data-table>
    </v-card>

    <template v-if="filterExpand">
      <v-navigation-drawer v-model="filterExpand" app v-cloak>
        <div class="d-flex pa-2">
          <span class="py-1">Filters</span><v-spacer></v-spacer
          ><v-btn text @click="closeFilters"><v-icon>mdi-close</v-icon></v-btn>
        </div>
        <v-divider></v-divider>
        <v-row no-gutters>
          <v-col cols="12" class="px-1 mt-1" sm="12">
            <v-select
              v-model="stateSelect"
              label="by State"
              :items="states"
              prepend-inner-icon="mdi-magnify"
              item-text="name"
              :loading="loadingStates"
              item-value="id"
              hide-details
              clearable
              outlined
              dense
              @change="getCustomers"
            />
          </v-col>
          <v-divider></v-divider>
          <v-col cols="12" class="mt-3 px-1 mb-0" sm="12">
            <v-autocomplete
              :disabled="stateCities.length == 0"
              label="by City"
              v-model.number="citySelect"
              :items="stateCities"
              item-text="name"
              item-value="id"
              :loading="loadingCities"
              autocomplete="off"
              @change="getCustomers"
              outlined
              clearable
              dense
            />
          </v-col>
          <v-divider></v-divider>
          <v-col cols="12" class="px-1" sm="12">
            <v-text-field
              label="by Zip Code"
              v-model="zipCode"
              autocomplete="postal-code"
              outlined
              clearable
              dense
            />
          </v-col>
        </v-row> </v-navigation-drawer
    ></template>
  </div>
</template>

<script>
import { getAPI } from "@/api/axios-base";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { notifyError } from "@/components/Notification";
import { cleanPhoneNumber, isPhoneNumber } from "@/utils/formatPhone";
import MaDatePicker from "@/components/MaDatePicker/MaDatePicker.vue";
import moment from "moment";

export default {
  components: {
    MaDatePicker,
  },
  data() {
    return {
      filterExpand: false,
      filterDate: "custom",
      dateTo: null,
      dateFrom: null,
      loading: false,
      query1: {
        date1: "",
        date2: "",
      },
      selected: [],
      customers: [],
      query: null,
      stateSelect: null,
      citySelect: null,
      zipCode: null,
      stateCities: [],
      loadingCities: false,
      totalcustomers: 0,
      isAdmins: false,
      options: {},
      footerProps: {
        "items-per-page-options": [25, 50, 100, 250, 500, 1000],
      },
      headers: [
        { text: "Fullname", show: true, width: 200, value: "fullname", sortable: true },
        { text: "Phone", show: true, width: 200, value: "phone", sortable: true },
        { text: "Zip Code", show: true, width: 200, value: "zipCode", sortable: true },
        { text: "Active", show: true, width: 200, value: "active", sortable: true },
        { text: "Healths", show: true, width: 200, value: "healths", sortable: true },
        { text: "Autos", show: true, width: 200, value: "autos", sortable: true },
      ],
    };
  },
  computed: {
    ...mapState("crmMedicFormModule", ["agents", "states", "loadingStates"]),
    ...mapGetters(["isSuper", "isAdmin", "isSupervisor"]),
    disableWhileLoading() {
      return this.loading;
    },

    isZipCode() {
      if (this.zipCode == null || this.zipCode == "") return true;
      return /^[0-9]{5}$/.test(this.zipCode);
    },

    noDatesFT() {
      if (
        this.dateFrom == null ||
        this.dateFrom == "" ||
        this.dateFrom == undefined ||
        this.dateTo == null ||
        this.dateTo == undefined ||
        this.dateTo == ""
      ) {
        return true;
      }
      return false;
    },
  },
  watch: {
    options(val) {
      if (val != {}) {
        this.getCustomers();
      }
    },
    query(val) {
      if (val == null) {
        this.getCustomers();
      } else if (val.length >= 3 || val.length == 0) {
        if (isPhoneNumber(val)) {
          this.query = cleanPhoneNumber(val);
          if (this.query[0] == "1") {
            this.query = this.query.slice(1);
          }
        }
        this.getCustomers();
      }
    },
    dateFrom(val) {
      if (val != null && this.dateTo != null && this.dateTo != "") {
        this.getCustomers();
      }
    },
    dateTo(val) {
      if (val != null && this.dateFrom != null && this.dateFrom != "") {
        this.getCustomers();
      }
    },

    stateSelect: function (stateId) {
      if (stateId == undefined || stateId == null) {
        this.stateCities = [];
        this.loadingCities = false;

        return;
      }

      this.loadingCities = true;
      this.actGetCityByState(stateId).then((cities) => {
        this.loadingCities = false;
        this.stateCities = cities;
      });
    },
    zipCode: {
      handler(val, valold) {
        if (val != valold) {
          if (this.isZipCode) {
            this.getCustomers();
          }
        }
      },
    },
  },
  methods: {
    ...mapActions("crmMedicFormModule", ["actListStates", "actGetCityByState"]),
    ...mapMutations(["mutFilterDrawerExpand", "mutDrawerExpand"]),
    clearDates() {
      this.dateFrom = "";
      this.dateTo = "";
      this.getCustomers();
    },

    getCustomers() {
      const { page, itemsPerPage } = this.options;
      const itmper = itemsPerPage != -1 ? itemsPerPage : 10;
      let body = {
        offset: (page - 1) * itmper,
        limit: itmper,
        query: this.query,
        dateInterval: {
          date1: moment(this.dateFrom).startOf("day").toISOString(),
          date2: moment(this.dateTo).endOf("day").toISOString(),
        },
      };

      this.query1 = body.dateInterval;

      this.loading = true;
      if (body.query == null) {
        delete body.query;
      }
      if (this.noDatesFT) {
        delete body.dateInterval;
      }
      getAPI
        .post("/customer/filterList", body)
        .then((res) => {
          this.customers = res.data.result;
          this.totalcustomers = res.data.count;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          let mess = error.response.data.message.toString();
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
    showDetails(customer) {
      this.$router.push(`/customers/details/${customer.uuid}`);
    },

    prettyDate(date) {
      if (date === null || date === "") return "";

      return moment(date).format("dddd D MMMM YYYY, h:mm:ss a");
    },
    openFilters() {
      this.filterExpand = !this.filterExpand;
      this.mutFilterDrawerExpand(this.filterExpand);
      this.mutDrawerExpand(this.filterExpand);
    },
    closeFilters() {
      this.filterExpand = false;
      this.mutFilterDrawerExpand(this.filterExpand);
      this.mutDrawerExpand(this.filterExpand);
    },
  },
  async mounted() {
    if (this.isAdmin && this.isSuper) {
      this.isAdmins = true;
    }
    await this.actListStates();
  },
  destroyed() {
    this.closeFilters();
  },
};
</script>
<style lang=""></style>
