<template>
  <div>
    <template>
      <v-data-table
        dense
        :headers="headers"
        :items="payments"
        :footer-props="footerProps"
      >
        <template v-slot:[`header.payment`]="{ header }">
          <div class="fill-width background-green pa-1">
            <div class="d-flex justify-center">{{ header.text }}</div>
            <v-row no-gutters class="fill-width d-flex justify-center">
              <v-col cols="12" sm="4" class="d-flex justify-center"> Monthly Fee </v-col>
              <v-col cols="12" sm="4" class="d-flex justify-center"> Over rate </v-col>
              <v-col cols="12" sm="4" class="d-flex justify-center"> Bond </v-col>
            </v-row>
          </div>
        </template>
        <template v-slot:[`header.chargeBack`]="{ header }">
          <div class="fill-width background-red pa-1">
            <div class="d-flex justify-center">{{ header.text }}</div>
            <v-row no-gutters class="fill-width">
              <v-col cols="12" sm="4" class="d-flex justify-center"> Monthly Fee </v-col>
              <v-col cols="12" sm="4" class="d-flex justify-center"> Over rate </v-col>
              <v-col cols="12" sm="4" class="d-flex justify-center"> Bond </v-col>
            </v-row>
          </div>
        </template>
        <template v-slot:[`header.balance`]="{ header }">
          <div class="fill-width background-orange pa-1">
            <div class="d-flex justify-center">{{ header.text }}</div>
            <v-row no-gutters class="fill-width">
              <v-col cols="12" sm="3" class="d-flex justify-center"> Monthly Fee </v-col>
              <v-col cols="12" sm="2" class="d-flex justify-center"> Over rate </v-col>
              <v-col cols="12" sm="2" class="d-flex justify-center"> Bond </v-col>
              <v-col cols="12" sm="2" class="d-flex justify-center"> Amount </v-col>
              <v-col cols="12" sm="3" class="d-flex justify-center"> Range </v-col>
            </v-row>
          </div>
        </template>
        <template v-slot:[`item.createAt`]="{ item }">
          <div class="cursor-pointer">
            {{ formatDate(item.createAt) }}
          </div>
        </template>
        <template v-slot:[`item.selectedDate`]="{ item }">
          <div class="cursor-pointer">
            {{
              item.monthByAgent
                ? item.monthByAgent + ", " + item.yearByAgent
                : formatDateDet(item.createAt)
            }}
          </div>
        </template>
        <template v-slot:[`item.amount`]="{ item }">
          <div
            class="cursor-pointer"
            :class="item.amount > 0 ? 'green--text' : 'red--text'"
          >
            {{ item.amount | currency }}
          </div>
        </template>
        <template v-slot:[`item.payment`]="{ item }">
          <template v-if="item">
            <v-row no-gutters class="fill-width fill-height background-green">
              <v-col cols="12" sm="4" class="d-flex justify-center align-center">
                {{ item.payment.monthlyfee | currency }}
              </v-col>
              <v-col cols="12" sm="4" class="d-flex justify-center align-center">
                {{ item.payment.over | currency }}
              </v-col>
              <v-col cols="12" sm="4" class="d-flex justify-center align-center">
                {{ item.payment.bond | currency }}
              </v-col>
            </v-row>
          </template>
        </template>
        <template v-slot:[`item.chargeBack`]="{ item }">
          <v-row no-gutters class="fill-width fill-height background-red">
            <v-col cols="12" sm="4" class="d-flex justify-center align-center">
              {{ item.payment.monthlyfee | currency }}
            </v-col>
            <v-col cols="12" sm="4" class="d-flex justify-center align-center">
              {{ item.payment.over | currency }}
            </v-col>
            <v-col cols="12" sm="4" class="d-flex justify-center align-center">
              {{ item.payment.bond | currency }}
            </v-col>
          </v-row>
        </template>
        <template v-slot:[`item.balance`]="{ item }">
          <v-row no-gutters class="fill-width fill-height background-orange">
            <v-col cols="12" sm="3" class="d-flex justify-center align-center">
              {{ item.balance.monthlyfee | currency }}
            </v-col>
            <v-col cols="12" sm="2" class="d-flex justify-center align-center">
              {{ item.balance.over | currency }}
            </v-col>
            <v-col cols="12" sm="2" class="d-flex justify-center align-center">
              {{ item.balance.bond | currency }}
            </v-col>
            <v-col cols="12" sm="2" class="d-flex justify-center align-center">
              {{ item.balance.amount | currency }}
            </v-col>
            <v-col cols="12" sm="3" class="d-flex justify-center align-center">
              {{ item.balance.range }}
            </v-col>
          </v-row>
        </template>
      </v-data-table></template
    >
  </div>
</template>
<script>
import { getAPI } from "@/api/axios-base";
import formatDate from "@/utils/formatDate";
import moment from "moment";

export default {
  props: {
    payments: Array,
  },
  data() {
    return {
      headers: [
        { text: "Created At", width: 100, value: "createAt", sortable: false },
        { text: "Amount", width: 60, value: "amount", sortable: false },
        { text: "Type", width: 100, value: "type", sortable: false },

        { text: "Month", width: 120, value: "month", sortable: false },
        { text: "Year", width: 60, value: "year", sortable: false },
        {
          text: "Selected Import Date",
          width: 120,
          value: "selectedDate",
          sortable: false,
        },

        /* { text: "Payments", width: 280, value: "payment", sortable: false },
        { text: "Charge Back", width: 280, value: "chargeBack", sortable: false },
        { text: "Balance", width: 380, value: "balance", sortable: false }, */
      ],
      paymentsLocal: [],
      footerProps: {
        "items-per-page-options": [25, 50, 100, 250, 500, 1000],
      },
    };
  },
  watch: {
    payments: {
      handler(val) {
        this.paymentsLocal = val;
      },
    },
  },
  methods: {
    formatDate(date) {
      const d = moment(date).format("MM/DD/YYYY");
      return d;
    },
    formatDateDet(date) {
      const d = moment(date).format("MMMM, YYYY");
      return d;
    },
  },
  mounted() {
    this.paymentsLocal = this.payments;
  },
};
</script>
<style lang=""></style>
