<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <v-card
    class="overflow-hidden mx-auto"
    :disabled="loading"
    :loading="loading"
    width="700"
  >
    <v-toolbar class="primary" tag="div" flat dark tile>
      <v-toolbar-title class="font-weight-medium"
        >Add State City</v-toolbar-title
      >
      <v-spacer />
    </v-toolbar>

    <v-row no-gutters class="mx-2">
      <v-col cols="12" sm="5" class="pr-1">
        <label class="font-weight-medium" for="state">State </label>
        <v-autocomplete
          :items="states"
          v-model="stateId"
          clearable
          item-text="name"
          item-value="id"
          :loading="loadingStates"
          autocomplete="off"
          outlined
          dense
        />
      </v-col>
      <v-col cols="12" sm="5">
        <label class="font-weight-medium" for="state">City </label>
        <v-text-field
          v-model="name"
          autocomplete="off"
          outlined
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="2" class="pt-4">
        <v-btn
          class="mx-1 mt-2"
          :disabled="!validAdd"
          :loading="loading"
          @click="addCity"
          color="success"
          ><v-icon>mdi-plus</v-icon> Add</v-btn
        >
      </v-col>
    </v-row>
    <v-row no-gutters class="mx-1">
      <v-col cols="12">
        <template>
          <template v-if="cities.length != 0">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              class="mx-4 search"
            ></v-text-field>
          </template>

          <v-data-table
            :headers="headers"
            :loading="loadingCities"
            :search="search"
            :items="cities"
            :footer-props="footerProps"
            class="elevation-1"
          >
          </v-data-table>
        </template>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import { getAPI } from "@/api/axios-base";
import { mapActions, mapState } from "vuex";
import { notifyError, notifySuccess } from "@/components/Notification";
export default {
  name: "states-city",
  data() {
    return {
      name: null,
      stateId: null,
      cities: [],
      loading: false,
      footerProps: { "items-per-page-options": [15, 50, 100, 500, 1000] },
      loadingCities: false,
      search: "",
      headers: [
        {
          text: "City",
          align: "start",
          sortable: false,
          value: "name",
        },
      ],
    };
  },
  computed: {
    ...mapState("crmMedicFormModule", ["states", "loadingStates"]),
    validAdd() {
      if (this.name == null || this.stateId == null) {
        return false;
      }
      return true;
    },
  },
  watch: {
    stateId(val) {
      if (val != null && val != undefined) {
        this.getCities(val);
      } else {
        this.cities = [];
      }
    },
  },
  methods: {
    ...mapActions("crmMedicFormModule", ["actListStates", "actGetCityByState"]),
    getCities(val) {
      this.loadingCities = true;
      this.actGetCityByState(val)
        .then((cities) => {
          this.loadingCities = false;
          this.cities = cities;
        })
        .catch((error) => {
          this.loadingCities = false;
          let mess = error.response.data.message.toString();

          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
    addCity() {
      const body = { name: this.name, stateId: this.stateId };
      this.loading = true;
      getAPI
        .post("/city/create", body)
        .then((res) => {
          this.clearAll();
          this.getCities(this.stateId);
          notifySuccess("City has been added successfully ");
        })
        .catch((error) => {
          this.loading = false;
          let mess = error.response.data.message.toString();

          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
    clearAll() {
      this.loading = false;
      this.name = null;
    },
  },
  mounted() {
    this.actListStates();
  },
};
</script>
<style lang="scss" scoped>
.search {
  max-width: 250px !important;
}
</style>
