<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div>
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title
          >Policies
          {{
            typeData == "company"
              ? "By Company: "
              : typeData == "agent"
              ? "By Agent: "
              : "By Creator: "
          }}
          {{ nameItem }}</v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-btn icon dark @click="$emit('closeDialog')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="policies"
        :options.sync="options"
        :server-items-length="total"
        :loading="loading"
        :footer-props="footerProps"
        @click:row="showDetails"
        class="elevation-1"
      >
        <template v-slot:[`item.polizeStatus`]="{ item }">
          <div
            class="text-body-1 font-weight-bold"
            v-bind:style="{ color: colorShow(item.polizeStatus) }"
          >
            {{ statusShow(item.polizeStatus) }}
          </div>
        </template>
        <template v-slot:[`item.company`]="{ item }">
          {{ item.company ? item.company.name : "-" }}
        </template>
        <template v-slot:[`item.policyPlan`]="{ item }">
          <div
            class="font-weight-bold"
            v-bind:style="{ color: colorPolicy(item.policyPlan) }"
          >
            {{ item.policyPlan }}
          </div>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
import { getAPI } from "@/api/axios-base";
import { notifyError } from "@/components/Notification";
import { PolicyStatus } from "@/utils/enums/policystatus";
export default {
  name: "policies-view",
  props: {
    typeData: String,
    idSearch: String,
    nameItem: String,
  },
  data() {
    return {
      loading: false,
      options: {},
      total: 0,
      footerProps: {
        "items-per-page-options": [100, 500, 1000],
      },
      policies: [],
    };
  },
  computed: {
    headers() {
      let h = [
        {
          text: "Client",
          align: "start",
          sortable: false,
          value: "fullname",
        },
        { text: "Metal Plan", value: "policyPlan", sortable: false },
        {
          text: "Status",
          align: "start",
          sortable: false,
          value: "polizeStatus",
        },
      ];
      if (this.nameItem == "All Policies" && this.typeData == "company") {
        h = [
          {
            text: "Client",
            align: "start",
            sortable: false,
            value: "fullname",
          },
          { text: "Company", value: "company", sortable: false },
          { text: "Metal Plan", value: "policyPlan", sortable: false },
          {
            text: "Status",
            align: "start",
            sortable: false,
            value: "polizeStatus",
          },
        ];
      }
      return h;
    },
  },
  watch: {
    options(val) {
      if (val != {}) {
        this.getPolicies();
      }
    },
  },
  methods: {
    showDetails(item) {
      this.$router.push(`/healths/details/${item.uuid}`);
    },

    getPolicies() {
      let url = "patient/filterPatients";
      let body = {};
      const { page, itemsPerPage } = this.options;
      const itmper = itemsPerPage != -1 ? itemsPerPage : 100;
      const range = {
        limit: itmper,
        offset: (page - 1) * itmper,
      };
      body = { ...body, range };
      if (this.typeData == "company" && this.idSearch != "total") {
        body = { ...body, companyId: Number(this.idSearch) };
      }
      if (this.typeData == "agent" && this.idSearch != "total") {
        body = { ...body, agentUuid: this.idSearch };
      }
      if (this.typeData == "creator" && this.idSearch != "total") {
        body = { ...body, employeeUuid: this.idSearch };
      }
      this.loading = true;

      getAPI
        .post(url, body)
        .then((res) => {
          this.loading = false;
          this.total = res.data.count;
          this.policies = res.data.result;
        })
        .catch((error) => {
          let mess = error.response.data.message.toString();
          notifyError(error.response.data, `An error occurred: ${mess}`);
          this.loading = false;
        });
    },

    colorShow(item) {
      const status = item;
      if (status != null && status != undefined) {
        switch (status) {
          case "POLIZE_PENDING":
            return "#1663E0FF";
          case "PAYMENT_PENDING":
            return "#4B7ECE";
          case "POLIZE_OK":
            return "#19FF00FF";
          case "PAYMENT_OK":
            return "#00FFB3";
          case "POLIZE_MEMBERS_NO_MATCH":
            return "#FF0033";
          case "PAYMENT_MEMBERS_NO_MATCH":
            return "#FFAA00";
          case "POLICY_CANCELLATION":
            return "#FF4400";
          case "POLICY_RECONCILIATION":
            return "#B7FF00";
          case "POLICY_RENEWAL":
            return "#EEC167";
          case "OTHER_PARTY":
            return "#9C27B0";
          case "NO_AOR":
            return "#625F62";

          default:
            return "#000";
        }
      }
      return "#000";
    },

    statusShow(item) {
      if (item) {
        const st = PolicyStatus[item];
        return st;
      }

      return "-";
    },
    colorPolicy(value) {
      const status = value;
      if (status != null && status != undefined) {
        switch (status) {
          case "GOLD":
            return "#EDC43D";
          case "SILVER":
            return "#C0C0C0";
          case "BRONZE":
            return "#CD7F32";

          default:
            return "#000";
        }
      }
      return "#000";
    },
  },
  mounted() {
    //this.getPolicies();
  },
};
</script>
<style lang=""></style>
