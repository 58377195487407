<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div>
    <div class="d-flex flex-row align-center justify-space-between mb-4">
      <div class="top-header mb-0 text-uppercase">
        <v-icon class="cursor--pointer" @click="backEmployees">mdi-reply</v-icon>

        {{ "Employees" }}
      </div>
    </div>

    <v-card class="my-1">
      <v-toolbar class="primary" tag="div" flat dark tile>
        <div class="text-h6">Employee Details</div>
        <v-spacer></v-spacer>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" color="white" icon @click="gotoEmployee"
              ><v-icon>mdi-account-edit</v-icon></v-btn
            >
          </template>
          <span>Employee Edit</span>
        </v-tooltip>
      </v-toolbar>
      <template v-if="loading">
        <div class="text-center my-2">
          <v-progress-circular
            :size="70"
            :width="7"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </div>
      </template>
      <template v-if="employee">
        <v-tabs v-model="tabSelected" grow show-arrows>
          <v-tab v-for="(tab, i) in tabs" :key="i" :href="tab.href">
            <template>
              <v-icon left>{{ tab.icon }}</v-icon>
              {{ tab.title }}
            </template>
          </v-tab>
          {{/* Employee Details */}}
          <v-tab-item value="details">
            <v-container>
              <v-row no-gutters>
                <v-col cols="12" sm="4">
                  <div class="text-caption font-weight-blod text--secondary">
                    Full name:
                  </div>
                  <div class="text-body-1">
                    {{ employee.fullname ? employee.fullname : "-" }}
                  </div>
                </v-col>
                <v-col cols="12" sm="4">
                  <div class="text-caption font-weight-blod text--secondary">
                    Username:
                  </div>
                  <div class="text-body-1">
                    {{ employee.username ? employee.username : "-" }}
                  </div>
                </v-col>
                <v-col cols="12" sm="4">
                  <div class="text-caption font-weight-blod text--secondary">Email:</div>
                  <div class="text-body-1">
                    {{ employee.email ? employee.email : "-" }}
                  </div>
                </v-col>
              </v-row>
              <v-divider class="my-2"></v-divider>
              <v-row no-gutters>
                <v-col cols="12" sm="3">
                  <div class="text-caption font-weight-blod text--secondary">Phone:</div>
                  <div class="text-body-1">
                    <template v-if="employee.phone">
                      <div class="d-flex">
                        <div class="cursor-pointer" @click="makeCallV(employee.phone)">
                          {{ employee.phone }}
                        </div>
                        <div class="mx-1">
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-icon
                                @click="copyPhone(employee.phone)"
                                v-on="on"
                                small
                                color="info"
                                >mdi-content-copy</v-icon
                              >
                            </template>
                            <span>Copy Phone</span>
                          </v-tooltip>
                        </div>
                      </div>
                    </template>
                    <template v-else>-</template>
                  </div>
                </v-col>
                <v-col cols="12" sm="3">
                  <div class="text-caption font-weight-blod text--secondary">Ext:</div>
                  <div class="text-body-1">
                    <template v-if="employee.ext">
                      <div class="d-flex">
                        <div class="cursor-pointer" @click="makeCallV(employee.ext)">
                          {{ employee.ext }}
                        </div>
                        <div class="mx-1">
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-icon
                                @click="copyPhone(employee.ext)"
                                v-on="on"
                                small
                                color="info"
                                >mdi-content-copy</v-icon
                              >
                            </template>
                            <span>Copy Phone</span>
                          </v-tooltip>
                        </div>
                      </div>
                    </template>
                    <template v-else>-</template>
                  </div>
                </v-col>
                <v-col cols="12" sm="3">
                  <div class="text-caption font-weight-blod text--secondary">Rol:</div>
                  <div
                    class="text-body-1"
                    v-bind:style="{ color: rolColor(employee.rol) }"
                  >
                    {{ employee.rol }}
                  </div>
                </v-col>
                <v-col cols="12" sm="3">
                  <div class="text-caption font-weight-blod text--secondary">
                    Occupation:
                  </div>
                  <div class="text-body-1">
                    {{ employee.ocupation ? employee.ocupation.name : "-" }}
                  </div>
                </v-col>
              </v-row>
              <v-divider class="my-2"></v-divider>
              <v-row no-gutters>
                <v-col cols="12" sm="4">
                  <div class="text-caption font-weight-blod text--secondary">
                    Create Date:
                  </div>
                  <div class="text-body-1">
                    {{ createDate }}
                  </div>
                </v-col>
                <v-col cols="12" sm="4">
                  <div class="text-caption font-weight-blod text--secondary">
                    Start Date:
                  </div>
                  <div class="text-body-1">
                    {{ startDate }}
                  </div>
                </v-col>
                <v-col cols="12" sm="4">
                  <div class="text-caption font-weight-blod text--secondary">
                    End Date:
                  </div>
                  <div class="text-body-1">
                    {{ endDate }}
                  </div>
                </v-col>
              </v-row>
              <v-divider class="mb-4"></v-divider>
              <div class="text-center">Companies</div>
              <v-divider class="mb-2"></v-divider>
              <v-data-table
                :headers="headers"
                :items="employeeCompanies"
                :items-per-page="10"
                item-key="name"
                class="elevation-1"
                :loading="loadingCompanies"
              >
                <template v-slot:[`item.company`]="{ item }">
                  {{ item.companyStatePrice ? item.companyStatePrice.company.name : "-" }}
                </template>
                <template v-slot:[`item.state`]="{ item }">
                  {{ item.companyStatePrice ? item.companyStatePrice.state.name : "-" }}
                </template>
                <template v-slot:[`item.price`]="{ item }">
                  {{ item.price | currency }}
                </template>
                <template v-slot:[`item.createdAt`]="{ item }">
                  {{ getDate(item.createdAt) }}
                </template>
              </v-data-table>
            </v-container>
          </v-tab-item>
          {{/* Employee Payments */}}
          <v-tab-item value="payments">
            <v-container>
              <payments-details />
            </v-container>
          </v-tab-item>
        </v-tabs>
      </template>
    </v-card>
  </div>
</template>

<script>
import { rolIcon, rolColor, statusColor } from "@/utils/rolesStatus";
import { getAPI } from "@/api/axios-base";
import { notifyError } from "@/components/Notification";
import moment from "moment";
import { funtionsPhone, utilMixin } from "@/utils/mixins";
import PaymentsDetails from "./components/PaymentsDetails.vue";

export default {
  components: { PaymentsDetails },
  name: "employee-details",
  mixins: [utilMixin, funtionsPhone],
  data() {
    return {
      employee: null,
      loading: false,
      tabSelected: "details",
      employeeCompanies: [],
      tabs: [
        { value: "details", title: "Details", icon: "mdi-account", href: "#details" },
        { value: "payments", title: "Payments", icon: "mdi-usd", href: "#payments" },
      ],
      headers: [
        { text: "Company", align: "start", value: "company", sortable: true },
        { text: "State", align: "start", value: "state", sortable: true },
        { text: "Price", align: "end", value: "price", sortable: true },
        {
          text: "Create Date",
          align: "end",
          value: "createdAt",
          sortable: true,
        },
        /*  { text: "Color", value: "color", sortable: false }, */
        //  { text: "Default", value: "default", sortable: false },
        { text: "", align: "end", value: "actions", sortable: false },
      ],
      loadingCompanies: false,
    };
  },

  computed: {
    createDate() {
      if (!this.employee) {
        return "-";
      }
      const date = moment(this.employee.createAt);

      return date.format("MM/DD/YYYY");
    },
    startDate() {
      if (!this.employee) {
        return "-";
      }
      if (!this.employee.startDate) {
        return "-";
      }
      const date = moment(this.employee.startDate);
      return date.format("HH:mm");
    },
    endDate() {
      if (!this.employee) {
        return "-";
      }
      if (!this.employee.endDate) {
        return "-";
      }
      const date = moment(this.employee.endDate);

      return date.format("HH:mm");
    },
  },
  methods: {
    close() {
      this.$emit("input", false);
    },
    rolColor,

    backEmployees() {
      this.$router.push("/employees");
    },
    getData() {
      const uuid = this.$route.params.uuid;
      this.loading = true;
      getAPI("/users/findOne/" + uuid)
        .then((res) => {
          this.loading = false;
          this.employee = res.data;
        })
        .catch((error) => {
          this.loading = false;
          let mess = error.response.data.message.toString();

          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },

    getEmployeeCompanies() {
      this.loadingCompanies = true;
      const uuid = this.$route.params.uuid;
      getAPI
        .post("/company-state-price-employee/filterList", {
          employeeUuid: uuid,
        })
        .then((res) => {
          this.loadingCompanies = false;
          this.employeeCompanies = res.data;
        })
        .catch((error) => {
          this.loadingCompanies = false;
          let mess = error.response.data.message.toString();

          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
    gotoEmployee() {
      const id = this.$route.params.uuid;
      this.$router.push(`/employees/edit/${id}`);
    },
  },
  mounted() {
    this.getData();
    this.getEmployeeCompanies();
  },
};
</script>
