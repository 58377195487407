<template>
  <div>
    <template>
      <div class="d-flex justify-end">
        <v-btn text x-small color="primary" @click="getEmployeeCompanies">
          <v-icon>mdi-refresh</v-icon> Update</v-btn
        >
      </div>
      <v-data-table
        dense
        :headers="headers"
        :loading="loading"
        :items="employeeCompanies"
        :footer-props="footerProps"
      >
        <template v-slot:[`item.company`]="{ item }">
          {{ item.companyStatePrice.company.name }}
        </template>
        <template v-slot:[`item.state`]="{ item }">
          {{ item.companyStatePrice.state.name }}
        </template>
        <template v-slot:[`item.monthlyfee`]="{ item }">
          {{ !item.monthlyfee ? 0 : item.monthlyfee | currency }}
        </template>
        <template v-slot:[`item.over`]="{ item }">
          {{ !item.over ? 0 : item.over | currency }}
        </template>
        <template v-slot:[`item.bond`]="{ item }">
          {{ !item.bond ? 0 : item.bond | currency }}
        </template>
      </v-data-table></template
    >
  </div>
</template>
<script>
import { getAPI } from "@/api/axios-base";
import { notifyError } from "@/components/Notification";

export default {
  data() {
    return {
      headers: [
        { text: "Company", align: "start", value: "company", sortable: false },
        { text: "State", align: "start", value: "state", sortable: false },
        { text: "Monthly Fee", align: "center", value: "monthlyfee", sortable: false },
        { text: "Over Rate", align: "center", value: "over", sortable: false },
        { text: "Bond", align: "center", value: "bond", sortable: false },
        /*   { text: "Amount", align: "center", value: "amount", sortable: false },
          { text: "Range", align: "center", value: "range", sortable: false }, */
      ],
      employeeCompanies: [],
      loading: false,

      footerProps: {
        "items-per-page-options": [25, 50, 100, 250, 500, 1000],
      },
    };
  },
  methods: {
    getEmployeeCompanies() {
      this.loading = true;
      const uuid = this.$route.params.uuid;
      getAPI
        .post("/company-state-price-employee/filterList", {
          employeeUuid: uuid,
        })
        .then((res) => {
          this.loading = false;
          this.employeeCompanies = res.data;
        })
        .catch((error) => {
          this.loading = false;
          let mess = error.response.data.message.toString();

          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
  },
  mounted() {
    this.getEmployeeCompanies();
  },
};
</script>
<style lang="scss"></style>
