var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[[_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{attrs:{"text":"","x-small":"","color":"primary"},on:{"click":_vm.getEmployeeCompanies}},[_c('v-icon',[_vm._v("mdi-refresh")]),_vm._v(" Update")],1)],1),_c('v-data-table',{attrs:{"dense":"","headers":_vm.headers,"loading":_vm.loading,"items":_vm.employeeCompanies,"footer-props":_vm.footerProps},scopedSlots:_vm._u([{key:"item.company",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.companyStatePrice.company.name)+" ")]}},{key:"item.state",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.companyStatePrice.state.name)+" ")]}},{key:"item.monthlyfee",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(!item.monthlyfee ? 0 : item.monthlyfee))+" ")]}},{key:"item.over",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(!item.over ? 0 : item.over))+" ")]}},{key:"item.bond",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(!item.bond ? 0 : item.bond))+" ")]}}],null,true)})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }