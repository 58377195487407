<template>
  <div>
    <div class="d-flex">
      <div class="col-sm-3">
        <v-text-field
          dense
          outlined
          :disabled="loading"
          hide-details
          label="by Policy Number"
          prepend-inner-icon="mdi-magnify"
          v-model="policyNumber"
          clearable
        ></v-text-field>
      </div>
      <v-spacer></v-spacer>
      <v-btn small :disabled="loading" color="primary" text @click="openFilters"
        ><v-icon>mdi-filter-variant</v-icon> Filters</v-btn
      >
      <v-btn text small :disabled="loading" color="primary" @click="getPayments">
        <v-icon>mdi-refresh</v-icon> Update</v-btn
      >
    </div>

    <template>
      <v-data-table
        dense
        :headers="headers"
        :loading="loading"
        :items="payments"
        :options.sync="options"
        :server-items-length="total"
        :footer-props="footerProps"
      >
        <template v-slot:[`item.fullname`]="{ item }">
          <div class="text-body-1 cursor-pointer" @click="showDetails(item)">
            {{ item.fullname }}
          </div>
        </template>
        <template v-slot:[`header.payment`]="{ header }">
          <div class="fill-width pa-1">
            <div class="text--center">{{ header.text }}</div>
            <v-row no-gutters class="fill-width">
              <v-col cols="12" sm="1"> Jan </v-col>
              <v-col cols="12" sm="1"> Feb </v-col>
              <v-col cols="12" sm="1"> Mar </v-col>
              <v-col cols="12" sm="1"> Apr </v-col>
              <v-col cols="12" sm="1"> May </v-col>
              <v-col cols="12" sm="1"> Jun </v-col>
              <v-col cols="12" sm="1"> Jul </v-col>
              <v-col cols="12" sm="1"> Aug </v-col>
              <v-col cols="12" sm="1"> Sep </v-col>
              <v-col cols="12" sm="1"> Oct </v-col>
              <v-col cols="12" sm="1"> Nov </v-col>
              <v-col cols="12" sm="1"> Dec </v-col>
            </v-row>
          </div>
        </template>

        <template v-slot:[`item.amountTopay`]="{ item }">
          {{ !item.amountTopay ? 0 : item.amountTopay | currency }}
        </template>
        <template v-slot:[`item.polizestatus`]="{ item }">
          <div
            class="text-body-1 cursor-pointer"
            v-bind:style="{ color: colorShow(item) }"
          >
            {{ statusShow(item) }}
          </div>
        </template>
        <template v-slot:[`item.effectivedate`]="{ item }">
          <span>{{ item.effectivedate ? showEffective(item.effectivedate) : "-" }}</span>
        </template>
        <template v-slot:[`item.paymentstatus`]="{ item }">
          <template v-if="item.paymentstatus">
            <span
              v-bind:style="{ color: colorShowPaymentStatus(item.paymentstatus) }"
              class="font-weight-bold"
              >{{ ToshowPaymentStatus(item.paymentstatus) }}</span
            >
          </template>
        </template>
        <template v-slot:[`item.payment`]="{ item }">
          <template>
            <v-row no-gutters class="fill-width fill-height">
              <v-col cols="12" sm="1" class="d-flex justify-center align-center">
                <v-icon :color="getIcon(item[`${yearSelect}_JANUARY`]).color">{{
                  getIcon(item[`${yearSelect}_JANUARY`]).icon
                }}</v-icon>
              </v-col>
              <v-col cols="12" sm="1" class="d-flex justify-center align-center">
                <v-icon :color="getIcon(item[`${yearSelect}_FEBRUARY`]).color">{{
                  getIcon(item[`${yearSelect}_FEBRUARY`]).icon
                }}</v-icon>
              </v-col>
              <v-col cols="12" sm="1" class="d-flex justify-center align-center">
                <v-icon :color="getIcon(item[`${yearSelect}_MARCH`]).color">{{
                  getIcon(item[`${yearSelect}_MARCH`]).icon
                }}</v-icon>
              </v-col>
              <v-col cols="12" sm="1" class="d-flex justify-center align-center">
                <v-icon :color="getIcon(item[`${yearSelect}_APRIL`]).color">{{
                  getIcon(item[`${yearSelect}_APRIL`]).icon
                }}</v-icon>
              </v-col>
              <v-col cols="12" sm="1" class="d-flex justify-center align-center">
                <v-icon :color="getIcon(item[`${yearSelect}_MAY`]).color">{{
                  getIcon(item[`${yearSelect}_MAY`]).icon
                }}</v-icon>
              </v-col>
              <v-col cols="12" sm="1" class="d-flex justify-center align-center">
                <v-icon :color="getIcon(item[`${yearSelect}_JUNE`]).color">{{
                  getIcon(item[`${yearSelect}_JUNE`]).icon
                }}</v-icon>
              </v-col>

              <v-col cols="12" sm="1" class="d-flex justify-center align-center">
                <v-icon :color="getIcon(item[`${yearSelect}_JULY`]).color">{{
                  getIcon(item[`${yearSelect}_JULY`]).icon
                }}</v-icon>
              </v-col>
              <v-col cols="12" sm="1" class="d-flex justify-center align-center">
                <v-icon :color="getIcon(item[`${yearSelect}_AUGUST`]).color">{{
                  getIcon(item[`${yearSelect}_AUGUST`]).icon
                }}</v-icon>
              </v-col>
              <v-col cols="12" sm="1" class="d-flex justify-center align-center">
                <v-icon :color="getIcon(item[`${yearSelect}_SEPTEMBER`]).color">{{
                  getIcon(item[`${yearSelect}_SEPTEMBER`]).icon
                }}</v-icon>
              </v-col>
              <v-col cols="12" sm="1" class="d-flex justify-center align-center">
                <v-icon :color="getIcon(item[`${yearSelect}_OCTOBER`]).color">{{
                  getIcon(item[`${yearSelect}_OCTOBER`]).icon
                }}</v-icon>
              </v-col>
              <v-col cols="12" sm="1" class="d-flex justify-center align-center">
                <v-icon :color="getIcon(item[`${yearSelect}_NOVEMBER`]).color">{{
                  getIcon(item[`${yearSelect}_NOVEMBER`]).icon
                }}</v-icon>
              </v-col>
              <v-col cols="12" sm="1" class="d-flex justify-center align-center">
                <v-icon :color="getIcon(item[`${yearSelect}_DECEMBER`]).color">{{
                  getIcon(item[`${yearSelect}_DECEMBER`]).icon
                }}</v-icon>
              </v-col>
            </v-row>
          </template>
        </template>
      </v-data-table>
      <div class="fill-width d-flex justify-end pa-4">
        <div>
          {{ getIcon("V").type }}:
          <v-icon :color="getIcon('V').color">{{ getIcon("V").icon }}</v-icon>
        </div>
        <v-divider vertical class="mx-3"></v-divider>
        <div>
          {{ getIcon("Y").type }}:
          <v-icon :color="getIcon('Y').color">{{ getIcon("Y").icon }}</v-icon>
        </div>
        <v-divider vertical class="mx-3"></v-divider>
        <div>
          {{ getIcon("O").type }}:
          <v-icon :color="getIcon('O').color">{{ getIcon("O").icon }}</v-icon>
        </div>
        <v-divider vertical class="mx-3"></v-divider>
        <div>
          {{ getIcon("X").type }}:
          <v-icon :color="getIcon('X').color">{{ getIcon("X").icon }}</v-icon>
        </div>
        <v-divider vertical class="mx-3"></v-divider>
        <div>
          {{ getIcon("X1").type }}:
          <v-icon :color="getIcon('X1').color">{{ getIcon("X1").icon }}</v-icon>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import { getAPI } from "@/api/axios-base";
import { PolicyStatus } from "@/utils/enums/policystatus";
import { notifyError } from "@/components/Notification";
import moment from "moment";
export default {
  props: {
    byagent: String,
    ptoNoms: Array,
    pstatus: String,
    flexp: Boolean,
    yandM: Array,
    yearByAgent: Number,
    monthByAgent: String,
    activePaymentE: String,
  },
  data() {
    return {
      filterExpand: false,
      payments: [],
      headers: [
        { text: "Name", width: 300, value: "fullname", sortable: false },
        { text: "#Policy", width: 200, value: "polizenumber", sortable: false },
        { text: "Status", width: 200, value: "polizestatus", sortable: false },
        { text: "Payment Status", width: 200, value: "paymentstatus", sortable: false },
        { text: "Effective Date", width: 200, value: "effectivedate", sortable: false },
        /*   {
          text: "Cancelation Date",
          width: 200,
          value: "cancelationDate",
          sortable: false,
        }, */
        { text: "Member", width: 200, value: "totalmembers", sortable: false },
        { text: "Pay To", width: 200, value: "paytoname", sortable: false },
        /*   {
          text: "Amount To Pay",
          align: "center",
          width: 200,
          value: "amountTopay",
          sortable: false,
        }, */
        {
          text: "Payment",
          width: 450,
          align: "center",
          value: "payment",
          sortable: false,
        },
      ],
      footerProps: {
        "items-per-page-options": [25, 50, 100, 250, 500, 1000],
      },
      options: {},
      total: 0,
      loading: false,
      yearSelect: Number(moment().format("YYYY")),
      yearsAndMonths: [
        {
          year: Number(moment().format("YYYY")),
          months: [
            "JANUARY",
            "FEBRUARY",
            "MARCH",
            "APRIL",
            "MAY",
            "JUNE",
            "JULY",
            "AUGUST",
            "SEPTEMBER",
            "OCTOBER",
            "NOVEMBER",
            "DECEMBER",
          ],
        },
      ],
      employeeUuid: null,
      policyNumber: null,
      yearAndMonthByAgent: {
        year: null,
        month: null,
      },
      clear: false,
      called: false,
      paysTo: [],
      paytoloading: false,
      payToNomIds: [],
      paymentStatus: null,
      activePaymentEnum: null,
      paymentStats: [
        { name: "Pay", value: "PAY" },
        { name: "Reconciliation", value: "RECONCILIATION" },
        { name: "Member Problem", value: "MEMBER_PROBLEM" },
        { name: "Charge Back", value: "CHARGE_BACK" },
        { name: "End", value: "END" },
        { name: "Review", value: "REVIEW" },
      ],
    };
  },
  watch: {
    options(val) {
      if (val != {}) {
        this.getPayments();
      }
    },
    policyNumber(val) {
      this.policyNumber = val != null ? val.toLocaleUpperCase() : val;
      if (val == null) {
        this.getPayments();
      } else if (val.length >= 5 || val.length == 0) {
        this.getPayments();
      }
    },
    byagent: {
      handler(val) {
        this.employeeUuid = val;
        this.getPayments();
      },
    },
    ptoNoms: {
      handler(val) {
        this.payToNomIds = val;
        this.getPayments();
      },
    },
    pstatus: {
      handler(val) {
        this.paymentStatus = val;
        this.getPayments();
      },
    },
    flexp: {
      handler(val) {
        if (val == false) {
          this.filterExpand = false;
        }
      },
    },

    yearByAgent: {
      handler(val) {
        this.yearAndMonthByAgent = {
          year: val,
          month: this.monthByAgent,
        };
        if (val == null && this.monthByAgent == null) {
          if (this.clear == false) {
            this.clear = true;
            this.getPayments();
          }
        } else if (val && this.monthByAgent) {
          this.getPayments();
        }
      },
    },
    monthByAgent: {
      handler(val) {
        this.yearAndMonthByAgent = {
          year: this.yearByAgent,
          month: val,
        };
        if (val == null && this.yearByAgent == null) {
          if (this.clear == false) {
            this.clear = true;
            this.getPayments();
          }
        } else if (val && this.yearByAgent) {
          this.getPayments();
        }
      },
    },

    /*  Var Months and Years */
    yandM: {
      handler(val, val1) {
        if (val != val1) {
          if (val.length == 0) {
            this.yearSelect = Number(moment().format("YYYY"));
            this.yearsAndMonths[0] = {
              year: Number(moment().format("YYYY")),
              months: [
                "JANUARY",
                "FEBRUARY",
                "MARCH",
                "APRIL",
                "MAY",
                "JUNE",
                "JULY",
                "AUGUST",
                "SEPTEMBER",
                "OCTOBER",
                "NOVEMBER",
                "DECEMBER",
              ],
            };
          } else {
            const y = Number(moment(val[0]).format("YYYY"));
            this.yearSelect = y;
            const m = val.map((v) => {
              return moment(v).format("MMMM").toLocaleUpperCase().toString();
            });
            this.yearsAndMonths[0] = {
              year: y,
              months: m,
            };
          }
          this.getPayments();
        }
      },
    },

    activePaymentE: {
      handler(val) {
        this.activePaymentEnum = val;
        this.getPayments();
      },
    },
  },
  methods: {
    getIcon(value) {
      switch (value) {
        case "V":
          return { icon: "mdi-check", color: "success", type: "Pay" };
        case "Y":
          return { icon: "mdi-checkbox-blank", color: "orange", type: `Don't Pay` };
        case "X1":
          return {
            icon: "mdi-close-circle",
            color: "error",
            type: "Charge back (DONT PAY)",
          };
        case "X":
          return { icon: "mdi-close", color: "error", type: "Charge back (PAY)" };
        case "O":
          return {
            icon: "mdi-checkbox-blank-circle",
            color: "gray",
            type: "Inactive month",
          };

        default:
          return {
            icon: "",
            color: "white",
            type: "",
          };
      }
    },

    getPayments() {
      this.loading = true;

      const { page, itemsPerPage } = this.options;
      const itmper = itemsPerPage != -1 ? itemsPerPage : 50;
      let body = {
        limit: itmper,
        offset: (page - 1) * itmper,
      };
      if (this.paymentStatus) {
        body = { ...body, paymentStatus: this.paymentStatus };
      }
      if (this.activePaymentEnum) {
        body = { ...body, activePaymentEnum: this.activePaymentEnum };
      }
      if (this.payToNomIds.length != 0) {
        body = { ...body, payToNomIds: this.payToNomIds };
      }
      if (this.employeeUuid) {
        body = { ...body, employeeUuid: this.employeeUuid };
      }
      if (this.policyNumber) {
        body = { ...body, policyNumber: this.policyNumber };
      }
      if (
        this.yearAndMonthByAgent.year != null &&
        this.yearAndMonthByAgent.month != null
      ) {
        body = { ...body, yearAndMonthByAgent: this.yearAndMonthByAgent };
      }

      body = { ...body, yearsAndMonths: this.yearsAndMonths };

      getAPI
        .post("payment/statics2", body)
        .then((res) => {
          this.total = res.data.count;
          this.payments = res.data.result;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    statusShow(item) {
      const status = item.polizestatus;
      if (status) {
        const st = PolicyStatus[status];
        return st;
      }

      return "-";
    },
    showEffective(effective) {
      return moment(effective).startOf("month").format("MMMM, YYYY");
    },

    colorShow(item) {
      const status = item.polizestatus;
      switch (status) {
        case "ACTIVE":
          return "#19FF00FF";
        case "ACTIVE_PENDING":
          return "#4B7ECE";
        case "CANCELLED":
          return "#FF4400";
        case "PAY_DUE":
          return "#4B7ECE";
        case "REVIEW":
          return "#EEC167";
        case "BLACKLIST":
          return "#270E41";
      }
    },
    colorShowPaymentStatus(item) {
      switch (item) {
        case "PAY":
          return "#19FF00FF";
        case "RECONCILIATION":
          return "#4B7ECE";
        case "MEMBER_PROBLEM":
          return "#ff571a";
        case "CHARGE_BACK":
          return "#FF4400";
        case "END":
          return "#19FF00FF";
        case "REVIEW":
          return "#EEC167";

        default:
          return "#000";
      }
    },
    ToshowPaymentStatus(item) {
      switch (item) {
        case "PAY":
          return "Pay";
        case "RECONCILIATION":
          return "Reconciliation";
        case "MEMBER_PROBLEM":
          return "Member Problem";
        case "CHARGE_BACK":
          return "Charge Back";
        case "END":
          return "End";
        case "REVIEW":
          return "Review";
      }
    },
    listPayto() {
      this.paytoloading = true;

      getAPI(`/pay-to-nom/list`)
        .then((res) => {
          this.paysTo = res.data.filter((r) => r.status == "ACTIVE");

          this.paytoloading = false;
        })
        .catch((error) => {
          this.paytoloading = false;
          let mess = error.response.data.message.toString();
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },

    showDetails(value) {
      this.$router.push(`/healths/details/${value.pol_uuid}`);
    },
    openFilters() {
      this.filterExpand = !this.filterExpand;
      this.$emit("openFilter", this.filterExpand);
    },
  },
  async mounted() {
    await this.listPayto();
  },
};
</script>
<style lang="scss" scoped></style>
