<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <v-dialog persistent v-model="dialog" max-width="500px" transition="dialog-transition">
    <template>
      <v-card>
        <v-toolbar color="info" dark>Manage Status</v-toolbar>
        <v-card-text class="mt-2">
          <div class="d-flex justify-center">
            <v-autocomplete
              class="mx-1 my-3"
              :label="`Select Status`"
              :items="policyStates"
              v-model="statusId"
              item-text="label"
              item-value="value"
              style="max-width: 350px"
              prepend-inner-icon="mdi-account-search"
              clearable
              hide-details
              outlined
              dense
            >
            </v-autocomplete>
          </div>

          <template v-if="statusId != 'BLACKLIST'">
            <div>
              <v-textarea
                :disabled="statusId == '' || statusId == null"
                label="Note for Status Change"
                v-model.trim="note"
                class="my-3"
                placeholder="Write a note"
                outlined
                counter="240"
              />
              <template v-if="!validNote && statusId != '' && statusId != null">
                <div class="mx-3">
                  <v-alert dense outlined type="error">
                    The note must be between 5 and 240 characters
                  </v-alert>
                </div>
              </template>
            </div>
          </template>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text @click="cancel()">Close</v-btn>
          <v-btn
            :disabled="statusId == '' || statusId == null || loadingAction || !validNote"
            :loading="loadingAction"
            color="info"
            text
            @click="confirm()"
            >Change</v-btn
          >
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>
<script>
import { getAPI } from "@/api/axios-base";
import { notifyError, notifyInfo, notifySuccess } from "@/components/Notification";
import Vue from "vue";
import { mapActions, mapGetters, mapState } from "vuex";
export default Vue.extend({
  props: {
    clientsUuids: [],
    openDialog: false,
    allUuid: Object,
  },
  name: "crm-change-status",
  data() {
    return {
      dialog: false,
      statusId: "",
      note: "",
      loading: false,
      trUuid: null,
      loadingAction: false,
      isAdmins: false,
      dialogNote: false,
    };
  },
  watch: {
    openDialog(val) {
      if (val) {
        this.dialog = val;
        this.trUuid = this.allUuid.count != 0 ? this.allUuid.tr : null;
      }
    },
    statusId(val) {
      if (val) {
        this.dialogNote = true;
        this.note = "";
      }
    },
  },

  computed: {
    ...mapGetters(["isSuper", "isAdmin", "isSupervisor", "getCoordinatorN"]),

    validNote() {
      if (this.note == "" || this.note == null || this.note == undefined) {
        return false;
      }
      if (this.note.length < 5 || this.note.length > 240) {
        return false;
      }
      return true;
    },
    policyStates() {
      const states = this.isAdmins
        ? [
            { value: "ACTIVE", label: "Active" },
            { value: "ACTIVE_PENDING", label: "Active Pending" },
            /*  { value: "CANCELLED", label: "Cancelled" }, */
            { value: "PAY_DUE", label: "Pay Due" },
            { value: "REVIEW", label: "Review" },
            /* { value: "BLACKLIST", label: "Blacklist" }, */
            /*    { value: "POLIZE_PENDING", label: "Policy Pending" },
            { value: "POLIZE_OK", label: "Policy Ok" },
            { value: "PAYMENT_OK", label: "Payment Ok" },
            { value: "PAYMENT_PENDING", label: "Payment Pending" },
            { value: "POLICY_CANCELLATION", label: "Cancelled" },
            { value: "POLICY_RENEWAL", label: "Renewal" },
            { value: "POLICY_RECONCILIATION", label: "Reconciliation" },
            { value: "OTHER_PARTY", label: "Other Party" },
            { value: "NO_AOR", label: "No Aor" },
            { value: "NO_AOR_ACTIVE", label: "No Aor Active" },
            { value: "POLICY_BLACKLIST", label: "Blacklist" },
            { value: "PENDING_RENEWAL", label: "Pending Renewal" },
            { value: "NO_RENEWAL", label: "No Renewal" },
            { value: "PROSPECT_RENEWAL", label: "Prospect Renewal" }, */
          ]
        : [
            { value: "ACTIVE", label: "Active" },
            { value: "ACTIVE_PENDING", label: "Active Pending" },
            /* { value: "CANCELLED", label: "Cancelled" }, */
            { value: "PAY_DUE", label: "Pay Due" },
            { value: "REVIEW", label: "Review" },
            /* { value: "BLACKLIST", label: "Blacklist" }, */
            /*   { value: "POLIZE_PENDING", label: "Policy Pending" },
            { value: "POLIZE_OK", label: "Policy Ok" },
            { value: "PAYMENT_OK", label: "Payment Ok" },
            { value: "POLICY_CANCELLATION", label: "Cancelled" },
            { value: "POLICY_RENEWAL", label: "Renewal" },
            { value: "POLICY_RECONCILIATION", label: "Reconciliation" },
            { value: "OTHER_PARTY", label: "Other Party" },
            { value: "NO_AOR", label: "No Aor" },
            { value: "NO_AOR_ACTIVE", label: "No Aor Active" },
            { value: "POLICY_BLACKLIST", label: "Blacklist" },
            { value: "PENDING_RENEWAL", label: "Pending Renewal" },
            { value: "NO_RENEWAL", label: "No Renewal" },
            { value: "PROSPECT_RENEWAL", label: "Prospect Renewal" }, */
          ];
      return states;
    },
  },
  mounted() {
    if (this.isSuper || this.isAdmin || this.isSupervisor) {
      this.isAdmins = true;
    }
  },
  methods: {
    ...mapActions("crmConfigurationsModule", ["actAsignToLead", "actMultiAsignToLead"]),

    cancel(updated) {
      this.dialog = false;
      this.statusId = "";
      this.trUuid = null;
      this.note = "";
      this.loadingAction = false;
      if (updated) {
        this.$emit("updated", true);
      } else {
        this.$emit("updated", false);
      }
    },

    async confirm() {
      return;
      const clients = this.clientsUuids.map((c) => c.uuid);
      let body = {
        patientUuids: clients,
        noteForStatusChange: this.note,
        polizeStatus: this.statusId,
        trUuid: this.trUuid,
      };
      this.loadingAction = true;
      if (body.trUuid) {
        delete body.patientUuids;
        getAPI
          .put("/patient/updateMasiveNoms", body)
          .then(() => {
            notifyInfo(this.$t("Status has been changed"));
            this.cancel(true);
          })
          .catch((error) => {
            let mess = error.response.data.message.toString();
            this.loadingAction = false;

            notifyError(error.response.data, `An error occurred: ${mess}`);
          });
      } else {
        delete body.trUuid;
        getAPI
          .put("/patient/updateMasiveNoms", body)
          .then(() => {
            notifyInfo(this.$t("Status has been changed"));
            this.cancel(true);
          })
          .catch((error) => {
            let mess = error.response.data.message.toString();
            this.loadingAction = false;

            notifyError(error.response.data, `An error occurred: ${mess}`);
          });
      }
    },
    cancelNote() {
      this.dialogNote = false;
      this.note = "";
    },
    acceptNote() {
      this.dialogNote = false;
    },
  },
});
</script>
<style lang="scss" scope></style>
