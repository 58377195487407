<template>
  <div class="filterMonths">
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      min-width="270px"
      top
      offset-y
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          :value="formatDate"
          :placeholder="nextMonth"
          outlined
          readonly
          dense
          v-on="on"
          ><template slot="prepend-inner">
            <v-icon v-on="on">mdi-calendar</v-icon>
          </template>
        </v-text-field>
      </template>

      <v-date-picker
        v-model="date"
        type="month"
        @update:picker-date="selectYear"
        no-title
        scrollable
        :min="minDate"
        :max="maxDate"
        :multiple="true"
      >
        <template>
          <template v-if="this.date.length != 12">
            <v-btn color="success" @click="selectAllMonths" text>All Months</v-btn>
          </template>
          <template v-else>
            <v-btn color="error" @click="removeAllMonths" text>Remove All</v-btn>
          </template>

          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="cancelMenu"> Cancel </v-btn>
          <v-btn text :disabled="date.length == 0" color="primary" @click="getmonth">
            OK
          </v-btn>
        </template>
      </v-date-picker>
    </v-menu>
  </div>
</template>
<script>
import moment from "moment";
export default {
  data() {
    return {
      date: [],
      menu: false,
      yearSelect: moment().format("YYYY"),
    };
  },
  computed: {
    minDate() {
      return this.date.length == 0
        ? moment().add(-1, "year").startOf("year").format("YYYY-MM")
        : moment(this.date[0]).startOf("year").format("YYYY-MM");
    },
    maxDate() {
      return this.date.length == 0
        ? moment().add(1, "year").endOf("year").format("YYYY-MM")
        : moment(this.date[0]).endOf("year").format("YYYY-MM");
    },
    formatDate() {
      if (
        this.date != "" &&
        this.date != null &&
        this.date != undefined &&
        this.date.length != 0
      ) {
        let d = this.date;
        if (d.length > 1) {
          d = d.sort((a, b) => {
            return moment(a) - moment(b);
          });
        }
        const others = d.length > 1 ? `(+${d.length - 1})` : "";
        return moment(d[0]).format("YYYY, MMMM") + others;
        /* return this.date.map((d, i) => {
          return i == 0 ? moment(d).format("YYYY, MMMM") : `(+${this.date.length - 1})`;
        }); */
      }

      return "";
    },
    nextMonth() {
      return moment().format("MMMM, YYYY");
    },
  },

  methods: {
    getmonth() {
      if (this.date && this.date.length > 1) {
        this.date = this.date.sort((a, b) => {
          return moment(a) - moment(b);
        });
      }
      this.$emit("dateSelect", this.date);
      this.menu = false;

      /*  const dt = moment(date).startOf("month").toISOString();
      this.$emit("monthPick", dt); */
    },

    cancelMenu() {
      this.menu = false;
    },
    clearDate() {
      this.date = Array.from({ length: 12 }, (_, i) =>
        moment().startOf("year").add(i, "month").format("YYYY-MM")
      );
    },
    selectYear(value) {
      this.yearSelect = value;
      console.log("aqyu", this.yearSelect);
    },
    selectAllMonths() {
      this.date = Array.from({ length: 12 }, (_, i) =>
        moment(this.yearSelect).startOf("year").add(i, "month").format("YYYY-MM")
      );
      console.log(this.date);
    },
    removeAllMonths() {
      this.date = [];
    },
  },
  mounted() {
    this.clearDate();
  },
};
</script>
<style lang="scss" scoped>
.menumonth {
  background-color: white !important;
}
.filterMonths {
  height: 40px !important;
}
</style>
