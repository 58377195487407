

























































































































































































































import Vue from "vue";
import MakePayment from "./MakePayment.vue";
import { getAPI } from "@/api/axios-base";
import {
  notificationError,
  notifyError,
  notifyInfo,
  notifySuccess,
} from "@/components/Notification";
import { apiBaseUrl } from "@/enviorment";
import axios, { AxiosError } from "axios";
import PdfView from "@/components/PDF/PdfView.vue";
import DocumentViewer from "../PdfSign/DocumentViewer.vue";
import { mapGetters, mapState } from "vuex";
import UsaePayInvoice from "./components/UsaePayInvoice.vue";
import moment from "moment";
import AddCardClient from "./components/AddCardClient.vue";
import Payments from "./components/Payments/Payments.vue";
import ChargeBack from "./components/Payments/ChargeBack.vue";
import Balance from "./components/Payments/Balance.vue";
export default Vue.extend({
  components: {
    Payments,
    ChargeBack,
    Balance,
    MakePayment,
    PdfView,
    DocumentViewer,
    UsaePayInvoice,
    AddCardClient,
  },
  name: "patient-payment",
  props: {
    procedure: Object,
  },

  data() {
    return {
      tabSelected: "payments",
      tabs: [
        {
          value: "payments",
          title: "Payments",
          icon: "mdi-usd",
          href: "#payments",
          color: "#66BB6A",
        },
        {
          value: "chargeBack",
          title: "Charge Back",
          icon: "mdi-usd",
          href: "#chargeBack",
          color: "#EF5350",
        },
        {
          value: "balance",
          title: "Balance",
          icon: "mdi-usd",
          href: "#balance",
          color: "#FFA726",
        },
      ],

      edit: false,
      showDocuments: false,
      contador: 0,
      transfer: {
        refunddialog: false,
        transferdialog: false,
        transferitems: [],
        transferamount: 0,
        transferToProcedure: "",
      },
      dialogsendinvoice: false,
      amount: 0,
      note: "",
      ticket: 0,
      typepdf: "ticket",
      paymentuuid: null,
      fail: false,
      total: 0,
      confircode: "",
      selected: [],
      loading: false,
      loadingpdf: false,
      showBreakdown: false,
      showInvoice: false,
      item: null,
      dialogconfirm: false,
      errorcode: false,
      confirm: null,
      admins: false,
      memberSelect: null,
      paymentsMembers: [],
      loadingpaymentmembers: false,
      deletePayment: {
        dialog: false,
        loading: false,
        item: null,
      },
      patientPayments: [],
      deletePayments: {
        dialog: false,
        loading: false,
        items: [],
      },

      countdelete: 0,
    };
  },
  watch: {
    contador(val) {
      if (val == (this as any).transfer.transferitems.length) {
        (this as any).getPayment();
        (this as any).canceltransfer();
      }
    },
    countdelete(val) {
      if (val == (this as any).deletePayments.items.length) {
        (this as any).getPayment();
        (this as any).cancelDelete();
        (this as any).selected = [];
        notifyInfo("Payments has been deleted!");
      }
    },
    confircode(val) {
      if (val == null || val == "" || val == undefined) {
        (this as any).errorcode = false;
      }
    },
    fail(val) {
      if (val) {
        (this as any).selected = [];
      }
    },

    memberSelect(val) {
      if (val != null && val != "" && val != undefined) {
        (this as any).getMemberPayment();
      }
    },
  },

  computed: {
    ...mapState("crmMedicFormModule", ["patientDetail"]),
    ...mapGetters(["isSuper", "isAdmin", "getviewpayments", "getUseMerchant"]),

    totalcomision() {
      if ((this as any).patientPayments.length == 0) {
        return 0;
      }
      let total = 0;
      (this as any).patientPayments.forEach((element: any) => {
        if (element.type == "COMISION") {
          total = total + Number(element.amount);
        }
      });
      return total;
    },
    totalover() {
      if ((this as any).patientPayments.length == 0) {
        return 0;
      }
      let total = 0;
      (this as any).patientPayments.forEach((element: any) => {
        if (element.type == "OVERIDE") {
          total = total + Number(element.amount);
        }
      });
      return total;
    },
    totalbond() {
      if ((this as any).patientPayments.length == 0) {
        return 0;
      }
      let total = 0;
      (this as any).patientPayments.forEach((element: any) => {
        if (element.type == "BONUS") {
          total = total + Number(element.amount);
        }
      });
      return total;
    },

    usaEpaySelecteds() {
      if ((this as any).selected.length == 0) {
        return false;
      }
      let c = 0;
      (this as any).selected.forEach((element: any) => {
        if (element.type == "UsaEpay") {
          c++;
        }
      });
      if (c == 0) {
        return false;
      } else {
        return true;
      }
    },

    headers() {
      return [
        { text: "Status", value: "status", sortable: false },
        { text: "Created At", value: "paymentDate", sortable: false },
        { text: "Payment Month", value: "month", sortable: false },
        { text: "Payment Year", value: "year", sortable: false },
        { text: "", value: "actions", sortable: false },
      ];
    },

    paymentsConfirmed() {
      const payments = (this as any).patientPayments;
      if ((this as any).memberSelect) {
        return (this as any).paymentsMembers.filter((p: any) => p.status == "CONFIRMED");
      }

      if (payments) {
        return payments.filter((p: any) => p.status == "CONFIRMED");
      }
      return [];
    },

    confpay() {
      const x = (this as any).confirm;

      return x;
    },

    payments(): number {
      return 0.0;
    },
    deposit(): number {
      if ((this as any).payments == 0) return 0;

      return 10;
    },
    deb(): number {
      const payments = (this as any).payments || 0;
      return (this as any).totalCost - payments;
    },

    validamout() {
      if ((this as any).amount > (this as any).deb) {
        return false;
      }
      return true;
    },
    totalCost(): number {
      return 100;
    },
  },
  mounted() {
    if ((this as any).isAdmin || (this as any).isSuper) {
      (this as any).admins = true;
    }
    (this as any).getPayment();
  },
  methods: {
    getPayment() {
      (this as any).loadingpaymentmembers = true;
      getAPI("/payment/getPaymentsByClient/" + this.$route.params.uuid).then((res) => {
        (this as any).patientPayments = res.data;
        (this as any).loadingpaymentmembers = false;
      });
    },
    async getMemberPayment() {
      (this as any).loadingpaymentmembers = true;
      const patient = (await getAPI(`/patient/getPatient/${(this as any).memberSelect}`))
        .data;
      (this as any).loadingpaymentmembers = false;
      (this as any).paymentsMembers = patient.payments;
    },
    setEdit() {
      (this as any).edit = false;
    },
    showPaymentTicket(paymentid: string) {
      (this as any).typepdf = "payment";
      (this as any).paymentuuid = paymentid;
      (this as any).showDocuments = true;
    },

    downloadPDFPayment() {
      (this as any).typepdf = "ticket";
      (this as any).showDocuments = true;
    },
    toDeletePayment(item: any) {
      (this as any).deletePayment = {
        loading: false,
        dialog: true,
        item: item,
      };
    },
    confirmDeletePayment() {
      (this as any).deletePayment.loading = true;
      getAPI
        .delete("/payment/delete/" + (this as any).deletePayment.item.uuid)
        .then((re) => {
          (this as any).deletePayment.loading = false;
          notifyInfo("Payment has been deleted!");
          (this as any).getPayment();
          (this as any).cancelDelete();
        })
        .catch((err) => {
          (this as any).deletePayment.loading = false;
          let mess = err.response.data.message.toString();
          notifyError(err.response.data, `An error occurred: ${mess}`);
        });
    },
    confirmDeletePayments() {
      (this as any).countdelete = 0;
      (this as any).deletePayments.loading = true;

      (this as any).deletePayments.items.forEach((it: any) => {
        getAPI
          .delete("/payment/delete/" + it.uuid)
          .then((re) => {
            (this as any).deletePayments.loading = false;
            (this as any).countdelete++;
          })
          .catch((err) => {
            (this as any).deletePayment.loading = false;
            let mess = err.response.data.message.toString();
            notifyError(err.response.data, `An error occurred: ${mess}`);
          });
      });

      return;
    },
    toDeletePayments() {
      (this as any).deletePayments = {
        loading: false,
        dialog: true,
        items: (this as any).selected,
      };
    },
    cancelDelete() {
      (this as any).deletePayment = {
        loading: false,
        dialog: false,
        item: null,
      };
      (this as any).deletePayments = {
        loading: false,
        dialog: false,
        items: [],
      };
    },

    paymentDate(date: string) {
      const da = date.split("T")[1];
      if (da.includes("00:00:00.000Z")) {
        const df = date.split("T")[0];
        const y = df.split("-")[0];
        const m = df.split("-")[1];
        const d = df.split("-")[2];
        return `${m}/${d}/${y}`;
      }

      const dat = moment(date);
      return dat.format("MM/DD/YYYY");
    },
    typesShow(type: string) {
      if (type == "External_Card_Reader") {
        return "Stripe Card Reader";
      }
      if (type == "Stripe") {
        return "Card";
      }
      if (type == "Stripe_Invoice") {
        return "Stripe Invoice";
      }
      return type;
    },
    toConfirm(item: any) {
      (this as any).dialogconfirm = true;
      (this as any).item = item;
    },
    confirmPay() {
      if ((this as any).confircode == "12345678") {
        (this as any).confirm = { confirm: true, payment: (this as any).item };

        (this as any).sendConfirm();
      } else {
        (this as any).errorcode = true;
      }
    },
    sendConfirm() {
      (this as any).item = null;
      (this as any).dialogconfirm = false;
      (this as any).confircode = "";
      (this as any).errorcode = false;
    },

    card(item: any) {
      if (item.type == "Stripe") {
        let idc = item.stripeCardId;

        if (idc.includes("{")) {
          idc = JSON.parse(idc);

          return idc.card.brand == "mastercard"
            ? "MasterCard**" + idc.card.last4
            : idc.card.brand + "****" + idc.card.last4;
        } else {
          const cardtem = (this as any).patientDetail.stripeCards.filter(
            (c: any) => c.id == idc
          );
          if (cardtem == undefined) {
            return (this as any).typesShow(item.type);
          }

          if (cardtem.length == 0) {
            return "****";
          }
          return cardtem[0].brand + "****" + cardtem[0].last4;
        }
      }

      return (this as any).typesShow(item.type);
    },
  },
});
